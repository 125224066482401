var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message",class:{ 'my-message': _vm.isMyMessage }},[_c('div',{staticClass:"flex",class:{ 'justify-end': _vm.isMyMessage }},[(!_vm.isMyMessage)?_c('div',{staticClass:"flex flex-col items-center justify-end mx-2"},[(_vm.senderId === -93101)?[(
            _vm.chatConfig.chatFavicon !== undefined &&
            (_vm.chatConfig.chatFavicon || []).length
          )?_c('img',{staticClass:"self-center",attrs:{"src":_vm.chatConfig.chatFaviconSrc,"height":_vm.size,"width":_vm.size}}):_c('img',{staticClass:"self-center",attrs:{"src":require("@assets/images/logo/motadata.png"),"height":_vm.size,"width":_vm.size}})]:_c('FlotoUserAvatar',{attrs:{"size":_vm.size,"user-id":_vm.owner.id}})],2):_vm._e(),(
        [
          _vm.chatBrainType.PLUGIN_UTILITY,
          _vm.chatBrainType.SEARCH_UTILITY,
          _vm.chatBrainType.ACTION_UTILITY ].indexOf(_vm.message.responseType) >= 0
      )?_c('UtilityPluginMessage',{class:{ 'justify-end': _vm.isMyMessage },attrs:{"message":_vm.message,"is-my-message":_vm.isMyMessage}}):(
        [_vm.chatBrainType.BOT_RESPONSE_TEXT].indexOf(_vm.message.responseType) >= 0
      )?_c('div',{staticClass:"content",class:{ 'my-message': _vm.isMyMessage },domProps:{"innerHTML":_vm._s(_vm.message.message)}}):(!(_vm.attachments || []).length)?_c('div',{staticClass:"content",class:{ 'my-message': _vm.isMyMessage },domProps:{"innerHTML":_vm._s(_vm.message.message)}}):(_vm.attachments && (_vm.attachments || []).length)?_c('div',{staticClass:"content cursor-pointer link",class:{ 'my-message': _vm.isMyMessage },on:{"click":function($event){return _vm.handleFileDownload(_vm.attachments[0])}}},[_c('MIcon',{attrs:{"name":"file-alt"}}),_vm._v(" "+_vm._s(_vm.attachments[0].realName)+" ")],1):_vm._e(),(_vm.isMyMessage)?_c('div',{staticClass:"flex flex-col items-center justify-end mx-2"},[(_vm.senderId === -93101)?[(
            _vm.chatConfig.chatFavicon !== undefined &&
            (_vm.chatConfig.chatFavicon || []).length
          )?_c('img',{staticClass:"self-center",attrs:{"src":_vm.chatConfig.chatFaviconSrc,"height":_vm.size,"width":_vm.size}}):_c('img',{staticClass:"self-center",attrs:{"src":require("@assets/images/logo/motadata.png"),"height":_vm.size,"width":_vm.size}})]:_c('FlotoUserAvatar',{attrs:{"size":_vm.size,"user-id":_vm.loggedIn ? _vm.senderId : undefined}})],2):_vm._e()],1),_c('div',{staticClass:"message-datetime md-text-xs px-12 py-1"},[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.date))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }