<template>
  <FlotoCrudContainer
    ref="list"
    as-table
    :scrollable="scrollable"
    :fetch-fn="fetchUserFn"
    :columns="tableColumns"
    :selectable="selectable"
    v-bind="$attrs"
    @selection-change="$emit('selection-change', $event)"
  >
    <template v-slot:add-controls="{ refreshList }">
      <MRow v-if="searchable" :gutter="0" class="items-center">
        <MCol :size="12" class="flex items-center mb-4">
          <div class="flex-1">
            <FlotoSearchBar
              ref="searchBar"
              :allow-keywords="false"
              :allow-save-search="false"
              :persist-criteria="false"
              :module-name="$constants.USER"
              :exclude-search-params="[]"
              @change="applyCriteria($event, refreshList)"
            />
          </div>
        </MCol>
      </MRow>
    </template>
    <template v-slot:form>
      <span />
    </template>
    <template v-slot="slotData">
      <slot v-bind="slotData"></slot>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import { getUsersApi } from '@modules/users/users-api'
export default {
  name: 'RequesterList',
  props: {
    fetchFn: { type: Function, default: undefined },
    searchable: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    scrollable: { type: Boolean, default: false },
  },
  data() {
    return {
      searchCriterias: {
        quals: [],
      },
      tableColumns: [
        { name: `${this.$tc('name')}`, key: 'name' },
        { name: `${this.$tc('email')}`, key: 'email' },
        { name: `${this.$tc('contact_no')}`, key: 'contactNo' },
        { name: `${this.$tc('alt_contact_no1')}`, key: 'altContactNo1' },
        { name: `${this.$tc('alt_contact_no2')}`, key: 'altContactNo2' },
      ],
      searchCriteria: [],
    }
  },
  methods: {
    applyCriteria(searchCriteria, refreshList) {
      this.searchCriterias = searchCriteria
      refreshList()
    },
    fetchUserFn(limit, offset) {
      return getUsersApi('requester', {}, this.searchCriterias, limit, offset)
    },
    refresh() {
      this.$refs.list.refresh()
    },
  },
}
</script>
