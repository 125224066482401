<template>
  <div v-if="open" class="message-comfirm-modal">
    <div class="flex flex-col flex-1 confirm-message bg-neutral-lightest">
      <div class="flex flex-1 justify-start items-center mb-2">
        <div class="icon-container mr-2">
          <slot name="icon">
            <MIcon name="info-circle" class="w-full text-primary" size="2x" />
          </slot>
        </div>
        <div class="flex flex-1">
          <slot name="message"></slot>
        </div>
      </div>
      <div class="flex justify-end">
        <MButton id="yes-btn " class="mr-2" @click.stop="handleConfirm">
          {{ $t('yes') }}
        </MButton>
        <MButton id="no-btn" variant="default" @click.stop="handleCancel">
          {{ $t('no') }}
        </MButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageConfirmModal',
  props: {
    open: { type: Boolean, default: false },
  },
  methods: {
    handleCancel() {
      this.$emit('hide')
    },
    handleConfirm() {
      this.$emit('confirm')
      this.$emit('hide')
    },
  },
}
</script>

<style lang="less" scoped>
.message-comfirm-modal {
  @apply flex items-center justify-center px-4;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: var(--chart-tooltip-background);

  & .confirm-message {
    @apply p-4 rounded;

    // background-color: var(--page-background-color);
  }
}
</style>
