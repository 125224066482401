<template>
  <div class="w-full">
    <div class="flex">
      <slot name="bot-avatar"></slot>
      <div
        class="flex flex-col content"
        :class="{ 'my-message': isMyMessage }"
        v-html="message.message"
      >
      </div>
      <slot name="sender-avatar"></slot>
    </div>
    <div class="flex flex-col mt-2">
      <div
        v-for="(option, index) in message.options"
        :key="index"
        class="flex items-center justify-center flex-wrap"
      >
        <div
          v-for="o in option.response"
          :key="o.key"
          class="static-choice-option text-ellipsis"
          @click="() => handleSelect(o)"
        >
          {{ o.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageMixin from '../../mixins/message-mixin'
export default {
  name: 'UserStaticChoiceMessage',
  mixins: [MessageMixin],
  data() {
    return {
      selectedValue: null,
    }
  },
  methods: {
    handleSelect(value) {
      this.selectedValue = value
      const message = value.text
      this.$emit('send-message', {
        message,
        chatBrainValue: value.key,
        chatBrainId: this.message.chatBrainId,
      })
    },
  },
}
</script>

<style lang="less" scoped>
.static-choice-option {
  @apply m-1 py-1 px-4 items-center cursor-pointer;

  color: var(--ai-bot-primary);
  border: 1px solid var(--ai-bot-primary);
  border-radius: 50px;
  &.selected,
  &:hover {
    color: var(--white-regular);
    background-color: var(--ai-bot-primary);
    border-color: var(--ai-bot-primary);
  }
}
</style>
