<template>
  <HighchartComponent
    ref="chartRef"
    :options="chartOptions"
    :highcharts="hc"
    v-on="listeners"
  />
</template>

<script>
import Highcharts from 'highcharts'
import HighChartsMore from 'highcharts/highcharts-more'
import HighchartsGauge from 'highcharts/modules/solid-gauge'
import HighchartsSankey from 'highcharts/modules/sankey'
import NoData from 'highcharts/modules/no-data-to-display'
import Drilldown from 'highcharts/modules/drilldown'
// import HighchartsOrganization from 'highcharts/modules/organization'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsExportData from 'highcharts/modules/export-data'
import Boost from 'highcharts/modules/boost'
import HighchartComponent from './highchart.vue'

Drilldown(Highcharts)
HighChartsMore(Highcharts)
HighchartsGauge(Highcharts)
HighchartsSankey(Highcharts)
NoData(Highcharts)
// HighchartsOrganization(Highcharts)
HighchartsExporting(Highcharts)
HighchartsExportData(Highcharts)
Boost(Highcharts)

/**
 * Override the reset function, we don't need to hide the tooltips and
 * crosshairs.
 */
const oldHighchartReset = Highcharts.Pointer.prototype.reset
Highcharts.Pointer.prototype.reset = function (force) {
  const options = this.chart.userOptions || this.options
  if (options.syncGroup === false || force) {
    setTimeout(() => oldHighchartReset.call(this))
    return
  }
  return undefined
}

// /**
//  * Highlight a point by showing tooltip, setting hover state and draw crosshair
//  */
Highcharts.Point.prototype.highlight = function (event) {
  event = this.series.chart.pointer.normalize(event)
  this.onMouseOver() // Show the hover marker
  this.series.chart.tooltip.refresh(this) // Show the tooltip
  this.series.chart.xAxis[0].drawCrosshair(event, this) // Show the crosshair
}

export default {
  name: 'Chart',
  components: {
    HighchartComponent,
  },
  props: {
    options: { type: Object, required: true },
    syncGroup: { type: String, default: undefined },
  },
  computed: {
    hc() {
      return Highcharts
    },
    chartOptions() {
      return Highcharts.merge(this.options, {
        syncGroup: this.syncGroup || false,
        ...(this.syncGroup
          ? {
              xAxis: {
                crosshair: true,
                events: {
                  setExtremes: this.syncExtremes,
                },
              },
              // tooltip: {
              //   positioner: function() {
              //     return {
              //       // right aligned
              //       x: this.chart.chartWidth - this.label.width,
              //       y: -10, // align to title
              //     }
              //   },
              //   borderWidth: 0,
              //   backgroundColor: 'none',
              //   headerFormat: '',
              //   shadow: false,
              //   style: {
              //     fontSize: '18px',
              //   },
              // },
            }
          : {}),
      })
    },
    listeners() {
      if (this.syncGroup) {
        return {
          mousemove: this.handlePointHightlight,
        }
      }
      return {}
    },
  },
  methods: {
    showSeries() {
      this.$refs.chartRef.chart.series.forEach((s) => s.show())
    },
    resetPointer() {
      this.$refs.chartRef.chart.pointer.reset(true)
    },
    syncExtremes(e) {
      var thisChart = this.$refs.chartRef.chart

      if (e.trigger !== 'syncExtremes') {
        const syncGroup = this.syncGroup || false
        const charts = Highcharts.charts.filter(Boolean).filter((c) => {
          const options = c.userOptions || c.options
          return (
            options &&
            options.syncGroup === syncGroup &&
            syncGroup &&
            c !== thisChart
          )
        })
        // Prevent feedback loop
        charts.forEach((chart) => {
          if (chart.xAxis[0].setExtremes) {
            const rangeDifference = chart.series[0].options.rangeDifference
            // It is null while updating
            chart.xAxis[0].setExtremes(
              e.min ? e.min + rangeDifference : e.min,
              e.max ? e.max + rangeDifference : e.max,
              undefined,
              false,
              {
                trigger: 'syncExtremes',
              }
            )
          }
        })
      }
    },
    handlePointHightlight(e) {
      const syncGroup = this.syncGroup || false
      const charts = Highcharts.charts.filter(Boolean).filter((c) => {
        const options = c.userOptions || c.options
        return options && options.syncGroup === syncGroup && syncGroup
      })
      charts.forEach((chart) => {
        const event = chart.pointer.normalize(e)
        if (chart.series.length) {
          // Get the hovered point
          const point = chart.series[0].searchPoint(event, true)

          if (point) {
            point.highlight(e)
          }
        }
      })
    },
  },
}
</script>
