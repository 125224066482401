<template>
  <portal v-if="initiated" to="chat-container">
    <ChatBox
      v-for="(chatRoom, index) in openedRooms"
      :key="chatRoom.id"
      :room="chatRoom"
      for-technician
      :transfer-timeout="transferTimeout"
      :right="chatBoxWidth * (index + 1)"
      :disabled="!connected"
      :socket="socketIOClientContext.socket"
      :event-prefix="socketIOClientContext.eventPrefix"
      :default-unread-count="unreadCount[chatRoom.id]"
      :default-visible="chatRoom.defaultVisible"
      :default-messages="chatRoom.defaultMessages"
      :online-users="onlineUsers"
      :owner="user"
      :chat-config="chatConfigContext.config"
      @on-reset-unread-count="onResetUnReadCount(chatRoom, $event || 0)"
      @on-minimize="onChatBoxMinimized(chatRoom)"
      @on-close="removeChatBoxRoom(chatRoom)"
    >
      <template v-if="!connected" v-slot:connection-error>
        <ConnectionError />
      </template>
    </ChatBox>
    <UsersList
      :disabled="!connected"
      :has-collaboration="isCollaborationAvailable"
      :can-support="chatEnabled"
      :online-users="availableUsers"
      :outgoing-online-users="onlineUsers"
      :exclude-ids="[user.id]"
      :socket="socketIOClientContext.socket"
      :event-prefix="socketIOClientContext.eventPrefix"
      :chat-config="chatConfigContext.config"
      @click="addChatBoxUser"
      @room-click="addNewRoom($event)"
    >
      <template v-if="!connected" v-slot:connection-error>
        <ConnectionError />
      </template>
    </UsersList>
  </portal>
</template>

<script>
import IntersectionWith from 'lodash/intersectionWith'
import IsEqual from 'lodash/isEqual'
import Notification from 'ant-design-vue/es/notification'
import { TechnicianComputed } from '@state/modules/technician'
import { authComputed } from '@state/modules/auth'
import { PreferenceComputed } from '@state/modules/preference'
import Bus from '@utils/emitter'
import UsersList from './chat-users-list.vue'
import ChatBox from './chat-box/chat-box.vue'
import ConnectionError from './connection-error.vue'
import ChatNotification from './chat-notification.vue'
import ChatTransferNotification from './chat-transfer-notification.vue'
import {
  getRoomBetweenTechnicianApi,
  getChatRoomByIdApi,
  transformRoom,
  claimChatApi,
  tranferChatApi,
} from './chat-api'

const TRANSFER_TIME_OUT = 60000

export default {
  name: 'CollaborationContainer',
  components: { UsersList, ChatBox, ConnectionError },
  inject: {
    chatConfigContext: { config: {} },
    socketIOClientContext: { socket: undefined, connected: false },
  },
  props: {
    maxChatBoxes: { type: Number, default: undefined },
    chatBoxWidth: { type: Number, default: 405 },
  },
  data() {
    this.transferTimer = null
    this.transferTimeout = TRANSFER_TIME_OUT
    return {
      isCollaborationAvailable: false,
      onlineUsers: [],
      openedRooms: [],
      unreadCount: {},
      chatNotifications: [],
      connected: true,
      maxAllowedChatBoxes:
        this.maxChatBoxes ||
        Math.floor(window.innerWidth / this.chatBoxWidth) - 1,
      initiated: false,
    }
  },
  computed: {
    ...authComputed,
    ...TechnicianComputed,
    ...PreferenceComputed,
    availableUsers() {
      const users = IntersectionWith(
        this.onlineUsers,
        this.chatConfigContext.config.userIds || [],
        IsEqual
      )
      return users
    },
  },
  watch: {
    'chatConfigContext.config': {
      handler: 'buildContainer',
      immediate: true,
    },
  },
  created() {
    const logoutHandler = () => {
      this.chatNotifications.forEach((id) => {
        Notification.close(id)
      })
    }
    Bus.$on('auth:logout', logoutHandler)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('auth:logout', logoutHandler)
    })
  },
  beforeDestroy() {
    if (this.transferTimer) {
      clearTimeout(this.transferTimer)
    }
    Bus.$off('chat_channel_updated', this.handleChatChannelUpdate)
    Bus.$off('chat_channel_updated', this.chatRoomCreatedHandler)
    if (this.socketIOClientContext.socket) {
      this.socketIOClientContext.socket.off(
        `${this.socketIOClientContext.eventPrefix}-transfer_request`,
        this.initiateChatTransfer
      )
      this.socketIOClientContext.socket.off(
        `${this.socketIOClientContext.eventPrefix}-transfter_accepted`,
        this.handleAcceptedChat
      )
      this.socketIOClientContext.socket.off(
        `${this.socketIOClientContext.eventPrefix}-transfer_completed`,
        this.handleChatTransferCompleted
      )
      this.socketIOClientContext.socket.off(
        `${this.socketIOClientContext.eventPrefix}-message_notification`,
        this.handleMessageNotification
      )
      this.socketIOClientContext.socket.off(
        `${this.socketIOClientContext.eventPrefix}-live_user_updated`,
        this.handleLiveUserUpdated
      )
      this.socketIOClientContext.socket.off(
        'connect',
        this.handleSocketConnected
      )
      this.socketIOClientContext.socket.off(
        'connect_error',
        this.handleSocketDisconnected
      )
      this.socketIOClientContext.socket.disconnect()
    }
  },
  methods: {
    handleChatChannelUpdate(payload) {
      const channel = transformRoom(payload)
      if (channel.status === 'pending') {
        return
      }
      if (
        channel.status === 'on_going' &&
        channel.members.indexOf(this.user.id) >= 0
      ) {
        if (
          channel &&
          !channel.isCollaboration &&
          channel.owner === this.user.id
        ) {
          return
        }
        this.addChatByRoomId(channel.id, true)
        return
      }
      const openedRooms = this.openedRooms.map(({ id }) => id)
      const index = openedRooms.indexOf(payload.id)
      if (index >= 0) {
        this.openedRooms = [
          ...this.openedRooms.slice(0, index),
          channel,
          ...this.openedRooms.slice(index + 1),
        ]
      }
    },
    buildContainer() {
      if (!this.chatEnabled) {
        this.isCollaborationAvailable = false
        this.initiated = false
        return
      }
      const chatConfig = this.chatConfigContext.config
      if (chatConfig.enabled) {
        this.isCollaborationAvailable = chatConfig.collaborationSupport
        this.init()
        if (this.chatEnabled) {
          Bus.$on('chat_channel_updated', this.handleChatChannelUpdate)
          Bus.$on('chat_channel_updated', this.chatRoomCreatedHandler)
        }
        this.$nextTick(() => {
          if (this.chatEnabled || chatConfig.collaborationSupport) {
            this.initiated = true
          }
        })
      } else {
        this.initiated = false
        this.isCollaborationAvailable = false
      }
    },
    init() {
      if (this.socketIOClientContext.connected) {
        this.handleSocketConnected()
      }
      this.socketIOClientContext.socket.on(
        'connect',
        this.handleSocketConnected
      )
      this.socketIOClientContext.socket.on(
        'connect_error',
        this.handleSocketDisconnected
      )
      this.socketIOClientContext.socket.on(
        `${this.socketIOClientContext.eventPrefix}-live_user_updated`,
        this.handleLiveUserUpdated
      )
      this.socketIOClientContext.socket.on(
        `${this.socketIOClientContext.eventPrefix}-message_notification`,
        this.handleMessageNotification
      )
      this.socketIOClientContext.socket.on(
        `${this.socketIOClientContext.eventPrefix}-transfer_request`,
        this.initiateChatTransfer
      )
      this.socketIOClientContext.socket.on(
        `${this.socketIOClientContext.eventPrefix}-transfter_accepted`,
        this.handleAcceptedChat
      )
      this.socketIOClientContext.socket.on(
        `${this.socketIOClientContext.eventPrefix}-transfer_completed`,
        this.handleChatTransferCompleted
      )
    },
    handleSocketConnected() {
      this.connected = true
      this.socketIOClientContext.socket.emit(
        `${this.socketIOClientContext.eventPrefix}-join`,
        this.user
      )
    },
    handleSocketDisconnected(e) {
      this.connected = false
    },
    handleLiveUserUpdated(users) {
      this.onlineUsers = users.map((i) => i.id)
    },
    handleMessageNotification(message) {
      const user = this.user
      if (
        message.room &&
        !message.room.isCollaboration &&
        message.room.owner === user.id
      ) {
        return
      }
      if (
        message.owner !== user.id &&
        (message.members || []).indexOf(user.id) >= 0
      ) {
        const openedRooms = this.openedRooms.map(({ id }) => id)
        if (openedRooms.indexOf(message.roomId) >= 0) {
          return
        }
        this.unreadCount = {
          ...this.unreadCount,
          [message.roomId]: 1,
        }
        this.addChatByRoomId(message.roomId, false)
      }
    },
    initiateChatTransfer(transferRequest) {
      if (transferRequest.technician === this.user.id && this.chatEnabled) {
        // show notification here to transfer request
        const technician = this.technicianOptions.find(
          (t) => t.id === transferRequest.from
        )
        const key = `${transferRequest.room.id}-${transferRequest.technician}`
        this.chatNotifications = [...this.chatNotifications, key]
        this.$notification({
          message: `${this.$t('chat')} ${this.$t('transfer')} ${this.$tc(
            'request'
          )}`,
          key,
          duration: 0,
          onClose: () => this.rejectChatTransfer(transferRequest),
          description: (h) =>
            h(ChatTransferNotification, {
              props: {
                transferRequest,
                description: this.$t('new_chat_transfer_request', {
                  technician: technician.name,
                  requester: transferRequest.room.user.name,
                }),
                btnText: this.$t('approve'),
                rejectBtnText: this.$t('reject'),
              },
              on: {
                transfer: () => this.transferChat(transferRequest),
                reject: () => this.rejectChatTransfer(transferRequest),
              },
            }),
        })
        this.transferTimer = setTimeout(() => {
          Notification.close(
            `${transferRequest.room.id}-${transferRequest.technician}`
          )
        }, TRANSFER_TIME_OUT)
      }
    },
    transferChat(transferRequest) {
      const key = `${transferRequest.room.id}-${transferRequest.technician}`
      Notification.close(key)
      this.chatNotifications.filter((id) => id !== key)
      this.socketIOClientContext.socket.emit(
        `${this.socketIOClientContext.eventPrefix}-transfter_accepted`,
        transferRequest
      )
    },
    rejectChatTransfer(transferRequest) {
      const key = `${transferRequest.room.id}-${transferRequest.technician}`
      Notification.close(key)
      this.chatNotifications.filter((id) => id !== key)
      this.socketIOClientContext.socket.emit(
        `${this.socketIOClientContext.eventPrefix}-transfer_rejected`,
        transferRequest
      )
    },
    handleAcceptedChat(transferRequest) {
      if (transferRequest.from === this.user.id) {
        return tranferChatApi(
          transferRequest.room.id,
          transferRequest.technician
        ).then((data) => {
          this.socketIOClientContext.socket.emit(
            `${this.socketIOClientContext.eventPrefix}-transfer_completed`,
            {
              ...transferRequest,
              room: {
                ...transferRequest.room,
                members: [
                  ...transferRequest.room.members.filter(
                    (id) => id !== transferRequest.from
                  ),
                  transferRequest.technician,
                ],
              },
            }
          )
        })
      }
    },
    handleChatTransferCompleted(transferRequest) {
      if (transferRequest.technician === this.user.id) {
        this.addNewRoom(transferRequest.room)
      } else if (transferRequest.from === this.user.id) {
        this.socketIOClientContext.socket.emit(
          `${this.socketIOClientContext.eventPrefix}-leave_room`,
          transferRequest.room
        )
        this.removeChatBoxRoom(transferRequest.room)
      }
    },
    addChatByRoomId(roomId, defaultVisible) {
      getChatRoomByIdApi(roomId).then((room) => {
        this.addNewRoom(room, defaultVisible)
      })
    },
    addChatBoxUser(userId) {
      getRoomBetweenTechnicianApi(this.user.id, userId).then((room) =>
        this.addNewRoom(room)
      )
    },
    addNewRoom(room, defaultVisible = true) {
      const openedBoxIds = this.openedRooms.map((i) => i.id)
      let chatBoxUsers = [...this.openedRooms]
      // if the box is already visible and closed then open it
      const alreadyOpenedIndex = openedBoxIds.indexOf(room.id)
      if (alreadyOpenedIndex >= 0) {
        chatBoxUsers = [
          ...chatBoxUsers.slice(0, alreadyOpenedIndex),
          { ...chatBoxUsers[alreadyOpenedIndex], defaultVisible },
          ...chatBoxUsers.slice(alreadyOpenedIndex + 1),
        ]
      } else {
        chatBoxUsers = [...chatBoxUsers, { ...room, defaultVisible }]
      }
      if (chatBoxUsers.length > this.maxAllowedChatBoxes) {
        chatBoxUsers = [
          ...chatBoxUsers.slice(chatBoxUsers.length - this.maxAllowedChatBoxes),
        ]
      }
      this.openedRooms = chatBoxUsers
    },
    onResetUnReadCount(room, value) {
      this.unreadCount = {
        ...this.unreadCount,
        [room.id]: value || 0,
      }
    },
    removeChatBoxRoom(room) {
      this.openedRooms = this.openedRooms.filter((i) => i.id !== room.id)
    },
    onChatBoxMinimized(room) {
      const i = this.openedRooms.map(({ id }) => id).indexOf(room.id)
      if (i >= 0) {
        this.openedRooms = [
          ...this.openedRooms.slice(0, i),
          { ...this.openedRooms[i], defaultVisible: false },
          ...this.openedRooms.slice(i + 1),
        ]
      }
    },
    cleanNotification(id) {
      Notification.close(id)
      this.chatNotifications = this.chatNotifications.filter((x) => x !== id)
    },
    claimChat(room) {
      this.cleanNotification(room.id)
      return claimChatApi(room.id, this.user.id)
    },
    chatRoomCreatedHandler(payload) {
      const transformed = transformRoom(payload)
      // if it is collaboration don't show notifiaction
      if (transformed.isCollaboration) {
        return
      }
      if (!this.chatEnabled) {
        return
      }
      if (payload.status !== 'pending') {
        this.cleanNotification(payload.id)
        return
      }
      if (this.chatNotifications.length >= 3) {
        const firstId = this.chatNotifications[0]
        this.cleanNotification(firstId)
      }
      this.chatNotifications = [...this.chatNotifications, payload.id]
      return this.$notification({
        message: this.$t('new_chat'),
        key: payload.id,
        duration: 0,
        description: (h) =>
          h(ChatNotification, {
            props: {
              chatRequest: payload,
              description: this.$t('new_chat_request', {
                user: payload.user.name,
              }),
              btnText: this.$t('claim'),
            },
            on: {
              claim: () => this.claimChat(payload),
            },
          }),
      })
    },
  },
}
</script>
