var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-1 items-center"},[_c('InlineEdit',{attrs:{"value":_vm.status.name,"item-style":{ display: 'flex', flex: 1, alignItems: 'center' }},on:{"change":function($event){return _vm.$emit('update', Object.assign({}, _vm.status, {name: $event}))}},scopedSlots:_vm._u([{key:"item-renderer",fn:function(ref){
var edit = ref.edit;
return [_c('MCheckbox',{staticClass:"mr-2",attrs:{"disabled":_vm.disabled,"checked":_vm.status.checked},on:{"change":function($event){return _vm.$emit('update', Object.assign({}, _vm.status, {checked: $event}))}}}),_c('div',{staticClass:"item-title flex-1"},[_vm._v(_vm._s(_vm.status.name))]),(!_vm.disabled)?_c('HoverableAction',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var stickAction = ref.stickAction;
var unstickAction = ref.unstickAction;
return [_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"mr-2 cursor-pointer text-neutral-light",attrs:{"name":"pencil","size":"lg"},on:{"click":edit}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")]),_c('FlotoDeleteBtn',{attrs:{"message":_vm.$t('confirm_remove_item', {
                item: ("" + (_vm.$tc('check_list'))),
              })},on:{"trigger":stickAction,"cancel":unstickAction,"confirm":function($event){return _vm.$emit('remove', _vm.status)}}},[_c('MIcon',{staticClass:"text-secondary-red",attrs:{"name":"times-circle","size":"lg"}})],1)]}}],null,true)}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }