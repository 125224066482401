<template>
  <MDropdown
    ref="dropdownMenu"
    v-bind="$attrs"
    overlay-class-name="m-dropdown-overlay"
    v-on="$listeners"
  >
    <template v-slot:trigger>
      <a class="flex items-center mr-1">
        {{ $t('take') }} {{ $tc('action') }}
        <MIcon name="chevron-down" size="lg" class="mx-1" />
      </a>
    </template>
    <template v-slot:menu>
      <MMenu
        :selected-keys="selectedKey ? [selectedKey] : undefined"
        :class="menuClass"
      >
        <MMenuItem
          v-for="actionName in actions"
          :key="actionName"
          :class="{
            [menuItemClass]: true,
          }"
        >
          <slot :hide="hide" :name="actionName" />
        </MMenuItem>
      </MMenu>
    </template>
  </MDropdown>
</template>

<script>
export default {
  name: 'BulkActionMenu',
  props: {
    actions: { type: Array, required: true },
    selectedKey: { type: String, default: undefined },
  },
  data() {
    this.menuClass = 'ant-dropdown-menu'
    this.menuItemClass = 'ant-dropdown-menu-item'
    this.menuItemDisabledClass = 'ant-dropdown-menu-item-disabled'
    this.menuItemSelectedClass = 'ant-dropdown-menu-item-selected'
    return {}
  },
  methods: {
    hide() {
      this.$refs.dropdownMenu.hide()
    },
  },
}
</script>
