<template>
  <div class="flex">
    <slot name="bot-avatar"></slot>
    <div class="content" :class="{ 'my-message': isMyMessage }">
      <MButton>{{ message.displayName }}</MButton>
    </div>
    <slot name="sender-avatar"></slot>
  </div>
</template>

<script>
import MessageMixin from '../../mixins/message-mixin'
export default {
  name: 'BotResponseButtonMessage',
  mixins: [MessageMixin],
}
</script>
