<template>
  <div class="flex flex-col justify-center mt-2">
    <div>
      {{ description }}
    </div>
    <div v-if="tags.length > 0" class="flex flex-wrap flex-col min-w-0">
      <b>Chat Context</b>
      <ul class="w-full" style="max-height: 500px; overflow-y: auto">
        <li v-for="tag in tags" :key="tag">
          {{ tag }}
        </li>
      </ul>
    </div>
    <div class="mt-2">
      <MButton variant="success" @click="$emit('claim')">
        <MIcon name="check" /> {{ btnText }}
      </MButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatNotification',
  props: {
    chatRequest: { type: Object, required: true },
    description: { type: String, required: true },
    btnText: { type: String, required: true },
  },
  computed: {
    tags() {
      return this.chatRequest.contextList || []
    },
  },
}
</script>
