<template>
  <portal
    v-if="
      isSupportChannelActive && initiated && hasChatSupportedModulesInLicense
    "
    to="chat-container"
  >
    <div
      v-if="!showChatBox"
      class="chat-trigger flex items-center justify-center"
      @click="handleShowBox"
    >
      <img
        v-if="
          (chatConfigContext || {}).config.chatLogo !== undefined &&
          (chatConfigContext || {}).config.chatLogo.length
        "
        :src="(chatConfigContext || {}).config.chatLogoSrc"
        height="50"
        width="50"
        style="border-radius: 50%"
      />
      <MBadge
        v-else
        :count="unreadCount"
        class="flex items-center justify-center"
      >
        <MIcon name="comments" size="2x" />
      </MBadge>
    </div>
    <ChatBox
      v-else
      :room="channel"
      :socket="socket"
      :event-prefix="eventPrefix"
      :default-unread-count="0"
      default-visible
      :can-minimize="false"
      :default-messages="[]"
      :online-users="onlineUsers"
      :owner="chatOwner"
      v-bind="
        channel
          ? {}
          : {
              createMessageFn,
            }
      "
      :chat-config="(chatConfigContext || {}).config"
      @on-minimize="showChatBox = false"
      @on-close="handleCloseChatBox"
    >
      <template v-if="!connected" v-slot:connection-error>
        <ConnectionError />
      </template>
      <template v-slot:remark>
        <template v-if="message && loggedIn">
          {{ message }}
          <a
            v-if="
              (channel || {}).status === 'missed' ||
              (channel || {}).status === 'completed'
            "
            class="cursor-pointer"
            @click="handleRestartConversation"
          >
            <br />
            Click here to Start New Conversation
          </a>
        </template>
        <template v-if="!loggedIn && isChatClosedByTechnician">
          <a class="cursor-pointer" @click="handleRestartConversation">
            <br />
            Click here to Start New Conversation
          </a>
        </template>
      </template>
    </ChatBox>
  </portal>
</template>

<script>
import io from 'socket.io-client'
import Bus from '@utils/emitter'
import { TechnicianComputed } from '@state/modules/technician'
import { authComputed } from '@state/modules/auth'
import { OrganizationComputed } from '@state/modules/organization'
import { LicenseComputed } from '@state/modules/license'
import {
  getRequesterChatChannelApi,
  getGuestUserChatChannelApi,
  createRequesterChatChannelApi,
  createMessageApi,
  transformRoom,
} from './chat-api'
import ChatBox from './chat-box/chat-box.vue'
import ConnectionError from './connection-error.vue'
import { chatUserName } from './utils'

const URL = window.location.origin
// const URL = 'http://localhost:8000'

export default {
  name: 'HumanSupportChannel',
  components: { ChatBox, ConnectionError },
  inject: { chatConfigContext: { config: {} } },
  props: {
    chatBoxWidth: { type: Number, default: 305 },
    socketPath: { type: String, required: true },
    guestUserChannel: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isSupportChannelActive: false,
      connected: true,
      socket: null,
      initiated: false,
      channel: undefined,
      showChatBox: false,
      message: null,
      welcomeMessage: null,
      onlineUsers: [],
      unreadCount: 0,
      isChatClosedByTechnician: false,
    }
  },
  computed: {
    ...authComputed,
    ...TechnicianComputed,
    ...OrganizationComputed,
    ...LicenseComputed,
    eventPrefix() {
      // eventPrefix: window.location.host,
      // eventPrefix: 'apolo.myservice.com',
      return this.multiTenantEnvironment ? window.location.host : ''
    },
    hasChatSupportedModulesInLicense() {
      return (
        this.availableModulesInLicense.indexOf(this.$constants.REQUEST) >= 0 &&
        this.availableModulesInLicense.indexOf(this.$constants.CHAT) >= 0
      )
    },
    chatOwner() {
      if (!this.loggedIn) {
        return {
          ...this.guestUserChannel.user,
          name: chatUserName(
            this.guestUserChannel.user.name,
            this.chatConfigContext.config
          ),
        }
      }
      return {
        ...this.user,
        name: chatUserName(this.user.name, this.chatConfigContext.config),
      }
    },
    aiBotSupport() {
      return this.chatConfigContext.config.botSupport
    },
  },
  watch: {
    'chatConfigContext.config': {
      handler: 'buildContainer',
      immediate: true,
    },
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.off('connect', this.handleSocketConnected)
      this.socket.off('connect_error', this.handleSocketDisconnected)
      this.socket.off(
        `${this.eventPrefix}-live_user_updated`,
        this.handleLiveUserUpdate
      )
      this.socket.off(
        `${this.eventPrefix}-message_notification`,
        this.handleMessageNotification
      )
      this.socket.off(
        `${this.eventPrefix}-transfer_completed`,
        this.handleChatTransfered
      )
      this.socket.disconnect()
    }
  },
  methods: {
    handleRestartConversation() {
      if (this.aiBotSupport) {
        Bus.$emit('app:missed-chat')
      } else {
        this.handleCloseChatBox()
        this.$nextTick(() => {
          this.handleShowBox()
        })
      }
    },
    handleChatTransfered() {
      this.getCurrentChannel()
    },
    handleShowBox() {
      this.unreadCount = 0
      this.showChatBox = true
    },
    handleChatClosed() {
      if (!this.loggedIn) {
        this.isChatClosedByTechnician = true
      }
    },
    buildContainer() {
      const chatConfig = this.chatConfigContext.config
      const roomUpdatedHandler = (payload) => {
        if (!this.channel || (this.channel || {}).id !== payload.id) {
          return
        }
        this.channel = transformRoom(payload)
        if (this.channel.status === 'completed') {
          this.message = this.$t('chat_completed')
        } else if (this.channel.status === 'on_going') {
          const user =
            this.technicianOptions.find(
              (t) => payload.technicianId[0] === t.id
            ) || {}
          user.name = chatUserName(user.name, chatConfig)
          this.message = this.$t('you_are_chatting', { user: user.name })
        } else if (this.channel.status === 'missed') {
          this.message = chatConfig.missedMessage
        }
      }
      if (chatConfig.enabled) {
        this.isSupportChannelActive = true
        this.message = chatConfig.welcomeMessage
        this.welcomeMessage = chatConfig.welcomeMessage
        this.init()
        this.$nextTick(() => {
          this.initiated = true
          this.getCurrentChannel()
        })
        Bus.$on('chat_channel_updated', roomUpdatedHandler)
        this.$once('hook:beforeDestroy', () => {
          Bus.$off('chat_channel_updated', roomUpdatedHandler)
        })
      } else {
        this.isSupportChannelActive = false
      }
    },
    init() {
      const isGuestUser = !this.loggedIn
      const query = {
        ...(isGuestUser
          ? {
              id: (this.guestUserChannel || {}).owner,
              name: ((this.guestUserChannel || {}).user || {}).name,
              isGuestUser: true,
            }
          : {
              id: this.user.id,
              name: this.user.name,
              token: this.accessToken,
            }),
        isRequester: true,
        useDomainEventPrefix: this.multiTenantEnvironment,
        eventPrefix: this.multiTenantEnvironment ? window.location.host : '',
      }
      this.socket = io(URL, {
        path: `${process.env.VUE_APP_API_BASE_PATH}${this.socketPath}`,
        query,
      })
      this.socket.on('connect', this.handleSocketConnected)
      this.socket.on('connect_error', this.handleSocketDisconnected)
      this.socket.on(
        `${this.eventPrefix}-live_user_updated`,
        this.handleLiveUserUpdate
      )
      this.socket.on(
        `${this.eventPrefix}-message_notification`,
        this.handleMessageNotification
      )
      this.socket.on(
        `${this.eventPrefix}-transfer_completed`,
        this.handleChatTransfered
      )
      this.socket.on(`${this.eventPrefix}-chat_closed`, this.handleChatClosed)
    },
    handleMessageNotification(message) {
      const user = !this.loggedIn ? this.guestUserChannel.user : this.user
      const room = this.channel
      if (
        room &&
        room.id === message.roomId &&
        message.owner !== user.id &&
        (message.members || []).indexOf(user.id) >= 0 &&
        !this.showChatBox
      ) {
        this.unreadCount += 1
      }
    },
    handleLiveUserUpdate(users) {
      this.onlineUsers = users.map((i) => i.id)
    },
    handleSocketDisconnected(e) {
      this.connected = false
    },
    handleSocketConnected() {
      this.connected = true
      this.socket.emit(`${this.eventPrefix}-join`, this.user)
    },
    createChannel() {
      return createRequesterChatChannelApi(this.user.id)
    },
    getCurrentChannel() {
      if (!this.loggedIn) {
        getGuestUserChatChannelApi(this.guestUserChannel.owner).then((data) => {
          this.channel = data
          if (this.channel.status === 'on_going') {
            const user = data.user
            user.name = chatUserName(user.name, this.chatConfigContext.config)
            this.message = this.$t('you_are_chatting', { user: user.name })
          } else if (this.channel.status === 'pending') {
            this.message = this.welcomeMessage
          }
        })
      } else {
        getRequesterChatChannelApi().then((data) => {
          this.channel = data
          if (this.channel.status === 'on_going') {
            const user =
              this.technicianOptions.find(
                (t) => data.members.filter((m) => m !== data.owner)[0] === t.id
              ) || {}
            user.name = chatUserName(user.name, this.chatConfigContext.config)
            this.message = this.$t('you_are_chatting', { user: user.name })
          } else if (this.channel.status === 'pending') {
            this.message = this.welcomeMessage
          }
        })
      }
    },
    createMessageFn(message, fileAttachments) {
      return this.createChannel().then((channel) => {
        this.channel = channel
        return createMessageApi(channel.id, message, fileAttachments)
      })
    },
    handleCloseChatBox() {
      if (
        this.channel &&
        ['pending', 'on_going'].indexOf(this.channel.status) === -1
      ) {
        this.channel = undefined
        this.message = this.welcomeMessage
      }
      this.showChatBox = false
    },
  },
}
</script>
