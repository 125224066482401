<template>
  <div class="flex">
    <slot name="bot-avatar"></slot>
    <div
      class="content"
      :class="{ 'my-message': isMyMessage }"
      v-html="message.message"
    />
    <slot name="sender-avatar"></slot>
  </div>
</template>

<script>
import MessageMixin from '../../mixins/message-mixin'
export default {
  name: 'UserTextMessage',
  mixins: [MessageMixin],
}
</script>
