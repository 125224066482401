<template>
  <div class="flex w-full">
    <slot name="bot-avatar"></slot>
    <div class="content" :class="{ 'my-message': isMyMessage }">
      <div class="flex flex-col" v-html="message.message"> </div>
      <div class="flex flex-col">
        <div class="flex">
          <FlotoValueInput
            v-model="formData.value"
            :input-type="inputType"
            only-leaf-node-selectable
            :module-name="message.model || $constants.REQUEST"
            :options="messageOptions"
            :advanced-searchable="false"
            v-bind="{ ...additionalProps }"
            :get-popup-container="popupContainer"
            @selected="itemSelected"
            @file-list-updated="fileList = $event"
          />
          <div
            v-if="
              (inputType === 'multiSelectDropdown' && formData.value) ||
              ((inputType === 'attachmentField' ||
                inputType === 'attachment') &&
                fileList.length)
            "
            class="flex justify-end items-center"
          >
            <a>
              <MTooltip>
                <template v-slot:trigger>
                  <MIcon
                    name="send-msg"
                    size="lg"
                    class="mx-1 send-icon"
                    @click="handleSend"
                  />
                </template>
                {{ $tc('send') }}
              </MTooltip>
            </a>
          </div>
        </div>
      </div>
    </div>
    <slot name="sender-avatar"></slot>
  </div>
</template>

<script>
import { refChoiceTypeMap } from '@components/flowbuilder/helpers/option-map'
import MessageMixin from '../../mixins/message-mixin'
export default {
  name: 'UserRefChoiceMessage',
  mixins: [MessageMixin],
  data() {
    return {
      formData: {},
      fileList: [],
    }
  },
  computed: {
    inputType() {
      return (this.message || {}).inputType
        ? refChoiceTypeMap[(this.message || {}).inputType]
        : 'dropdown'
    },
    messageOptions() {
      return this.message.options ? this.message.options[0].response : []
    },
    additionalProps() {
      if (
        this.inputType === refChoiceTypeMap.technician &&
        this.message.groupId
      ) {
        return { groupId: this.message.groupId }
      }
      return {}
    },
  },
  methods: {
    itemSelected(valueOption) {
      if (!valueOption) {
        return
      }
      if (this.inputType === 'multiSelectDropdown') {
        return
      }
      const text = valueOption.text || valueOption.name || valueOption.label
      const message = text
      this.$emit('send-message', {
        message,
        chatBrainValue: valueOption.id || valueOption.value || valueOption.key,
        chatBrainId: this.message.chatBrainId,
      })
    },
    handleSend() {
      if (this.inputType === 'multiSelectDropdown') {
        this.handleMultiSelectSend()
      }
      if (
        this.inputType === 'attachment' ||
        this.inputType === 'attachmentField'
      ) {
        this.handleFileUpload()
      }
    },
    handleMultiSelectSend() {
      const valueMap = {}
      const message = []
      const value = this.formData.value || []
      value.forEach((i) => {
        const selectedItem = this.messageOptions.find((o) => o.key === i) || {}
        valueMap[selectedItem.text] = selectedItem.key
        message.push(selectedItem.text)
      })
      this.$emit('send-message', {
        message: message.join(', '),
        chatBrainId: this.message.chatBrainId,
        multiSelectMap: valueMap,
      })
    },
    handleFileUpload() {
      if (
        this.inputType === 'attachment' ||
        this.inputType === 'attachmentField'
      ) {
        const valueMap = {}
        const message = []
        const files = (this.fileList || []).map((l) => l.response)
        files.forEach((file) => {
          valueMap[file.realName] = file.id
          message.push(file.realName)
        })
        this.$emit('send-message', {
          message: message.join(', '),
          multiSelectMap: valueMap,
          chatBrainId: this.message.chatBrainId,
        })
      }
    },
    popupContainer() {
      const element = this.$el.closest('.scroll-wrapper')
      if (element) {
        return element
      }
      return document.body
    },
  },
}
</script>
