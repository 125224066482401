<template>
  <MIcon
    :name="(iconMap[moduleName] || {}).name || 'tire-rugged'"
    size="2x"
    class="mx-1"
  />
</template>

<script>
export default {
  name: 'PluginMessageModuleIcon',
  props: {
    moduleName: { type: String, required: true },
  },
  data() {
    this.iconMap = {
      [this.$constants.REQUEST]: {
        name: 'ticket-alt',
      },
      [this.$constants.SERVICE_CATALOG]: {
        name: 'shopping-cart',
      },
      [this.$constants.PROBLEM]: {
        name: 'problem',
      },
      [this.$constants.CHANGE]: {
        name: 'change',
      },
      [this.$constants.RELEASE]: {
        name: 'box-full',
      },
      [this.$constants.ASSET]: {
        name: 'laptop',
      },
      [this.$constants.KNOWLEDGE]: {
        name: 'lightbulb-on',
      },
      [this.$constants.APPROVAL]: {
        name: 'user-check',
      },
    }
    return {}
  },
}
</script>
