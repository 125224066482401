<template>
  <div class="input-container flex items-center">
    <div class="flex-1">
      <MInput
        v-model="value"
        placeholder="Send a message"
        class="message-input"
        auto-focus
        :disabled="disabled"
        @keyup="onKeyUp"
      />
    </div>
    <div class="addition-action">
      <!-- <MIcon name="emoji" size="lg" class="mx-1" />
      <MIcon name="paperclip" size="lg" class="mx-1" /> -->
      <MIcon
        name="send-msg"
        size="lg"
        class="mx-1 send-icon"
        :style="isRtl ? { transform: 'rotate(180deg)' } : {}"
        @click="sendMessage"
      />
    </div>
  </div>
</template>

<script>
import { authComputed } from '@state/modules/auth'
export default {
  name: 'AIBotCompose',
  props: {
    idleTimeout: { type: Number, default: 3000 },
    disabled: { type: Boolean, default: false },
    hasFile: { type: Boolean, default: false },
    confirmationMessage: { type: String, default: undefined },
  },
  data() {
    this.timer = null
    this.startTypingCalled = false
    return {
      value: '',
    }
  },
  computed: {
    ...authComputed,
    isDisabled() {
      if (this.hasFile) {
        return false
      }
      return this.disabled || !(this.value || '').trim()
    },
  },
  methods: {
    sendMessage() {
      if (this.value.trim()) {
        this.$emit('send-message', {
          message: this.value.replace(/<[^>]*>/g, ''),
        })
        this.$nextTick(() => {
          this.value = ''
        })
      }
    },
    onKeyUp(e) {
      if (e.key === 'Enter') {
        this.sendMessage()
      }
    },

    // onKeyUp(e) {
    //   if (this.timer) {
    //     clearInterval(this.timer)
    //     if (!this.startTypingCalled) {
    //       this.$emit('start-typing')
    //       this.startTypingCalled = true
    //     }
    //   }
    //   this.timer = setTimeout(() => {
    //     this.startTypingCalled = false
    //     this.$emit('stop-typing')
    //   }, this.idleTimeout)
    // },
  },
}
</script>
