<template>
  <div
    ref="aiBotMessagesContainer"
    class="scroll-wrapper"
    @scroll="handleMessagesScroll"
  >
    <div class="users-container">
      <div v-if="loadingHistory" class="history-loader">
        <MIcon name="spinner-third" class="fa-spin" size="lg" />
      </div>
      <template v-if="(messages || []).length">
        <AIBotMessage
          v-for="message in messages"
          :key="message.id"
          :message="message"
          :attachments="message.fileAttachments"
          :sender-id="isLoggedIn() ? message.owner : 0"
          :sender-name="message.ownerName"
          :participant="owner"
          :requester="requester"
          :is-my-message="
            message.owner === (owner || {}).id ||
            (!isLoggedIn() && message.owner === -93103)
          "
          :date="message.date"
          :confirmation-message="confirmationMessage"
          :chat-config="chatConfig"
          @send-message="$emit('send-message', $event)"
        />
      </template>
      <div v-if="!loadingHistory && showNoData && (messages || []).length <= 0">
        <FlotoNoData size="small" />
      </div>
    </div>
  </div>
</template>

<script>
import Bus from '@utils/emitter'
import Reverse from 'lodash/reverse'
import UniqBy from 'lodash/uniqBy'
import { chatBrainType } from '@data/ai-bot'
import { getAIBotChatHistoryApi } from '../chat-api'
import AIBotMessage from './ai-bot-message.vue'
import { isLoggedIn } from '@/src/utils/auth'

export default {
  name: 'AIBotChatMessages',
  components: { AIBotMessage },
  props: {
    room: { type: Object, default: undefined },
    chatLimit: { type: Number, default: 50 },
    owner: { type: Object, required: true },
    requester: { type: Object, default: undefined },
    socket: { type: Object, default: undefined },
    defaultMessages: {
      type: Array,
      default() {
        return []
      },
    },
    showNoData: { type: Boolean, default: false },
    chatConfig: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    this.isLoggedIn = isLoggedIn
    this.messageBoxScrollPosition = 0
    return {
      loadingHistory: false,
      messages: this.defaultMessages || [],
      lastOffset: 0,
      hasMoreMessages: true,
    }
  },
  computed: {
    confirmationMessage() {
      if (this.messages && this.messages.length) {
        return (this.messages[this.messages.length - 1] || {})
          .confirmationMessage
      }
      return undefined
    },
  },
  watch: {
    confirmationMessage(newValue) {
      this.$emit('confirmation-message', newValue)
    },
  },
  mounted() {
    if (this.room) {
      this.getChatHistory(this.lastOffset, this.chatLimit)
    }
    const aiBotMessageHandler = (payload) => {
      const excludedResponseType = [
        chatBrainType.USER_INPUT_DATE,
        chatBrainType.USER_STATIC_CHOICE,
        chatBrainType.USER_REF_CHOICE,
      ]
      this.messages = [
        ...this.messages.map((i) => ({
          ...i,
          responseType: excludedResponseType.includes(i.responseType)
            ? null
            : i.responseType,
        })),
        ...payload,
      ]
      this.$nextTick(() => this.resetScrollToBottom())
    }
    Bus.$on('app:send-message', aiBotMessageHandler)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('app:send-message', aiBotMessageHandler)
    })
    const aiBotReceiveMessageHandler = (payload) => {
      this.messages = [...this.messages, ...payload]
      this.$nextTick(() => this.resetScrollToBottom())
    }
    Bus.$on('app:receive-message', aiBotReceiveMessageHandler)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('app:receive-message', aiBotReceiveMessageHandler)
    })
  },
  methods: {
    getMessages() {
      return this.messages
    },
    handleMessagesScroll(e) {
      if (e.target.scrollTop === 0) {
        this.getChatHistory(this.lastOffset, this.chatLimit)
      }
    },
    getChatHistory(offset, limit) {
      if (!this.hasMoreMessages || this.loadingHistory || !this.room.id) {
        return
      }
      this.loadingHistory = true
      this.saveMessageBoxScrollPosition(offset / limit || 1)

      getAIBotChatHistoryApi(this.room.id, offset, limit).then((data) => {
        const orderedMessages = Reverse(data.messages)
        const botMessage = data.messages.find(
          (message) => message.owner === -93101
        )
        if (botMessage) {
          this.$emit('bot-name', botMessage.ownerName)
        }
        this.messages = UniqBy([...orderedMessages, ...this.messages], 'id')
        this.loadingHistory = false
        this.lastOffset = offset + limit
        this.hasMoreMessages = data.total > offset + limit
        this.$nextTick(() => {
          // if first time loading we scroll to bottom
          if (offset === 0) {
            this.resetScrollToBottom()
          } else {
            this.restoreMessageBoxScrollPosition()
          }
        })
      })
    },
    resetScrollToBottom() {
      if (this.$refs.aiBotMessagesContainer) {
        this.$refs.aiBotMessagesContainer.scrollTop =
          this.$refs.aiBotMessagesContainer.scrollHeight
      }
    },
    saveMessageBoxScrollPosition(pageNo) {
      this.messageBoxScrollPosition =
        this.$refs.aiBotMessagesContainer.scrollHeight / pageNo
    },
    restoreMessageBoxScrollPosition() {
      this.$refs.aiBotMessagesContainer.scrollTop =
        this.messageBoxScrollPosition
    },
  },
}
</script>
