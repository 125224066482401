<template>
  <a @click="trigger">
    <slot name="trigger" />
    <FlotoDrawerForm :open="isDrawerOpen" @cancel="cancelAction">
      <template v-slot:header>
        {{ $tc('update') }} {{ $tc('requester') }}
      </template>
      <MRow class="mb-4">
        <MCol class="text-right">
          <AddRequesterDrawer
            :key="renderCount"
            :user="currentSelection"
            @close-drawer="isDrawerOpen = false"
            @done="handleDone"
          >
            <template v-slot:trigger>
              <MButton
                :disabled="!Object.keys(currentSelection).length"
                variant="primary"
                class="ml-2"
              >
                {{ $tc('edit') }}
              </MButton>
            </template>
          </AddRequesterDrawer>
        </MCol>
      </MRow>
      <MRow>
        <MCol>
          <RequesterList
            selectable
            searchable
            :max-allowed-selection="1"
            @selection-change="handleChange"
          />
        </MCol>
      </MRow>
      <template v-slot:actions="{ hide }">
        <MButton variant="default" class="ml-2" @click="hide">
          {{ $tc('cancel') }}
        </MButton>
      </template>
    </FlotoDrawerForm>
  </a>
</template>

<script>
import Bus from '@utils/emitter'
import RequesterList from './requesters-list'
import Pick from 'lodash/pick'
import AddRequesterDrawer from './add-requester-drawer'

export default {
  name: 'UpdateRequesterDrawer',
  components: { RequesterList, AddRequesterDrawer },
  data() {
    return {
      isDrawerOpen: false,
      processing: false,
      currentSelection: {},
      renderCount: 1,
    }
  },
  methods: {
    trigger() {
      setTimeout(() => {
        this.isDrawerOpen = true
        this.$emit('trigger')
      })
    },
    cancelAction() {
      this.isDrawerOpen = false
    },
    handleChange($event) {
      if ($event.length) {
        this.currentSelection = Pick($event[0], [
          'id',
          'name',
          'email',
          'contactNo',
          'altContactNo1',
          'altContactNo2',
          'department',
          'location',
          'vip',
        ])
      } else {
        this.currentSelection = {}
      }
      this.renderCount++
    },
    handleDone(user) {
      Bus.$emit('app:contactops_channel_updated', {
        unknown: false,
        requesterId: user.id,
        requesterName: user.name,
      })
      this.$emit('done', user)
    },
  },
}
</script>
