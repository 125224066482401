<template>
  <MRow :gutter="0" class="flex-1">
    <!-- <MCol v-if="analytics.stats" :size="12" class="my-2">
      <MRow class="my-2">
        <MCol v-if="analytics.approvalType" auto-size>
          <div>
            <small class="text-neutral-light">{{ $t('decision_type') }}</small>
          </div>
          <MTag
            variant="neutral-lighter"
            :closable="false"
            class="cursor-default"
          >
            {{ $t(analytics.approvalType) }}
          </MTag>
        </MCol>
        <MCol v-if="analytics.status" auto-size>
          <div>
            <small class="text-neutral-light">{{ $t('status') }}</small>
          </div>
          <StatusIcon :status="analytics.status" show-status />
        </MCol>
      </MRow>
      <MRow class="justify-between">
        <MCol auto-size class="flex flex-col items-center">
          <div>
            <small class="text-neutral-light">
              {{ $t('total') }} {{ $tc('approval', 2) }}
            </small>
          </div>
          <span class="rounded-counter">
            {{ analytics.stats.total }}
          </span>
        </MCol>
        <MCol auto-size class="flex flex-col items-center">
          <div>
            <small class="text-neutral-light">
              {{ $t('approval_needed') }}
            </small>
          </div>
          <span class="rounded-counter info">
            {{ analytics.stats.needed }}
          </span>
        </MCol>
        <MCol auto-size class="flex flex-col items-center">
          <div>
            <small class="text-neutral-light">
              {{ $t('total') }} {{ $tc('approved') }}
            </small>
          </div>
          <span class="rounded-counter success">
            {{ analytics.stats.approved }}
          </span>
        </MCol>
        <MCol auto-size class="flex flex-col items-center">
          <div>
            <small class="text-neutral-light">
              {{ $t('total') }} {{ $tc('pending') }}
            </small>
          </div>
          <span class="rounded-counter warning">
            {{ analytics.stats.pending }}
          </span>
        </MCol>
        <MCol auto-size class="flex flex-col items-center">
          <div>
            <small class="text-neutral-light">
              {{ $t('total') }} {{ $tc('ignored') }}
            </small>
          </div>
          <span class="rounded-counter neutral-lighter">
            {{ analytics.stats.ignored }}
          </span>
        </MCol>
        <MCol auto-size class="flex flex-col items-center">
          <div>
            <small class="text-neutral-light">
              {{ $t('total') }} {{ $tc('rejected') }}
            </small>
          </div>
          <span class="rounded-counter error">
            {{ analytics.stats.rejected }}
          </span>
        </MCol>
      </MRow>
      <MDivider />
    </MCol> -->
    <MCol :size="12">
      <FlotoCrudContainer
        ref="approvalListRef"
        :scrollable="false"
        :fetch-fn="fetchAllApprovals"
        :update-fn="getApproval"
        :disabled="disabled"
      >
        <template v-slot:form>
          <span />
        </template>
        <template v-slot:list-items="{ items, update }">
          <MCollapse :bordered="true" class="flex-1">
            <MCollapsePanel
              v-for="approval in items"
              :key="getKey(approval)"
              :class="{ 'archived-approval': approval.archived }"
            >
              <template v-slot:header>
                <ApprovalHeader
                  :disabled="disabled || approval.archived"
                  :approval="approval"
                  @re-request-approval="
                    () => hadleReRequestApproval(approval.id)
                  "
                />
              </template>
              <ApprovalStages
                :disabled="
                  isPortalLogin && allowCommentOnApproval
                    ? false
                    : disabled || approval.archived
                "
                :stage="approval"
                :module-name="moduleName"
                :allow-comment-on-approval="allowCommentOnApproval"
                @refresh="update({ id: approval.id })"
                @refresh-page="$emit('refresh-page')"
              />
            </MCollapsePanel>
          </MCollapse>
        </template>
      </FlotoCrudContainer>
    </MCol>
  </MRow>
</template>

<script>
import {
  getResourceApprovalsApi,
  getResourceApprovalApi,
  getApprovalAnalyticsApi,
  reRequestReferredBackApprovalApi,
} from './approval-api'
import ApprovalHeader from './components/approval-header.vue'
import ApprovalStages from './components/approval-stages'
import { authComputed } from '@state/modules/auth'
// import StatusIcon from './components/approval-status-icon.vue'

export default {
  name: 'ApprovalContainer',
  components: { ApprovalHeader, ApprovalStages },
  props: {
    disabled: { type: Boolean, default: false },
    moduleName: { type: String, required: true },
    resourceId: { type: Number, required: true },
    allowCommentOnApproval: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: true,
      analytics: {},
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {
    getAnalytics() {
      this.loading = true
      this.analytics = {}
      getApprovalAnalyticsApi(this.moduleName, this.resourceId).then(
        (analytics) => {
          this.analytics = analytics
          this.loading = false
        }
      )
    },
    fetchAllApprovals(limit, offset) {
      return getResourceApprovalsApi(
        this.moduleName,
        this.resourceId,
        limit,
        offset
      )
    },
    getApproval(data) {
      return getResourceApprovalApi(
        this.moduleName,
        this.resourceId,
        data.id
      ).then((response) => {
        this.$emit('refresh')
        this.getAnalytics()
        return response
      })
    },
    refresh() {
      this.$refs.approvalListRef.refresh()
    },
    hadleReRequestApproval(approvalId) {
      reRequestReferredBackApprovalApi(approvalId).then(() => {
        this.refresh()
      })
    },
    getKey(approval) {
      return approval.id
    },
  },
}
</script>

<style lang="less">
.archived-approval {
  &::before {
    position: absolute;
    padding: 1px 4px 0 2px;
    font-size: 9px;
    color: var(--secondary-red-dark);
    content: 'Archived';
    background: var(--error-outline-button-hover-bg);
    border-radius: 0 0 6px;
  }

  border-left: 4px solid var(--secondary-red-dark) !important;
}
</style>
