<template>
  <a @click="trigger">
    <slot name="trigger" />
    <FlotoDrawerForm
      :open="isDrawerOpen"
      @submit="handleFormSubmit"
      @cancel="cancelAction"
    >
      <template v-slot:header>
        {{ `${$tc('transfer')}/${$tc('conference')} ${$tc('call')}` }}
      </template>
      <MRow>
        <MCol :size="12" class="mx-2">
          <MRow>
            <MCol :size="12">
              <OutgoingCallBtn
                :disabled="isRinging"
                :call-fn="handleCall"
                placement="bottomLeft"
              >
                <template v-slot:trigger>
                  <a
                    class="text-primary"
                    :class="{ 'cursor-pointer': !isRinging }"
                  >
                    <span v-if="!isRinging">
                      <MIcon
                        name="phone-volume"
                        size="2x"
                        class="text-primary mr-2"
                      />
                    </span>
                    <span v-if="isRinging">
                      {{ `${$tc(callText)}...` }}
                      <div>
                        {{ externalNumber }}
                      </div>
                    </span>
                    <span v-else class="md-text-base">
                      {{ `${$tc('dial')} ${$tc('external')} ${$tc('number')}` }}
                    </span>
                  </a>
                </template>
              </OutgoingCallBtn>
            </MCol>
          </MRow>
          <MDivider />
          <MRow>
            <MCol :size="12">
              <h5>{{ `${$tc('active')} ${$tc('agent', 2)}` }}</h5>
            </MCol>
          </MRow>
          <MRow>
            <MCol :size="12">
              <AvailableAgentsTab :diabled="isRinging" />
            </MCol>
          </MRow>
        </MCol>
      </MRow>
      <template v-if="isRinging" v-slot:actions="{ hide }">
        <MButton
          variant="success"
          class="mr-2"
          @click="handleTransferCall(hide)"
        >
          {{ $tc('transfer') }} {{ $tc('call') }}
          <MIcon name="phone-alt" class="text-white" />
          <MIcon
            class="text-white"
            name="long-arrow-right"
            style="position: relative; bottom: 3px; left: -5px; font-size: 13px"
          />
        </MButton>
        <MButton variant="primary" @click="handleConferenceCall(hide)">
          {{ $tc('conference') }} {{ $tc('call') }}
          <MIcon
            name="headphones-alt"
            class="text-white"
            style="position: relative; bottom: 2px"
          />
        </MButton>
        <MButton variant="error" class="ml-2" @click="handleEndCall">
          {{ $tc('end') }}
          <MIcon
            name="phone-alt"
            class="text-white"
            style="transform: rotate(133deg)"
          />
        </MButton>
      </template>
    </FlotoDrawerForm>
  </a>
</template>

<script>
import OutgoingCallBtn from '@components/contactOps/components/outgoing-call-btn'
import AvailableAgentsTab from './available-agents'
import {
  dialSecondCallApi,
  transferToExternalCallApi,
  endExternalCallApi,
  conferenceExternalCallApi,
} from '../api'
import { transformContactOpsChannel } from '../data'
import * as STATUSES from '../helpers/channel-statuses'
import Bus from '@utils/emitter'

export default {
  name: 'TransferCallDrawer',
  components: {
    AvailableAgentsTab,
    OutgoingCallBtn,
  },
  data() {
    this.STATUSES = STATUSES
    return {
      isDrawerOpen: false,
      processing: false,
      currentTab: 'available_agents',
      formData: {},
      isRinging: false,
      channelData: {},
      callText: 'calling',
      externalNumber: null,
    }
  },
  created() {
    const handleContactOpsChannelUpdated = (payload) => {
      const channel = transformContactOpsChannel(payload)
      this.channelData = channel
      this.externalNumber = channel.secondCallDetails.number
      if (
        [STATUSES.ON_CALL, STATUSES.BUSY].indexOf(
          channel.secondCallDetails.status
        ) >= 0
      ) {
        this.isRinging = true
        this.callText = 'on_call'
      }
      if (
        [STATUSES.AVAILABLE, STATUSES.NOT_AVAILABLE].indexOf(
          channel.secondCallDetails.status
        ) >= 0
      ) {
        this.isRinging = false
      }
    }
    Bus.$on('contactops_channel_updated', handleContactOpsChannelUpdated)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('contactops_channel_updated', handleContactOpsChannelUpdated)
    })
  },
  methods: {
    trigger() {
      setTimeout(() => {
        this.isDrawerOpen = true
        this.$emit('trigger')
      })
    },
    cancelAction() {
      this.isDrawerOpen = false
      this.currentTab = 'available_agents'
      this.formData = {}
    },
    handleFormSubmit() {
      this.processing = true
    },
    handleCall(number) {
      return dialSecondCallApi(number)
    },
    handleTransferCall(hideFn) {
      transferToExternalCallApi(this.externalNumber).then(() => {
        hideFn()
      })
    },
    handleEndCall() {
      endExternalCallApi(this.externalNumber).then(() => {
        this.isRinging = false
        this.callText = 'calling'
      })
    },
    handleConferenceCall(hideFn) {
      conferenceExternalCallApi(this.externalNumber).then(() => {
        hideFn()
      })
    },
  },
}
</script>
