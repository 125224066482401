<script>
import Moment from 'moment'
import Merge from 'lodash/merge'
// import { UserPreferenceComputed } from '@state/modules/user-preference'
import getChartCommonOptions from './chart-common-options'
import { WidgetTypeConstants, FontSizeMap } from '@components/chart/helper'
import { downloadWidgetApi } from '@src/modules/dashboard/dashboard-api.js'

export default {
  name: 'ChartOptions',
  props: {
    widgetType: { type: String, default: 'line' },
    height: { type: Number, default: undefined },
    isAvailabilityChart: { type: Boolean, default: false },
    vertical: { type: Boolean, default: false },
    stacked: { type: Boolean, default: false },
    dateTime: { type: Boolean, default: false },
    categories: { type: Array, default: undefined },
    chartType: { type: String, default: 'line' },
    unit: { type: String, default: undefined },
    enableLegend: { type: Boolean, default: false },
    disableDataPointClick: { type: Boolean, default: false },
    // eslint-disable-next-line
    enableDataLabels: { type: Boolean, default: true },
    maxY: { type: Number, default: undefined },
    hideXAxis: { type: Boolean, default: false },
    legendOptions: {
      type: Object,
      default() {
        return {}
      },
    },
    // eslint-disable-next-line
    useInstanceInTooltip: { type: Boolean, default: true },
    // eslint-disable-next-line
    enableExport: { type: Boolean, default: true },
    data: {
      type: [Array, Object],
      required: true,
    },
    additionalSeries: {
      type: [Array, Object],
      default() {
        return []
      },
    },
    widget: {
      type: Object,
      default() {
        return {
          widgetProperties: {},
        }
      },
    },
    // eslint-disable-next-line
    allowDecimal: { type: Boolean, default: true },
    alertSeries: { type: [Array, Object], default: undefined },
    pieInnerSize: { type: String, default: undefined },
    markOutsideRange: { type: Boolean, default: false },
    // eslint-disable-next-line
    serverSideZoom: { type: Boolean, default: true },
    filters: {
      type: Object,
      default() {
        return {}
      },
    },
    enabledCustomDownload: { type: Boolean, default: false },
  },
  computed: {
    // ...UserPreferenceComputed,
    appliedChartType() {
      const widgetType = this.widgetType
      if (
        [
          WidgetTypeConstants.STACKED_VERTICAL_BAR,
          WidgetTypeConstants.VERTICAL_BAR,
          WidgetTypeConstants.STACKED_HORIZONTAL_BAR,
          WidgetTypeConstants.HORIZONTAL_BAR,
          WidgetTypeConstants.HORIZONTAL,
        ].indexOf(widgetType) >= 0
      ) {
        return this.vertical ? 'column' : 'bar'
      } else if (widgetType === WidgetTypeConstants.STACKED_AREA) {
        return 'areaspline'
      } else if (widgetType === WidgetTypeConstants.SANKEY) {
        return 'sankey'
      } else if (widgetType === WidgetTypeConstants.STACKED_LINE) {
        return 'spline'
      } else if (widgetType === WidgetTypeConstants.PIE) {
        return 'pie'
      } else if (widgetType === WidgetTypeConstants.AREA) {
        return 'areaspline'
      } else if (widgetType === WidgetTypeConstants.LINE) {
        return 'spline'
      }
      if (this.chartType === 'line') {
        return 'spline'
      } else if (this.chartType === 'area') {
        return 'areaspline'
      }
      return this.chartType
    },
    options() {
      const isStacked = this.stacked
      const categories = this.categories
      const height = this.height
      const dateTime = this.dateTime
      const labelProperty =
        (this.widget.widgetProperties || {}).labelProperty || {}
      const chartOptions =
        (this.widget.widgetProperties || {}).chartOptions || {}
      const unit = this.unit
      const allowDecimal = this.allowDecimal
      const enableLegend = this.enableLegend
      const disableDataPointClick = this.disableDataPointClick
      const enableDataLabels = this.enableDataLabels
      const enableExport = this.enableExport
      const hideXAxis = this.hideXAxis
      let data = this.data || []
      const options = getChartCommonOptions(
        [
          ...data,
          ...(this.additionalSeries && Array.isArray(this.additionalSeries)
            ? this.additionalSeries
            : [this.additionalSeries]),
        ],
        this.widget.widgetProperties,
        {
          hasDateTime: dateTime,
          timezone: this.timezone,
          dateFormat: this.dateFormat,
          serverSideZoom: this.serverSideZoom,
          unit: this.unit,
          useInstanceInTooltip: this.useInstanceInTooltip,
        }
      )
      const appliedOptions = Merge(options, {
        chart: {
          type: this.appliedChartType,
          ...(dateTime ? { zoomType: 'x' } : {}),
          ...(height ? { height } : {}),
        },
        ...(enableLegend
          ? {
              legend: {
                enabled: true,
                verticalAlign: 'bottom',
                ...this.legendOptions,
              },
            }
          : {}),
        xAxis: {
          ...(categories && !dateTime
            ? {
                categories,
              }
            : {}),
          ...(dateTime
            ? {
                type: 'datetime',
              }
            : {}),
          ...(!isNaN(labelProperty.rotation)
            ? {
                labels: {
                  rotation: +labelProperty.rotation,
                },
              }
            : {}),
          ...(hideXAxis
            ? {
                visible: false,
              }
            : {}),
        },
        yAxis: {
          ...(unit
            ? {
                labels: {
                  format: `{value} ${unit || ''}`,
                },
              }
            : {}),
          ...(this.maxY
            ? {
                max: this.maxY,
              }
            : {}),
          ...(allowDecimal
            ? {
                allowDecimal: allowDecimal,
              }
            : {}),
        },
        plotOptions: {
          series: {
            centerInCategory: true,
            ...(isStacked ? { stacking: 'normal' } : {}),
            ...(['bar', 'column'].includes(this.appliedChartType)
              ? {
                  minPointLength: 1,
                }
              : {}),
            ...(enableDataLabels
              ? {
                  dataLabels: {
                    enabled: true,

                    filter: { operator: '>', property: 'y', value: 0 },
                  },
                }
              : {}),
            point: {
              events: {
                ...(disableDataPointClick
                  ? {
                      click: null,
                    }
                  : {}),
                mouseOver: function (event) {
                  if (Array.isArray(event.target.series.options.isOutOfRange)) {
                    if (
                      event.target.series.options.isOutOfRange[
                        event.target.index
                      ]
                    ) {
                      event.target.update({ color: 'var(--secondary-red)' })
                    } else {
                      event.target.update({ color: event.target.series.color })
                    }
                  }
                },
              },
            },
          },
          areasplinerange: {
            marker: {
              enabled: true,
            },
          },
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            ...(this.pieInnerSize ? { innerSize: this.pieInnerSize } : {}),
            dataLabels: {
              enabled: enableDataLabels,
              formatter: function () {
                const index = this.point.index
                const formattedValues =
                  this.point.series.userOptions.formattedValues
                if (formattedValues && formattedValues.length) {
                  return `${this.point.name}: ${formattedValues[index]}`
                }
                return `${this.point.name}: ${this.point.y}`
              },
              rotation: isNaN(labelProperty.rotation)
                ? 0
                : labelProperty.rotation,
              style: {
                fontWeight: 'normal',
                fontSize: FontSizeMap['small'],
                ...(labelProperty.ellipsisEnabled
                  ? {
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }
                  : {}),
              },
            },
            showInLegend: true,
          },
        },
        ...(enableExport
          ? {
              exporting: {
                enabled: true,
                filename: this.widget.name,
                ...(this.enabledCustomDownload
                  ? {
                      menuItemDefinitions: {
                        downloadXLS: {
                          textKey: 'downloadXLS',
                          onclick: () => this.downloadWidget('excel', 'xlsx'),
                        },
                        downloadCSV: {
                          textKey: 'downloadCSV',
                          onclick: () => this.downloadWidget('csv', 'csv'),
                        },
                      },
                    }
                  : {}),
              },
            }
          : {}),
      })
      return Merge(appliedOptions, chartOptions)
    },
  },
  methods: {
    downloadWidget(type, extension) {
      return downloadWidgetApi(this.filters, this.widget.id, type).then(
        (data) => {
          import('file-saver').then((FS) =>
            FS.saveAs(
              data,
              `${this.widget.name}-${Moment().format(
                'ddd_MMM_DD_YYYY_hhmmA'
              )}.${extension}`
            )
          )
          return data
        }
      )
    },
  },
  render() {
    return this.$scopedSlots.default({
      options: this.options,
    })
  },
}
</script>
