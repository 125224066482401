<template>
  <div class="flex">
    <MRow :gutter="16" class="flex-1 pr-2">
      <MCol :size="6">
        <FlotoFormItem
          id="approval-type"
          :label="$t('set_decision')"
          :vid="`${guid}-approvalType`"
        >
          <MRadioGroup
            v-model="approvalType"
            as-button
            :options="approvalTypeOptions"
          />
        </FlotoFormItem>
        <FlotoFormItem
          id="requester-group"
          :label="$t('requester_group')"
          :vid="`${guid}-requesterGroups`"
        >
          <FlotoRequesterGroupPicker
            v-model="requesterGroups"
            multiple
            as-input
            mandatory-selection
          />
        </FlotoFormItem>
        <FlotoFormItem
          id="technician-group"
          :label="$t('technician_group')"
          :vid="`${guid}-technicianGroups`"
        >
          <FlotoTechnicianGroupPicker
            v-model="technicianGroups"
            multiple
            as-input
            mandatory-selection
          />
        </FlotoFormItem>
        <FlotoFormItem
          id="requester-picker"
          :label="$t('set_users')"
          :vid="`${guid}-users`"
        >
          <FlotoRequesterPicker v-model="users" multiple as-input />
        </FlotoFormItem>
        <FlotoFormItem
          v-if="
            [
              $constants.ASSET,
              $constants.ASSET_HARDWARE,
              $constants.ASSET_NON_IT,
              $constants.ASSET_CONSUMABLE,
            ].indexOf(moduleName) >= 0
          "
          id="include-asset-user"
          :validation-label="$t('user')"
          :vid="`${guid}-includeAssetUser`"
        >
          <MCheckbox v-model="includeAssetUser">
            {{ $tc('asset') }} {{ $tc('user') }}
          </MCheckbox>
        </FlotoFormItem>
        <FlotoFormItem
          v-if="[$constants.CMDB].indexOf(moduleName) >= 0"
          id="include-ci-user"
          :validation-label="$t('user')"
          :vid="`${guid}-includeAssetUser`"
        >
          <MCheckbox v-model="includeAssetUser">
            {{ $tc('ci') }} {{ $tc('user') }}
          </MCheckbox>
        </FlotoFormItem>
        <FlotoFormItem
          v-if="
            [
              $constants.REQUEST,
              $constants.SERVICE_CATALOG,
              $constants.PROBLEM,
              $constants.CHANGE,
              $constants.RELEASE,
            ].indexOf(moduleName) >= 0
          "
          id="include-manager-user"
          class="mb-0"
          :validation-label="$t('manager_head')"
          :vid="`${guid}-includeManagerUser`"
        >
          <MCheckbox v-model="includeManagerUser">
            {{ $tc('manager_head') }}
          </MCheckbox>
        </FlotoFormItem>
        <FlotoFormItem
          v-if="
            [
              $constants.REQUEST,
              $constants.SERVICE_CATALOG,
              $constants.PROBLEM,
              $constants.CHANGE,
              $constants.RELEASE,
            ].indexOf(moduleName) >= 0
          "
          id="include-assignee-manager-user"
          class="mb-0"
          :validation-label="$t('assignee_manager')"
          :vid="`${guid}-includeAssigneeManagerUser`"
        >
          <MCheckbox v-model="includeAssigneeManagerUser">
            {{ $tc('assignee_manager') }}
          </MCheckbox>
        </FlotoFormItem>
        <FlotoFormItem
          v-if="
            [
              $constants.REQUEST,
              $constants.SERVICE_CATALOG,
              $constants.PROBLEM,
              $constants.CHANGE,
              $constants.RELEASE,
            ].indexOf(moduleName) >= 0
          "
          id="include-department-head"
          :validation-label="$t('department_head')"
          :vid="`${guid}-includeDepartmetHead`"
        >
          <MCheckbox v-model="includeDepartmetHead">
            {{ $tc('department_head') }}
          </MCheckbox>
        </FlotoFormItem>

        <template
          v-if="
            [
              $constants.REQUEST,
              $constants.PROBLEM,
              $constants.CHANGE,
              $constants.RELEASE,
              $constants.SERVICE_CATALOG,
            ].indexOf(moduleName) >= 0
          "
        >
          <div class="mt-3">
            <h5>{{ $tc('action_on_stage_activation') }}</h5>
            <MDivider class="mt-0" />
            <FlotoFormItem
              id="technician-group"
              :label="$t('set_technician_group')"
              :vid="`${guid}-groupId`"
            >
              <FlotoTechnicianGroupPicker
                v-model="groupId"
                as-input
                mandatory-selection
                validate-archived-value
              />
            </FlotoFormItem>
            <FlotoFormItem
              id="technician"
              :label="$tc('set_technician')"
              :vid="`${guid}-technicianId`"
            >
              <FlotoTechnicianPicker
                v-model="technicianId"
                as-input
                mandatory-selection
                :group-id="groupId"
              />
            </FlotoFormItem>
          </div>
        </template>
      </MCol>
      <MCol :size="6">
        <FlotoFormItem
          id="subject"
          v-model="subject"
          :label="$t('subject')"
          rules="required|subject"
          :vid="`${guid}-subject`"
          :placeholder="$t('subject')"
        />
        <FlotoFormItem
          id="description"
          v-model="description"
          :rows="4"
          :label="$t('description')"
          :vid="`${guid}-description`"
          :placeholder="$t('description_instruction')"
          type="textarea"
        />
      </MCol>
    </MRow>
    <MRow
      v-if="actionInputIndex !== 0"
      :gutter="16"
      class="justify-end relative"
      :style="{ top: '25px', marginTop: '-25px' }"
    >
      <MCol class="flex-no-grow" auto-size>
        <slot name="actions" />
        <MTooltip placement="bottomRight">
          <template v-slot:trigger>
            <a
              class="text-secondary-red remove-action"
              @click="$emit('remove')"
            >
              <MIcon name="times-circle" size="lg" />
            </a>
          </template>
          {{ $t('remove') }} {{ $tc('action') }}
        </MTooltip>
      </MCol>
    </MRow>
  </div>
</template>

<script>
import { generateId } from '@utils/id'
import { ApprovalTypeOptions } from '@data/approval'

export default {
  name: 'ApprovalActionInput',
  model: {
    event: 'change',
  },
  props: {
    value: { type: Object, default: undefined },
    moduleName: { type: String, default: undefined },
    actionInputIndex: { type: Number, default: -1 },
  },
  data() {
    this.guid = generateId()
    this.approvalTypeOptions = ApprovalTypeOptions()
    return {}
  },
  computed: {
    approvalType: {
      get() {
        return (this.value || {}).approvalType
      },
      set(approvalType) {
        return this.$emit('change', { ...(this.value || {}), approvalType })
      },
    },
    requesterGroups: {
      get() {
        return (this.value || {}).requesterGroups
      },
      set(requesterGroups) {
        return this.$emit('change', { ...(this.value || {}), requesterGroups })
      },
    },
    technicianGroups: {
      get() {
        return (this.value || {}).technicianGroups
      },
      set(technicianGroups) {
        return this.$emit('change', {
          ...(this.value || {}),
          technicianGroups,
        })
      },
    },
    groupId: {
      get() {
        return (this.value || {}).groupId
      },
      set(groupId) {
        return this.$emit('change', { ...(this.value || {}), groupId })
      },
    },
    technicianId: {
      get() {
        return (this.value || {}).technicianId
      },
      set(technicianId) {
        return this.$emit('change', { ...(this.value || {}), technicianId })
      },
    },
    users: {
      get() {
        return (this.value || {}).users
      },
      set(users) {
        return this.$emit('change', {
          ...(this.value || {}),
          users,
        })
      },
    },
    includeAssetUser: {
      get() {
        return (this.value || {}).includeAssetUser
      },
      set(includeAssetUser) {
        return this.$emit('change', { ...(this.value || {}), includeAssetUser })
      },
    },
    includeManagerUser: {
      get() {
        return (this.value || {}).includeManagerUser
      },
      set(includeManagerUser) {
        return this.$emit('change', {
          ...(this.value || {}),
          includeManagerUser,
        })
      },
    },
    includeDepartmetHead: {
      get() {
        return (this.value || {}).includeDepartmetHead
      },
      set(includeDepartmetHead) {
        return this.$emit('change', {
          ...(this.value || {}),
          includeDepartmetHead,
        })
      },
    },
    includeAssigneeManagerUser: {
      get() {
        return (this.value || {}).includeAssigneeManagerUser
      },
      set(includeAssigneeManagerUser) {
        return this.$emit('change', {
          ...(this.value || {}),
          includeAssigneeManagerUser,
        })
      },
    },
    subject: {
      get() {
        return (this.value || {}).subject
      },
      set(subject) {
        return this.$emit('change', { ...(this.value || {}), subject })
      },
    },
    description: {
      get() {
        return (this.value || {}).description
      },
      set(description) {
        return this.$emit('change', { ...(this.value || {}), description })
      },
    },
  },
}
</script>
