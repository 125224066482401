import api from '@api'
import { transformRecentCall, transformAgents } from '@data/contactops'
import {
  transformTicketForServer,
  transformTicketForDetail,
} from '@data/ticket'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@/src/utils/get-module-translator'
import { transformCallbackReminder } from './data'
import { transformUser, transformUserForServer } from '@data/user'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function updateCallActionApi(action, data = {}) {
  return api.post(`/contactOps/call/${action}`, data)
}

export function getRecentCallsApi(id) {
  return api.get(`/contactOps/requester/${id}/recentcalls`).then((data) => {
    return {
      items: (data || []).objectList.map(transformRecentCall),
    }
  })
}

export function getWaitingCallCountApi() {
  return api.get('/contactOps/waitingcalls/count')
}

export function getHoldCallCountApi() {
  return api.get('/contactOps/onholdcall/count')
}

export function createCallbackReminderApi(reminder) {
  return api.post(`/telephony/reminder`, transformCallbackReminder(reminder), {
    message: __rootT('added_successfully', {
      resource: __rootTc('callback_reminder'),
    }),
  })
}

export function blockNumberApi(data) {
  return api.post('/contactOps/blocknumber', data, {
    message: __rootTc('blocked_number'),
  })
}

export function createRequestApi(moduleName, request) {
  return api
    .post(`/contactOps/${moduleName}`, transformTicketForServer(request), {
      message: __rootT('created_successfully', {
        resource: __rootTc(moduleName, 1),
      }),
    })
    .then(transformTicketForDetail)
}

export function createUserApi(data) {
  return api
    .post(`/contactOps/requester`, transformUserForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('requester'),
      }),
    })
    .then(transformUser)
}

export function updateUserApi(data) {
  return api
    .patch(`/contactOps/requester/${data.id}`, transformUserForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('requester'),
      }),
    })
    .then(transformUser)
}

export function getActiveAgentsApi() {
  return api.get('/contactOps/activeAgents').then((data) => {
    return {
      items: (data.objectList || []).map(transformAgents),
      // total: data.totalCount,
    }
  })
}

export function dialSecondCallApi(number) {
  return api.post(
    `/contactOps/secondcall/dial`,
    {},
    {
      params: {
        callerNumber: number,
      },
    }
  )
}

export function transferToExternalCallApi(number) {
  return api.post(
    `/contactOps/secondcall/transfer`,
    {},
    {
      params: {
        callerNumber: number,
      },
    }
  )
}

export function endExternalCallApi(number) {
  return api.post(
    `/contactOps/secondcall/drop`,
    {},
    {
      params: {
        callerNumber: number,
      },
    }
  )
}

export function conferenceExternalCallApi(number) {
  return api.post(
    '/contactOps/secondcall/conference',
    {},
    {
      params: {
        callerNumber: number,
      },
    }
  )
}
