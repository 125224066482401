<template>
  <MRow class="items-start">
    <MCol class="ml-2 py-2">
      <FlotoReadMore>
        <FlotoRichEditor
          :value="feedback.text"
          :disabled="true"
          class="flex-1 border-none focusable mb-1"
        />
        <FormConsumer
          v-if="fields.length"
          :value="feedback.fieldValueDetails"
          disabled
          :form-fields="fields"
          avoid-default-value
        />
      </FlotoReadMore>
    </MCol>
  </MRow>
</template>

<script>
import { FormComputed } from '@state/modules/form'
import FormConsumer from '@components/form-consumer.vue'

export default {
  name: 'Feedback',
  components: { FormConsumer },
  props: { feedback: { type: Object, required: true } },
  data() {
    return {}
  },
  computed: {
    ...FormComputed,
    fields() {
      return this.feedbackFields().filter(
        (f) =>
          this.feedback.fieldValueDetails[f.id] ||
          this.feedback.fieldValueDetails[f.id] === false
      )
    },
  },
}
</script>
