<template>
  <FlotoCrudContainer
    ref="list"
    :fetch-fn="getAuditItems"
    :scrollable="false"
    :resource-name="$t('audit_trail')"
  >
    <template v-slot:list-items="{ items }">
      <MRow>
        <MCol class="my-2">
          <MTimeline mode="left" class="pl-2">
            <Audit
              v-for="timeline in items"
              :key="timeline.id"
              :parent-id="resourceId"
              :module-name="moduleName"
              v-bind="timeline"
            />
          </MTimeline>
        </MCol>
      </MRow>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import { getAuditApi } from './api'
import Audit from './audit'

export default {
  name: 'AuditContainer',
  components: { Audit },
  props: {
    resourceId: { type: Number, required: true },
    moduleName: { type: String, required: true },
  },
  methods: {
    getAuditItems(limit, offset) {
      return getAuditApi({}, this.moduleName, this.resourceId, limit, offset)
    },
    refresh() {
      this.$refs.list.refresh()
    },
  },
}
</script>
