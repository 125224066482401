import Constants from '@constants'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'

const __translator = getRootTranslator()
const __tc = getRootPluaralTranslator()

export function ConversationType() {
  return {
    technician_to_requester: {
      enableMention: true,
      editorToolbar: true,
      icon: 'reply-to-requester',
      borderClass: 'border-neutral',
      activeBgClass: 'bg-neutral',
      textClass: 'text-neutral',
      type: 'technician_to_requester',
      command: 'reply',
      modules: [Constants.REQUEST],
      order: 1,
      name: __translator('reply'),
      filterName: __translator('requester_communication'),
    },
    requestor_to_technician: {
      enableMention: true,
      editorToolbar: true,
      icon: 'reply-to-requester',
      borderClass: 'border-secondary-orange',
      activeBgClass: 'bg-secondary-orange',
      textClass: 'text-secondary-orange',
      type: 'requestor_to_technician',
      modules: [Constants.REQUEST],
      order: 1,
      command: 'reply',
      name: __tc('reply'),
      filterName: __translator('technician_communication'),
    },
    forward_notification: {
      enableMention: true,
      editorToolbar: true,
      icon: 'share',
      borderClass: 'border-primary-alt',
      activeBgClass: 'bg-primary-alt',
      textClass: 'text-primary-alt',
      type: 'forward_notification',
      modules: [Constants.REQUEST],
      order: 1,
      command: 'forward',
      name: __tc('forward'),
      filterName: __translator('forward'),
    },
    solution: {
      enableMention: true,
      editorToolbar: true,
      icon: 'puzzle-piece',
      borderClass: 'border-secondary-green',
      activeBgClass: 'bg-secondary-green',
      textClass: 'text-secondary-green',
      type: 'solution',
      command: 'solution',
      order: 5,
      name: __translator('solution'),
      modules: [Constants.REQUEST, Constants.PROBLEM],
    },
    conclusion: {
      enableMention: false,
      editorToolbar: true,
      icon: 'puzzle-piece',
      borderClass: 'border-secondary-green',
      activeBgClass: 'bg-secondary-green',
      textClass: 'text-secondary-green',
      type: 'conclusion',
      command: 'conclusion',
      order: 6,
      name: __translator('conclusion'),
      modules: [Constants.USER_SURVEY],
    },
    diagnosis: {
      enableMention: true,
      editorToolbar: true,
      icon: 'diagnosis',
      borderClass: 'border-primary',
      activeBgClass: 'bg-primary',
      textClass: 'text-primary',
      type: 'diagnosis',
      command: 'diagnosis',
      order: 4,
      name: __translator('diagnosis'),
      modules: [Constants.REQUEST],
    },
    collaborate: {
      enableMention: true,
      editorToolbar: true,
      icon: 'handshake',
      borderClass: 'border-secondary-yellow',
      activeBgClass: 'bg-secondary-yellow',
      textClass: 'text-secondary-yellow',
      type: 'collaborate',
      order: 2,
      command: 'collaborate',
      name: __tc('collaborate'),
      filterName: __tc('collaboration', 2),
      modules: [
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
        Constants.PURCHASE,
        Constants.USER_SURVEY,
        Constants.PROJECT,
      ],
    },
    note: {
      enableMention: true,
      editorToolbar: true,
      icon: 'conversation-note',
      borderClass: 'border-secondary-redDark',
      activeBgClass: 'bg-secondary-redDark',
      textClass: 'text-secondary-redDark',
      type: 'note',
      order: 3,
      name: __tc('note'),
      command: 'note',
      filterName: __tc('note', 2),
      modules: [
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
        Constants.PURCHASE,
        Constants.USER_SURVEY,
        Constants.PROJECT,
      ],
    },
    feedback: {
      canCreate: false,
      icon: 'comment-smile',
      activeBgClass: 'bg-secondary-orange',
      textClass: 'text-secondary-orange',
      borderClass: 'border-secondary-orange',
      type: 'feedback',
      order: 6,
      name: __tc('feedback'),
      modules: [Constants.REQUEST],
    },
    comment_on_task: {
      enableMention: false,
      editorToolbar: true,
      icon: 'comment-lines',
      borderClass: 'border-neutral',
      activeBgClass: 'bg-neutral',
      textClass: 'text-neutral',
      type: 'comment_on_task',
      order: 7,
      name: __tc('comment'),
      modules: [Constants.TASK],
    },
    comment_on_approval: {
      enableMention: false,
      editorToolbar: true,
      icon: 'comment-lines',
      borderClass: 'border-neutral',
      activeBgClass: 'bg-neutral',
      textClass: 'text-neutral',
      type: 'comment_on_approval',
      order: 8,
      name: __tc('comment'),
      modules: [Constants.APPROVAL_STAGE],
    },
    // call_log: {
    //   canCreate: false,
    //   icon: 'phone-alt',
    //   activeBgClass: 'bg-primary',
    //   textClass: 'text-primary',
    //   borderClass: 'border-primary',
    //   type: 'call_log',
    //   order: 9,
    //   name: `${__tc('call')} ${__tc('log')}`,
    //   modules: [Constants.REQUEST],
    // },
    other_user_to_requster: {
      canCreate: false,
      icon: 'users',
      borderClass: 'border-neutral',
      activeBgClass: 'bg-neutral',
      textClass: 'text-neutral',
      type: 'other_user_to_requster',
      order: 6,
      name: __tc('anonymous_reply'),
      modules: [Constants.REQUEST],
    },
    email_content: {
      canCreate: false,
      icon: 'envelope',
      borderClass: 'border-neutral',
      activeBgClass: 'bg-neutral',
      textClass: 'text-neutral',
      type: 'email_content',
      order: 6,
      name: __tc('email_content'),
      modules: [Constants.REQUEST],
    },
    review: {
      enableMention: false,
      editorToolbar: true,
      icon: 'review-kb',
      borderClass: 'border-neutral',
      activeBgClass: 'bg-neutral',
      textClass: 'text-neutral',
      type: 'review',
      order: 7,
      name: __tc('review'),
      modules: [Constants.KNOWLEDGE],
    },
  }
}
