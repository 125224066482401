import Color from 'color'

export function handleRenderEvent() {
  const anomalySeries = this.series.find((s) => s.options.isAnomalySeries)
  if (anomalySeries && anomalySeries.data.length > 0) {
    const areaSeries = this.series.find((s) => s.type === 'areasplinerange')

    const renderer = areaSeries.group.renderer
    const existingGroup = renderer.boxWrapper.element.getElementById(
      anomalySeries.options.guid
    )
    if (existingGroup) {
      existingGroup.remove()
    }
    const group = renderer.g().add()
    group.element.id = anomalySeries.options.guid

    const clipPath = renderer.createElement('mask')

    clipPath.element.id = `mask-${anomalySeries.options.guid}`
    renderer
      .createElement('rect')
      .attr('height', this.clipBox.height)
      .attr('width', this.clipBox.width)
      .attr('x', this.clipBox.x)
      .attr('y', this.clipBox.y)
      .attr('fill', 'white')
      .add(clipPath)
    renderer
      .createElement('path')
      .attr('fill', 'black')
      .attr('d', areaSeries.area.element.getAttribute('d'))
      .add(clipPath)

    clipPath.add(group)
    anomalySeries.group.element.setAttribute(
      'mask',
      `url(#mask-${anomalySeries.options.guid})`
    )
    anomalySeries.group.element.childNodes[0].setAttribute(
      'mask',
      `url(#mask-${anomalySeries.options.guid})`
    )
  }
}

export function handleLoadEvent() {
  this.series.forEach((series) => {
    if (
      this.options.chart.type === 'areaspline' ||
      series.options.type === 'areaspline'
    ) {
      series.update(
        {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [
                0,
                Color(series.color)
                  .alpha(1)
                  .toString(),
              ],
              [
                1,
                Color(series.color)
                  .alpha(0.2)
                  .toString(),
              ],
            ],
          },
        },
        true
      )
    }
  })
  const min = this.xAxis[0].getExtremes().min
  const max = this.xAxis[0].getExtremes().max
  this.userOptions.initialTimeRange = { min, max }
  renderLegendElements.call(this)
}

export function renderLegendElements() {
  if (this.legend) {
    this.legend.destroy()
  }
  // distance between 2 elements
  let itemDistance = this.legend.options.itemDistance
  // the biggest element
  let maxItemWidth = this.legend.maxItemWidth
  // make the width of the legend in the size of 2 largest elements + distance
  let nextLegendWidth = maxItemWidth * 2 + itemDistance
  // container width
  let boxWidth = this.plotBox.width
  // if the length of the 2 largest elements + the distance between them is less than the width of 			container, we make 1 row, else set legend width 2 max elements + distance between
  if (boxWidth < nextLegendWidth) {
    this.legend.options.width = maxItemWidth
  } else {
    this.legend.options.width = nextLegendWidth
  }

  this.render()
}
