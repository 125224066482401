<template>
  <div class="flex flex-col justify-center mt-2">
    <div>
      {{ description }}
    </div>
    <div class="mt-2">
      <MButton variant="success" @click="$emit('transfer')">
        <MIcon name="check" /> {{ btnText }}
      </MButton>
      <MButton variant="error" class="ml-2" @click="$emit('reject')">
        <MIcon name="check" /> {{ rejectBtnText }}
      </MButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatNotification',
  props: {
    transferRequest: { type: Object, required: true },
    description: { type: String, required: true },
    btnText: { type: String, required: true },
    rejectBtnText: { type: String, required: true },
  },
}
</script>
