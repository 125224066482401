<template>
  <div v-if="!loading && hasCaptcha">
    <div v-if="captchaConfig.captchaType === 'offline'">
      <div class="flex flex-1 items-center">
        <img
          alt="captcha"
          class="mb-2"
          :src="`data:image/png;base64,${offlineCaptcha.captchaImage}`"
        />
        <MTooltip>
          <template v-slot:trigger>
            <MIcon
              name="sync"
              class="ml-2 cursor-pointer text-neutral-light"
              @click="generateCaptcha"
            />
          </template>
          {{ $tc('refresh') }} {{ $tc('captcha') }}
        </MTooltip>
      </div>
      <FlotoFormItem
        ref="captachInput"
        :value="value.captcha"
        :placeholder="$tc('captcha')"
        :validation-label="$tc('captcha')"
        rules="required"
        @update="handleChange"
      ></FlotoFormItem>
    </div>
  </div>
</template>

<script>
import { UserSecurityConfigMethods } from '@state/modules/user-security-config'
import { isCaptchaEnabled } from './utils'
import { load } from 'recaptcha-v3'
import { gerateCaptchaApi } from './api'
export default {
  name: 'Captcha',
  model: {
    event: 'change',
  },
  props: {
    action: { type: String, required: true },
    value: {
      type: [Object],
      default() {
        return {}
      },
    },
    autoFocus: { type: Boolean, default: false },
  },
  data() {
    return {
      captchaConfig: {},
      offlineCaptcha: {},
      loading: true,
      recaptcha: undefined,
      hasCaptcha: false,
    }
  },
  mounted() {
    this.getCaptchaConfig().then((data) => {
      let hasCaptcha = isCaptchaEnabled(data, this.action)
      this.hasCaptcha = hasCaptcha
      if (hasCaptcha) {
        if (data.captchaType !== 'online') {
          this.generateCaptcha()
        } else {
          this.init(data)
        }
      } else {
        this.loading = false
      }
    })
  },
  beforeDestroy() {
    this.recaptcha && this.recaptcha.hideBadge()
  },
  methods: {
    ...UserSecurityConfigMethods,
    getCaptchaConfig() {
      return this.fetchUserSecurityConfigs().then((data) => {
        this.captchaConfig = data
        return data
      })
    },
    init(config) {
      if (config.captchaType === 'online' && config.site) {
        load(config.site, {
          autoHideBadge: false,
          explicitRenderParameters: {
            badge: 'bottomright',
          },
        }).then((recaptcha) => {
          this.loading = false
          this.recaptcha = recaptcha
          recaptcha.showBadge()
          recaptcha.execute(this.action).then((token) => {
            this.$emit('change', { token })
          })
        })
      }
    },
    generateCaptcha() {
      gerateCaptchaApi().then((data) => {
        this.loading = false
        this.offlineCaptcha = data
        this.$emit('change', {
          captcha: '',
          token: data.token,
        })
        this.$emit('is-offline-captcha', true)
        this.$nextTick(() => {
          this.captachFocus()
        })
      })
    },
    handleChange(change) {
      this.$emit('change', {
        captcha: change,
        token: this.offlineCaptcha.token,
      })
    },
    captachFocus() {
      if (this.autoFocus && this.$refs.captachInput) {
        this.$refs.captachInput.focus()
      }
    },
  },
}
</script>
