<template>
  <div class="connection-error">
    {{ $t('connection_error') }}
  </div>
</template>

<script>
export default {
  name: 'ConnectionError',
}
</script>

<style lang="less" scoped>
.connection-error {
  @apply px-2 py-2 flex items-center justify-center;

  color: white;
  background: @error-color;
}
</style>
