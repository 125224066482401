<template>
  <FlotoCrudContainer
    as-table
    :fetch-fn="getActiveAgents"
    :create-fn="addProductType"
    :columns="tableColumns"
    :scrollable="false"
  >
    <template v-slot:add-controls> <span /> </template>
    <template v-slot:form> <span /> </template>
    <template v-slot:name="{ item }">
      <td class="text-ellipsis">
        {{ item.name || '---' }}
      </td>
    </template>
    <template v-slot:status="{ item }">
      <td class="text-ellipsis">
        <AgentStatusPicker disabled :value="item.status" />
      </td>
    </template>
    <template v-slot:durationInStatus="{ item }">
      <td class="text-ellipsis">
        {{ item.durationInStatus || '---' }}
      </td>
    </template>
    <template v-slot:actions="{ item }">
      <td>
        <FlotoDeleteBtn
          :message="$t('confirm_transfercall')"
          @confirm="() => handleTransferCall(item)"
        >
          <MIcon
            name="phone-alt"
            class="ml-2"
            :class="!disabled ? 'cursor-pointer text-primary' : ''"
          />
        </FlotoDeleteBtn>
      </td>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import { getActiveAgentsApi } from '../api'
import AgentStatusPicker from '@modules/contactOps-manager/components/agent-status-picker'

export default {
  name: 'AvailableAgentList',
  components: { AgentStatusPicker },
  props: {
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      searchTerm: '',
      tableColumns: [
        {
          name: `${this.$tc('technician')} ${this.$tc('name')}`,
          key: 'name',
        },
        {
          name: `${this.$tc('technician')} ${this.$tc('status')}`,
          key: 'status',
        },
        {
          name: `${this.$tc('time_spent_in_current_status')}`,
          key: 'durationInStatus',
        },
        {
          name: `${this.$tc('action', 2)}`,
          key: 'actions',
        },
      ],
    }
  },
  methods: {
    getActiveAgents() {
      return getActiveAgentsApi()
    },
    addProductType() {},
    handleTransferCall() {
      if (this.disabled) {
        return
      }
      return true
    },
  },
}
</script>
