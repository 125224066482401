export const FontSizeMap = {
  small: '11px',
  medium: '13px',
  large: '15px',
}

export const WidgetTypeConstants = {
  CHART: 'chart',
  AREA: 'area',
  LINE: 'line',
  VERTICAL_BAR: 'column',
  HORIZONTAL_BAR: 'bar',
  PIE: 'pie',
  GRID: 'grid',
  METRIC: 'Metric',
  PARTITIONS: 'Partitions',
  TOPN: 'TopN',
  GAUGE: 'Gauge',
  STACKED_AREA: 'StackedArea',
  STACKED_LINE: 'StackedLine',
  STACKED_VERTICAL_BAR: 'StackedVerticalBar',
  STACKED_HORIZONTAL_BAR: 'StackedHorizontalBar',
  SOLID_GAUGE: 'SolidGauge',
  METRO_TILE: 'MetroTile',
  GAUGE_INFORMATIVE: 'Informative',
  HEATMAP: 'HeatMap',
  ORDINAL: 'Ordinal',
  HEALTH: 'Health',
  HORIZONTAL: 'Horizontal',
  SANKEY: 'Sankey',
  CUSTOM: 'Custom',
}
export const DateFormat = 'DD/MM/YYYY'
export const TimeFormat = 'HH:mm:ss'

export function getWidgetProperties(widgetCategory) {
  const commonProperties = {
    headerProperty: {
      fontSize: 'small',
    },
  }
  if (widgetCategory === WidgetTypeConstants.CHART) {
    return {
      ...commonProperties,
      labelProperty: {
        rotation: 0,
        legendEnabled: false,
        xAxisTitleEnabled: false,
        yAxisTitleEnabled: false,
      },
    }
  } else if (
    [
      WidgetTypeConstants.GRID,
      WidgetTypeConstants.TOPN,
      WidgetTypeConstants.CUSTOM,
    ].indexOf(widgetCategory) >= 0
  ) {
    return {
      ...commonProperties,
      headerProperty: {
        fontSize: 'small',
        tableHeaderFontSize: 'small',
      },
      columnProperty: {},
      colorProperty: [],
    }
  }
  return commonProperties
}

// export function transformWidget(widget) {
//   return {
//     id: widget[Constants.ID_PROPERTY],
//     name: widget['widget.name'],
//     description: widget['widget.description'],
//     timeRange: convertTimeLine(widget['timeline'] || {}),
//     category: widget['widget.category'],
//     widgetType: widget['widget.type'],
//     isPredefined: widget._type === '0',
//     widgetProperties: transformProperties(widget),
//   }
// }
