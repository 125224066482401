var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ai-bot-chat-box h-full",class:{
    'chat-preview': _vm.isChatPreview,
  },style:(("font-family: " + ((_vm.chatConfig || {}).fontFamily) + "; "))},[_c('div',{staticClass:"ai-bot-card-header px-3 pt-4 pb-6"},[_c('div',{staticClass:"flex items-center justify-center p-1 rounded",staticStyle:{"background-color":"var(--page-background-color)"}},[(
          _vm.chatConfig.chatFavicon !== undefined &&
          (_vm.chatConfig.chatFavicon || []).length
        )?_c('img',{staticClass:"self-center",attrs:{"src":_vm.chatConfig.chatFaviconSrc,"height":_vm.size,"width":_vm.size}}):_c('img',{staticClass:"self-center",attrs:{"src":require("@assets/images/logo/motadata.png"),"height":_vm.size,"width":_vm.size}})]),_c('div',{staticClass:"flex-1 pl-3 mx-1"},[_c('div',{staticClass:"text-xl font-semibold"},[_vm._v(_vm._s(_vm.branding.name))]),_c('div',{staticClass:"md-text-xs"},[_vm._v(_vm._s(_vm.branding.email))])]),(!_vm.isChatPreview)?_c('div',{staticClass:"flex"},[_c('MDropdown',{ref:"dropdownMenu",scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MButton',{staticClass:"ml-2",attrs:{"id":"action-btn","variant":"transparent","shadow":false,"shape":"circle"}},[_c('MIcon',{staticStyle:{"color":"var(--white-regular)"},attrs:{"name":"ellipsis-v","size":"2x"}})],1)]},proxy:true},{key:"menu",fn:function(){return [_c('MMenu',{staticClass:"action-menu"},[_c('MMenuItem',{on:{"click":_vm.handleStartNewConversation}},[_vm._v(" Start New Conversation ")])],1)]},proxy:true}],null,false,2281002498)})],1):_vm._e()]),_c('div',{staticClass:"ai-chat-area"},[_c('div',{staticClass:"message-container flex flex-1 flex-col min-h-0"},[_c('div',{staticClass:"flex flex-col min-h-0"},[_c('AIBotChatMessages',_vm._b({key:_vm.AIBotchatMessagesKey,ref:"chatBoxRef",attrs:{"owner":_vm.owner,"room":_vm.room,"chat-limit":_vm.chatLimit,"default-messages":_vm.defaultMessages,"chat-config":_vm.chatConfig},on:{"send-message":_vm.handleSendMessage,"bot-name":function($event){_vm.aiBotName = $event},"confirmation-message":function($event){_vm.confirmationMessage = $event}}},'AIBotChatMessages',
            _vm.room && _vm.room.isCollaboration
              ? {}
              : {
                  requester: _vm.room && _vm.room.user,
                }
          ,false)),(_vm.isAiTyping)?_c('div',{staticClass:"typing-animation-wrapper"},[_c('div',{staticClass:"typing-animation"},[_c('span',{staticClass:"dot"}),_c('span',{staticClass:"dot"}),_c('span',{staticClass:"dot"}),(_vm.aiBotName)?[_vm._v(" "+_vm._s(_vm.$t('is_typing', { user: _vm.aiBotName }))+" ")]:_vm._e()],2)]):_vm._e()],1)]),_c('AIBotCompose',{attrs:{"confirmation-message":_vm.confirmationMessage},on:{"send-message":_vm.handleSendMessage}}),_c('MessageConfirmModal',{attrs:{"open":_vm.showModal,"prevent-auto-close-on-confirm":""},on:{"hide":function($event){_vm.showModal = false},"confirm":_vm.sendMessage},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('MIcon',{staticClass:"w-full text-primary",attrs:{"name":"info-circle","size":"2x"}})]},proxy:true},{key:"message",fn:function(){return [_vm._v(" "+_vm._s(_vm.confirmationMessage)+" ")]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }