<template>
  <MRow :gutter="0" class="px-4 flex-1">
    <MCol class="flex flex-col" :size="12">
      <FlotoCrudContainer
        ref="crudContainer"
        :fetch-fn="getCheckLists"
        :per-page="10000"
        :scrollable="false"
        :update-fn="updateFn"
        :delete-fn="removeFn"
        :create-fn="creatFn"
        :as-table="false"
      >
        <template v-slot:form="{ submit }">
          <CheckListForm
            v-if="!disabled"
            :module-name="moduleName"
            @submit="submit"
          />
          <span v-else> </span>
        </template>
        <template
          v-slot:list-items="{
            items,
            update,
            remove,
          }"
        >
          <div
            v-for="item in items"
            :key="item.id"
            class="hierarchy-item flex items-center"
          >
            <div
              class="title-container flex flex-1 items-center min-w-0 mb-3 text-neutral with-bg"
              style="margin-right: 0"
            >
              <CheckListItem
                :disabled="disabled"
                :status="item"
                @update="update($event)"
                @remove="handleRemoveCheckList(item, remove)"
              />
            </div>
          </div>
        </template>
      </FlotoCrudContainer>
    </MCol>
  </MRow>
</template>
<script>
import CheckListForm from './check-list-form'
import {
  getCheckListApi,
  removeCheckListApi,
  updateCheckListApi,
  createCheckListApi,
} from './check-list-api'
import CheckListItem from './check-list-item'
export default {
  name: 'CheckListContainer',
  components: { CheckListForm, CheckListItem },
  props: {
    moduleName: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    resourceId: { type: Number, required: true },
  },
  methods: {
    handleRemoveCheckList(item, removeFn) {
      return removeFn(item)
    },
    getCheckLists() {
      return getCheckListApi(this.moduleName, this.resourceId).then((data) => ({
        items: data,
      }))
    },
    creatFn(data) {
      return createCheckListApi(this.moduleName, this.resourceId, data)
    },
    updateFn(data) {
      return updateCheckListApi(this.moduleName, this.resourceId, data).then(
        (data) => {
          if ('default' in data && data.default === true) {
            // @TODO remove refresh call
            return this.refresh()
          }
          return data
        }
      )
    },
    removeFn(status) {
      return removeCheckListApi(this.moduleName, this.resourceId, status)
    },
    refresh() {
      this.$refs.crudContainer.refresh()
    },
  },
}
</script>
