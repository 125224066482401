<template>
  <a @click="trigger">
    <slot name="trigger">
      <MIcon name="print" class="action-menu-icon" />
      <span class="ml-1 action-menu-text">
        {{ $tc('print') }} {{ $tc('barcode') }}
      </span>
    </slot>
  </a>
</template>
<script>
import { gotToPrint } from '@utils/barcode'
export default {
  name: 'BarcodePrintAction',
  props: {
    barcodes: {
      type: Array,
      default() {
        return []
      },
    },
    resourceId: {
      type: Number,
      required: true,
    },
    moduleName: {
      type: String,
      required: true,
    },
  },
  methods: {
    trigger() {
      setTimeout(() => {
        // this.isDrawerOpen = true
        this.$emit('trigger')
        this.gotToPrint()
      }, 400)
    },
    gotToPrint() {
      gotToPrint({ assetIds: [this.resourceId], moduleName: this.moduleName })
    },
  },
}
</script>
