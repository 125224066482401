<template>
  <MPopover
    ref="mPopoverRef"
    trigger="click"
    placement="bottomLeft"
    :overlay-style="{ minWidth: '450px' }"
  >
    <template v-slot:trigger>
      <a class="flex items-center mb-4">
        <MIcon name="plus-circle" size="lg" class="mr-1"></MIcon>
        {{ $t('add') }} {{ $tc('check_list') }}
      </a>
    </template>
    <FlotoForm ref="form" layout="vertical" @submit="onSubmit">
      <FlotoFormItem
        v-model="status.name"
        auto-focus
        rules="required|short_text"
        :label="$t('name')"
        :placeholder="$t('name')"
      />
      <template v-slot:submit>
        <MButton type="submit" variant="primary" class="mr-2">
          {{ $t('save') }}
        </MButton>
        <MButton variant="default" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoForm>
  </MPopover>
</template>

<script>
export default {
  name: 'CheckListForm',
  props: {
    moduleName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      status: {},
    }
  },
  methods: {
    hide() {
      this.$refs.mPopoverRef && this.$refs.mPopoverRef.hide()
      this.status = {}
    },
    onSubmit() {
      this.$emit('submit', this.status)
      this.hide()
    },
  },
}
</script>
