<template>
  <MDropdown ref="dropdownMenu">
    <template v-slot:trigger>
      <MButton
        variant="transparent"
        class="text-primary"
        :shadow="false"
        shape="circle"
      >
        <MIcon name="plus" size="lg" />
      </MButton>
    </template>
    <template v-slot:menu>
      <MMenu class="action-menu">
        <MMenuItem>
          <AddRequesterDrawer
            :user="{ contactNo: defaultChannel.requesterNumber }"
            @trigger="hide"
            @done="($event) => $emit('done', $event)"
          >
            <template v-slot:trigger>
              <span class="ml-1 action-menu-text">
                {{ `${$tc('add')} ${$tc('new')}` }}
              </span>
            </template>
          </AddRequesterDrawer>
        </MMenuItem>
        <MMenuItem>
          <UpdateRequesterDrawer
            @trigger="hide"
            @done="($event) => $emit('done', $event)"
          >
            <template v-slot:trigger>
              <span class="ml-1 action-menu-text">
                {{ $tc('update') }} {{ $tc('existing') }}
              </span>
            </template>
          </UpdateRequesterDrawer>
        </MMenuItem>
      </MMenu>
    </template>
  </MDropdown>
</template>

<script>
import AddRequesterDrawer from './add-requester-drawer'
import UpdateRequesterDrawer from './update-requester-drawer'
export default {
  name: 'AddUpdateRequester',
  components: { AddRequesterDrawer, UpdateRequesterDrawer },
  props: {
    defaultChannel: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    hide() {
      this.$refs.dropdownMenu.hide()
    },
  },
}
</script>
