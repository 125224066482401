<template>
  <MPopover
    ref="mPopoverRef"
    :key="rendorCount"
    trigger="click"
    :placement="placement"
    :overlay-style="{ width: '350px' }"
    :disabled="disabled"
    @hide="hide"
  >
    <template v-slot:trigger>
      <slot name="trigger">
        <div class="flex flex-col" :class="{ 'cursor-pointer': !disabled }">
          <span>
            <MIcon name="phone-volume" />
          </span>
          <span class="md-text-xs font-thin">
            {{ $tc('outgoing_call') }}
          </span>
        </div>
      </slot>
    </template>
    <DialPad @dial-number="handleCall" />
  </MPopover>
</template>

<script>
import DialPad from './dial-pad'
import { dialOutgoingCallApi } from '@modules/contactOps-manager/contactOps-manager-api'
export default {
  name: 'OutgoingCallBtn',
  components: { DialPad },
  inject: { contactOpsConfigContext: {} },
  props: {
    callFn: { type: Function, default: undefined },
    placement: { type: String, default: 'bottomRight' },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      rendorCount: 1,
    }
  },
  methods: {
    hide() {
      this.$refs.mPopoverRef && this.$refs.mPopoverRef.hide()
      this.rendorCount = this.rendorCount + 1
    },
    handleCall(number) {
      const requestedData = {
        requesterNumber: number,
        dialType: 'dialpad',
      }
      if (this.callFn) {
        return this.callFn(number).then(() => {
          this.hide()
        })
      }
      dialOutgoingCallApi(requestedData).then(() => {
        this.hide()
      })
    },
  },
}
</script>
