var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[(_vm.stage.totalStage > 1)?_c('MRow',{staticClass:"my-4 items-center justify-center px-6"},[_c('MCol',{attrs:{"auto-size":""}},[_c('b',[_vm._v(_vm._s(_vm.$tc('approval'))+" "+_vm._s(_vm.$tc('level')))])]),_c('MCol',{staticClass:"counter-steps",style:({ maxWidth: ((_vm.stage.totalStage * 100) + "px") })},[_c('MSteps',{attrs:{"active":_vm.stage.activeStage - 1,"status":_vm.currentStepStatus}},_vm._l((_vm.allStages),function(stageNumber){return _c('MStep',{key:stageNumber,scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('CounterStep',{attrs:{"tooltip-text":_vm.$t(
                  _vm.stage.activeStage === stageNumber &&
                    _vm.stage.stageStatus[stageNumber] === 'pending'
                    ? 'progress'
                    : _vm.stage.stageStatus[stageNumber]
                ),"active":_vm.currentActiveApprovalStage === stageNumber,"status":_vm.stage.activeStage === stageNumber &&
                _vm.stage.stageStatus[stageNumber] === 'pending'
                  ? 'progress'
                  : stageNumber > _vm.stage.activeStage &&
                    _vm.stage.stageStatus[_vm.stage.activeStage] === 'rejected'
                  ? 'pending'
                  : _vm.getStatusForCounter(_vm.stage.stageStatus[stageNumber])}},[_c('a',{staticClass:"block",on:{"click":function($event){_vm.currentActiveApprovalStage = stageNumber}}},[_vm._v(" "+_vm._s(stageNumber)+" ")])])]},proxy:true}],null,true)})}),1)],1)],1):_vm._e(),_c('ApprovalStage',{key:_vm.currentActiveApprovalStage,attrs:{"approval-id":_vm.stage.id,"disabled":_vm.currentActiveApprovalStage > _vm.stage.activeStage,"disabled-conversation":_vm.disabled,"hide-action":_vm.isPortalLogin ? true : _vm.disabled,"show-subject":_vm.stage.totalStage > 1,"stage":_vm.currentActiveApprovalStage,"module-name":_vm.moduleName},on:{"refresh":function($event){return _vm.$emit('refresh')},"refresh-page":function($event){return _vm.$emit('refresh-page')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }