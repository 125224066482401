<template>
  <div class="flex flex-1 items-center">
    <InlineEdit
      :value="status.name"
      :item-style="{ display: 'flex', flex: 1, alignItems: 'center' }"
      @change="$emit('update', { ...status, name: $event })"
    >
      <template v-slot:item-renderer="{ edit }">
        <MCheckbox
          :disabled="disabled"
          :checked="status.checked"
          class="mr-2"
          @change="$emit('update', { ...status, checked: $event })"
        />
        <div class="item-title flex-1">{{ status.name }}</div>
        <HoverableAction v-if="!disabled">
          <template v-slot="{ stickAction, unstickAction }">
            <MTooltip>
              <template v-slot:trigger>
                <MIcon
                  name="pencil"
                  size="lg"
                  class="mr-2 cursor-pointer text-neutral-light"
                  @click="edit"
                />
              </template>
              {{ $t('edit') }}
            </MTooltip>
            <FlotoDeleteBtn
              :message="
                $t('confirm_remove_item', {
                  item: `${$tc('check_list')}`,
                })
              "
              @trigger="stickAction"
              @cancel="unstickAction"
              @confirm="$emit('remove', status)"
            >
              <MIcon
                name="times-circle"
                class="text-secondary-red"
                size="lg"
              ></MIcon>
            </FlotoDeleteBtn>
          </template>
        </HoverableAction>
      </template>
    </InlineEdit>
  </div>
</template>

<script>
import HoverableAction from '@components/hoverable-action'
import InlineEdit from '@components/inline-edit/inline-edit'

export default {
  name: 'CheckListItem',
  components: { InlineEdit, HoverableAction },
  model: {
    event: 'change',
  },
  props: {
    status: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    hideDefaultAction: { type: Boolean, default: false },
  },
  methods: {},
}
</script>
