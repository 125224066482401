export function transformContactOpsChannel(channel) {
  return {
    callType: channel.callType,
    callStatus: channel.toStatus,
    userId: channel.userId,
    mute: channel.mute,
    hold: channel.hold,
    lastCallTime: channel.lastCallTime,
    type: channel.type,
    requesterNumber: channel.requesterNumber,
    requesterId: channel.requesterId,
    requesterName: channel.requesterName,
    requestId: channel.requestId,
    unknown: channel.unknown,
    waitingCallCount: channel.waitingCallCount,
    onHoldCount: channel.onHoldCount,
    callbackTime: channel.callbackTime,
    notes: channel.notes,
    callStartTime: channel.callStartTime,
    shortcutDataList: channel.objectList,
    secondCallDetails: channel.secondCallDetails,
    callCompleteStatus: channel.callStatus,
    currStatusStartTime: channel.currStatusStartTime,
  }
}

export function transformCallbackReminder(reminder) {
  return {
    requesterNumber: reminder.requesterNumber,
    requesterName: reminder.requesterName,
    callbackTime: reminder.callbackTime,
    notes: reminder.notes,
    status: reminder.status,
  }
}
