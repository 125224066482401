var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex h-100 flex-col flex-1"},[_c('ListWithPreviewLayout',{key:((_vm.defaultChannel.requesterId) + "-" + _vm.isOpen),ref:"listwithpreviewref",attrs:{"resource-name":_vm.$tc(_vm.moduleName, 2),"allow-keyboard-navigation":"","fetch-fn":_vm.getRequests,"update-fn":_vm.updateItem,"columns":_vm.tableColumns,"view":_vm.currentView,"module-name":_vm.moduleName,"selectable":false,"fetch-immediate":true,"hide-search-selector":true,"allow-split-view":false},on:{"viewChange":function($event){_vm.currentView = $event},"navigate-to-item":_vm.navigateToItem},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$tc(_vm.moduleName, 2))+" ")]},proxy:true},{key:"search-selector",fn:function(){return [_c('div')]},proxy:true},{key:"search",fn:function(){return [_c('div')]},proxy:true},{key:"actions",fn:function(ref){
var refreshList = ref.refreshList;
return [(!_vm.defaultChannel.unknown)?_c('MRow',{attrs:{"gutter":0}},[_c('MCol',{staticClass:"text-right",attrs:{"size":2}},[(!_vm.wrapUpCallData.requestId && !_vm.defaultChannel.requestId)?_c('CreateIncidentDrawer',{attrs:{"default-channel":_vm.defaultChannel},on:{"refresh":refreshList},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MButton',{staticClass:"mr-2",attrs:{"size":"small","outline":""}},[_vm._v(" "+_vm._s(_vm.$tc('create'))+" "+_vm._s(_vm.$tc('request'))+" ")])]},proxy:true}],null,true)}):_vm._e()],1)],1):_vm._e()]}},{key:"grid-actions-before",fn:function(ref){
var refreshList = ref.refreshList;
return [_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"mr-2 cursor-pointer text-neutral-light",attrs:{"name":"sync"},on:{"click":refreshList}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('refresh'))+" ")])]}},{key:"row",fn:function(rowSlotData){return [_c('TableRow',_vm._b({attrs:{"module-name":_vm.moduleName,"closed-status-id":_vm.closedStatusId}},'TableRow',rowSlotData,false))]}},{key:"detail",fn:function(ref){
var item = ref.item;
var replaceItem = ref.replaceItem;
return [_c('Transition',{attrs:{"name":"placeholder"}},[_c('DetailView',{attrs:{"use-transition":false,"module-name":_vm.moduleName,"resource-id":item.id,"on-change":replaceItem,"ongoing-call-details":_vm.defaultChannel},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
            var isTicketClosed = ref.isTicketClosed;
            var handleCloseTicket = ref.handleCloseTicket;
            var handleReopenTicket = ref.handleReopenTicket;
return [(isTicketClosed)?_c('MButton',{staticClass:"ml-2",on:{"click":function () { return handleReopenTicket(); }}},[_vm._v(" "+_vm._s(_vm.$t('reopen'))+" "+_vm._s(_vm.$tc(_vm.moduleName))+" ")]):_c('MButton',{staticClass:"ml-2",on:{"click":function () { return handleCloseTicket(); }}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" "+_vm._s(_vm.$tc(_vm.moduleName))+" ")])]}}],null,true)})],1)]}}])}),(_vm.showConfirmCloseTicketModalFor > 0)?_c('ConfirmCloseModal',{attrs:{"module-name":_vm.moduleName},on:{"confirm":_vm.handleConfirmClose,"hide":function($event){_vm.showConfirmCloseTicketModalFor = null}},scopedSlots:_vm._u([(_vm.showChildMessasge)?{key:"message",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('confirm_close_related', { resource: _vm.$tc(_vm.moduleName), }))+" ")]},proxy:true}:null],null,true)}):_vm._e(),_c('NoteRules',{ref:"noteDrawer",attrs:{"create-fn":_vm.handleNoteAdded}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }