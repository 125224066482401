<template>
  <MRow class="items-start">
    <MCol class="ml-2 py-2">
      <FlotoReadMore>
        <FlotoFormItemReadOnly
          v-if="details.requesterNumber"
          :label="`${$tc('requester')} ${$tc('number')}`"
        >
          {{ details.requesterNumber }}
        </FlotoFormItemReadOnly>
        <FlotoFormItemReadOnly
          v-if="details.telephonyAgentId"
          :label="`${$tc('telephony')} ${$tc('agent')}`"
        >
          <FlotoUserDrawer :user-id="details.telephonyAgentId" />
        </FlotoFormItemReadOnly>
        <FlotoFormItemReadOnly
          v-if="details.callType"
          :label="`${$tc('call')} ${$tc('type')}`"
        >
          {{ $tc(details.callType) }}
        </FlotoFormItemReadOnly>
        <FlotoFormItemReadOnly
          v-if="details.callTime"
          :label="`${$tc('call')} ${$tc('time')}`"
        >
          {{ details.callTime | datetime }} ({{ details.callTime | timeago }})
        </FlotoFormItemReadOnly>
      </FlotoReadMore>
    </MCol>
    <MCol auto-size :class="conversation.textClass" class="flex items-center">
      <MTooltip>
        <template v-slot:trigger>
          <MIcon :name="conversation.icon" size="lg" />
        </template>
        {{ conversation.name }}
      </MTooltip>
    </MCol>
  </MRow>
</template>

<script>
export default {
  name: 'CallLog',
  props: {
    callLog: { type: Object, required: true },
    conversation: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    details() {
      const d = JSON.parse(this.callLog.text) || {}
      return {
        ...d,
      }
    },
  },
}
</script>
