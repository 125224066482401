<template>
  <MRow :gutter="0">
    <MCol :lg="12" :sm="12" auto-size>
      <MRow>
        <MCol :size="12">
          <FlotoFormItemReadOnly v-if="disabled" :label="$t('street')">
            {{ formData.street || '---' }}
          </FlotoFormItemReadOnly>
          <FlotoFormItem
            v-else
            id="street-input"
            v-model="formData.street"
            :vid="`${type}-street`"
            :disabled="disabled"
            :placeholder="$t('street')"
            :label="$t('street')"
            :rules="{
              required: isRequired('street'),
              // eslint-disable-next-line
              short_text: true,
            }"
          />
        </MCol>
      </MRow>
      <MRow>
        <MCol :size="12">
          <FlotoFormItemReadOnly v-if="disabled" :label="$t('landmark')">
            {{ formData.landmark || '---' }}
          </FlotoFormItemReadOnly>
          <FlotoFormItem
            v-else
            id="landmark-input"
            v-model="formData.landmark"
            :vid="`${type}-landmark`"
            :disabled="disabled"
            :placeholder="$t('landmark')"
            :label="$t('landmark')"
            :rules="{
              required: isRequired('landmark'),
              // eslint-disable-next-line
              short_text: true,
            }"
          />
        </MCol>
      </MRow>
      <MRow>
        <MCol :size="6">
          <FlotoFormItemReadOnly v-if="disabled" :label="$t('city')">
            {{ formData.city || '---' }}
          </FlotoFormItemReadOnly>
          <FlotoFormItem
            v-else
            id="city-input"
            v-model="formData.city"
            :vid="`${type}-city`"
            :disabled="disabled"
            :placeholder="$t('city')"
            :label="$t('city')"
            :rules="{
              required: isRequired('city'),
              // eslint-disable-next-line
              short_text: true,
            }"
          />
        </MCol>
        <MCol :size="6">
          <FlotoFormItemReadOnly v-if="disabled" :label="$t('pincode')">
            {{ formData.pinCode || '---' }}
          </FlotoFormItemReadOnly>
          <FlotoFormItem
            v-else
            id="pin-code-input"
            v-model="formData.pinCode"
            :vid="`${type}-pinCode`"
            :disabled="disabled"
            :placeholder="$t('pincode')"
            :label="$t('pincode')"
            :rules="{
              required: isRequired('pincode'),
              numeric: true,
              max: 6,
            }"
          />
        </MCol>
      </MRow>
      <MRow>
        <MCol :size="6">
          <FlotoFormItemReadOnly v-if="disabled" :label="$t('state')">
            {{ formData.state || '---' }}
          </FlotoFormItemReadOnly>
          <FlotoFormItem
            v-else
            id="state-input"
            v-model="formData.state"
            :vid="`${type}-state`"
            :disabled="disabled"
            :placeholder="$t('state')"
            :label="$t('state')"
            :rules="{
              required: isRequired('state'),
              // eslint-disable-next-line
              short_text: true,
            }"
          />
        </MCol>
        <MCol :size="6">
          <FlotoFormItemReadOnly v-if="disabled" :label="$t('country')">
            {{ formData.country || '---' }}
          </FlotoFormItemReadOnly>
          <FlotoFormItem
            v-else
            id="country-input"
            v-model="formData.country"
            :vid="`${type}-country`"
            :disabled="disabled"
            :placeholder="$t('country')"
            :label="$t('country')"
            :rules="{
              required: isRequired('country'),
              // eslint-disable-next-line
              short_text: true,
            }"
          />
        </MCol>
      </MRow>
    </MCol>
  </MRow>
</template>

<script>
export default {
  name: 'AddressForm',
  model: {
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    mandatoryFields: {
      type: Array,
      default() {
        return []
      },
    },
    type: { type: String, default: 'address' },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    formData: {
      get() {
        return this.value
      },
      set(data) {
        this.$event('change', { ...this.value, ...data })
      },
    },
  },
  methods: {
    isRequired(field) {
      return this.mandatoryFields.indexOf(field) >= 0
    },
  },
}
</script>
