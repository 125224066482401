<template>
  <div class="flex items-center px-4 py-1">
    <h3 class="flex-auto mb-0 text-primary"><slot /></h3>
    <slot v-if="!hideBreadcrumb" name="breadcrumb">
      <div v-if="breadcrumb.length" class="breadcrumb">
        <AdminModuleDropdown>
          <template v-slot:trigger>
            <div
              v-for="(item, index) in breadcrumb"
              :key="item.title"
              class="breadcrumb-item"
            >
              <FlotoLink v-if="!item.active" :to="item.route">
                {{ item.title }}
              </FlotoLink>
              <template v-else>
                {{ item.title }}
              </template>
              <MIcon
                v-if="index !== breadcrumb.length - 1"
                :name="`chevron-${isRtl ? 'left' : 'right'}`"
                class="text-neutral-light ml-3"
                size="sm"
              />
            </div>
          </template>
        </AdminModuleDropdown>
      </div>
    </slot>
  </div>
</template>

<script>
import { authComputed } from '@state/modules/auth'
import AdminModuleDropdown from '@modules/admin/components/admin-module-dropdown'

export default {
  name: 'AdminHeader',
  components: { AdminModuleDropdown },
  props: {
    breadcrumb: { type: Array, required: true },
    hideBreadcrumb: { type: Boolean, default: false },
  },
  computed: {
    ...authComputed,
  },
}
</script>

<style lang="less" scoped>
.breadcrumb {
  @apply flex;

  .breadcrumb-item {
    @apply ml-3;
  }
}
</style>
