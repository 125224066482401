var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isSupportChannelActive && _vm.initiated)?_c('div',[(
      _vm.aiBotSupport &&
      (!_vm.channel.status ||
        ['ai_bot', 'ai_bot_completed'].indexOf(_vm.channel.status) >= 0)
    )?_c('AIBotSupportChannel',{attrs:{"ongoing-chat":Boolean(_vm.channel.status),"channel":_vm.channel,"history-messages":_vm.defaultMessages,"missed-chat":_vm.missedChat},on:{"start-new-conversation":_vm.handleStartNewConversation}}):_vm._e(),(
      (_vm.humanChatSupport && !_vm.aiBotSupport) ||
      (_vm.channel.status &&
        ['ai_bot', 'ai_bot_completed'].indexOf(_vm.channel.status) === -1)
    )?_c('HumanSupportChannel',{ref:"humanSupportChannelRef",attrs:{"guest-user-channel":_vm.channel,"socket-path":_vm.socketPath}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }