// import SortBy from 'lodash/sortBy'
import 'moment-timezone'
import { WidgetTypeConstants } from '@components/chart/helper'

/**
 *
 * Sample
 * [
 * {'ping.latency.min.last': 2.1, 'object.ip': '172.1.1.1', interval: 122221220}
 * {'ping.latency.min.last': 2.0, 'object.ip': '172.1.1.1', interval: 122221250}
 * {'ping.latency.min.last': 2.3, 'object.ip': '172.1.1.2', interval: 122221250}
 * ]
 *
 * here interval is millisecond
 */

// async function getTimezone() {
//   return 'Asia/Calcutta'
// }

export default function buildChartSeries(
  result,
  chartType,
  additionalOptions = {},
  isHistorical = false
) {
  if (isHistorical) {
    return buildCategoriesedChartSeries(result, chartType, additionalOptions)
  }
  return buildCategoriesedChartSeries(result, chartType, additionalOptions)
}

/**
 * build topN results for categories
 */
function buildCategoriesedChartSeries(result, chartType, additionalOptions) {
  if (chartType === WidgetTypeConstants.PIE) {
    const series = result.yAxis.datasets.map((item) => {
      return {
        ...item,
        name: item.title,
        widgetId: additionalOptions.widgetId,
        vm: additionalOptions.vm,
        data: item.values.map((item, index) => ({
          name: result.xAxis.keys[index],
          y: item,
        })),
      }
    })
    return { series, categories: result.xAxis.keys }
  }
  const series = result.yAxis.datasets.map((item) => {
    return {
      qualifications: item.qualifications,
      name: item.title,
      widgetId: additionalOptions.widgetId,
      vm: additionalOptions.vm,
      data: item.values.map((i, index) => ({
        y: i,
        // color: result.xAxis.keys[(i, index)] === 'Open' ? 'yellow' : 'red',
      })),
    }
  })
  return { series, categories: result.xAxis.keys }
}
