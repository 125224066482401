<template>
  <div>
    <div class="timer">{{ prettyTime | prettify }}</div>
  </div>
</template>

<script>
export default {
  name: 'CallTimer',
  filters: {
    prettify: function(value) {
      let data = value.split(':')
      let hours = data[0]
      let minutes = data[1]
      let secondes = data[2]
      if (hours < 10) {
        hours = '0' + hours
      }
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (secondes < 10) {
        secondes = '0' + secondes
      }
      return hours + ':' + minutes + ':' + secondes
    },
  },
  props: {
    startTime: { type: [Number, String], default: undefined },
    type: { type: String, default: 'increment' },
  },
  data() {
    return {
      isRunning: true,
      hours: 0,
      minutes: 0,
      secondes: 0,
      time: 0,
      timer: null,
    }
  },
  computed: {
    prettyTime() {
      const d = Number(this.time)
      const h = Math.floor(d / 3600)
      const m = Math.floor((d % 3600) / 60)
      const s = Math.floor((d % 3600) % 60)

      const hDisplay = h > 0 ? h : 0
      const mDisplay = m > 0 ? m : 0
      const sDisplay = s > 0 ? s : 0
      return hDisplay + ':' + mDisplay + ':' + sDisplay
    },
  },
  watch: {
    startTime: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          if (typeof newValue === 'string') {
            const timeArray = newValue.split(':')
            const h = Math.floor(timeArray[0] * 60 * 60)
            const m = Math.floor(timeArray[1] * 60)
            const s = Math.floor(timeArray[2])
            this.time = h + m + s
          }
          if (typeof newValue === 'number') {
            this.time = newValue / 1000
          }
        }
      },
    },
  },
  created() {
    this.start()
  },
  methods: {
    start() {
      this.isRunning = true
      this.timer = setInterval(() => {
        if (this.type === 'increment') {
          this.time++
        } else if (this.type === 'decrement') {
          if (this.time > 0) {
            this.time--
          } else {
            this.$emit('time-up')
            this.end()
          }
        }
      }, 1000)
    },
    end() {
      clearInterval(this.timer)
    },
  },
}
</script>
