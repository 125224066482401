var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-box",class:{ hidden: !_vm.isOpen },style:({
    right: ((_vm.right || 0) + "px"),
    fontFamily: ("" + (_vm.chatConfig.fontFamily)),
  })},[_c('div',{staticClass:"chat-header",on:{"click":_vm.toggle}},[_c('div',{staticClass:"chat-header-container"},[_c('div',{staticClass:"chat-name"},[_c('div',{staticClass:"chat-name-span flex items-center"},[(_vm.chatHeader.avatar)?_c('FlotoUserAvatar',{staticClass:"mr-2",staticStyle:{"min-width":"25px"},attrs:{"size":25,"avatar":_vm.chatHeader.avatar,"show-online-status":"","is-online":_vm.isOnline}}):_vm._e(),_c('div',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(_vm.chatHeader.name)+" ")])],1)]),_c('MBadge',{staticClass:"mr-1",attrs:{"count":_vm.unreadCount}},[_vm._v("     ")])],1),_c('div',{staticClass:"chat-actions",on:{"click":function($event){$event.preventDefault();return _vm.handleAction.apply(null, arguments)}}},[(_vm.canMinimize)?_c('div',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggle.apply(null, arguments)}}},[_c('MIcon',{attrs:{"name":("chevron-" + (_vm.isOpen ? 'down' : 'up'))}})],1):_vm._e(),_c('div',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_c('MIcon',{attrs:{"name":"times"}})],1)])]),(_vm.isOpen)?_vm._t("connection-error"):_vm._e(),_c('div',{staticClass:"chat-area",class:{ hidden: !_vm.isOpen }},[_c('div',{staticClass:"message-container flex flex-1 flex-col min-h-0"},[_c('div',{staticClass:"flex flex-col min-h-0"},[_c('ChatMessages',_vm._b({key:_vm.chatMessagesKey,ref:"chatBoxRef",attrs:{"owner":_vm.owner,"socket":_vm.socket,"event-prefix":_vm.eventPrefix,"room":_vm.room,"chat-limit":_vm.chatLimit,"default-messages":_vm.defaultMessages,"chat-config":_vm.chatConfig}},'ChatMessages',
            _vm.room && _vm.room.isCollaboration
              ? {}
              : {
                  requester: _vm.room && _vm.room.user,
                }
          ,false)),(_vm.isOnline)?_c('div',{staticClass:"typing-animation-wrapper"},[_c('div',{staticClass:"typing-animation",style:({ visibility: _vm.typerName ? 'visible' : 'hidden' })},[_c('span',{staticClass:"dot"}),_c('span',{staticClass:"dot"}),_c('span',{staticClass:"dot"}),(_vm.typerName)?[_vm._v(" "+_vm._s(_vm.$t('is_typing', { user: _vm.chatUserName(_vm.typerName, _vm.chatConfig) }))+" ")]:_vm._e()],2)]):_c('div',{staticClass:"user-status"},[_vm._v(" "+_vm._s(_vm.$t('is_offline', { user: _vm.chatUserName(_vm.chatHeader.name, _vm.chatConfig), }))+" ")]),_c('div',{staticClass:"user-status"},[(_vm.transferRemark)?_c('div',[_vm._v(" "+_vm._s(_vm.transferRemark)+" ")]):_vm._e(),_vm._t("remark")],2)],1)]),_c('Compose',{attrs:{"disabled":_vm.disabled,"has-file":Boolean(_vm.uploadedFile)},on:{"send-message":_vm.sendMessage,"start-typing":_vm.onStartTyping,"stop-typing":_vm.onStopTyping}}),(_vm.uploadedFile)?_c('div',{staticClass:"flex file-item items-center"},[_c('div',{staticClass:"flex-1 min-w-0 text-ellipsis"},[_c('MIcon',{staticClass:"mr-1",attrs:{"name":"file-alt"}}),_vm._v(" "+_vm._s(_vm.uploadedFile.realName)+" ")],1),_c('MIcon',{staticClass:"cursor-pointer",attrs:{"name":"times"},on:{"click":function($event){_vm.uploadedFile = null}}})],1):_vm._e(),(_vm.shouldShowActions)?_c('div',{staticClass:"flex justify-between px-2 py-1 pr-6 chat-actions"},[_c('div',{staticClass:"box"},[_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
          var showTooltip = ref.toggle;
return [_c('FlotoAttachment',{attrs:{"show-upload-list":false,"max-files":1,"use-public-api":""},on:{"change":_vm.handleFileUploaded}},[_c('MButton',{attrs:{"variant":"transparent","shadow":false,"shape":"circle"},on:{"mouseenter":showTooltip,"mouseleave":showTooltip}},[_c('MIcon',{attrs:{"name":"paperclip"}})],1)],1)]}}],null,false,2571486071)},[_vm._v(" "+_vm._s(_vm.$tc('attach_file', 2))+" ")])],1),(_vm.room && !_vm.room.isCollaboration && !_vm.isPortalLogin)?[_c('div',{staticClass:"box"},[_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('FlotoTechnicianPicker',{attrs:{"include-ids":_vm.includedTechnicians},on:{"change":_vm.handleTransferChat},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
          var openDropdown = ref.toggle;
return [_c('MButton',{attrs:{"variant":"transparent","shadow":false,"shape":"circle"},on:{"click":function($event){$event.preventDefault();return openDropdown.apply(null, arguments)}}},[_c('MIcon',{attrs:{"name":"user-plus"}})],1)]}}],null,false,3019510916)})]},proxy:true}],null,false,1236427802)},[_vm._v(" "+_vm._s(_vm.$t('transfer'))+" "+_vm._s(_vm.$tc('chat'))+" ")])],1),_c('div',{staticClass:"box"},[_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
          var showTooltip = ref.toggle;
return [_c('MButton',{attrs:{"variant":"transparent","shadow":false,"shape":"circle"},on:{"mouseenter":showTooltip,"mouseleave":showTooltip,"click":function($event){$event.preventDefault();return _vm.handleShowCreateRequestForm.apply(null, arguments)}}},[_c('MIcon',{attrs:{"name":"ticket-alt"}})],1)]}}],null,false,1123923229)},[_vm._v(" "+_vm._s(_vm.$t('create'))+" "+_vm._s(_vm.$tc('request'))+" ")])],1),_c('div',{staticClass:"box"},[_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
          var showTooltip = ref.toggle;
return [_c('MButton',{attrs:{"variant":"transparent","shadow":false,"shape":"circle"},on:{"click":function($event){$event.preventDefault();return _vm.handleAction({ key: 'close' })},"mouseenter":showTooltip,"mouseleave":showTooltip}},[_c('MIcon',{attrs:{"name":"times"}})],1)]}}],null,false,393381637)},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])],1)]:_vm._e()],2):_vm._e()],1),(_vm.room && !_vm.room.isCollaboration && !_vm.isPortalLogin)?_c('FlotoDrawerForm',{attrs:{"open":_vm.showCreateRequestDrawer,"width":"65%"},on:{"cancel":function($event){_vm.showCreateRequestDrawer = false},"submit":_vm.handleCreateRequest},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('create'))+" "+_vm._s(_vm.$tc('request'))+" ")]},proxy:true},{key:"actions",fn:function(ref){
          var hide = ref.hide;
          var submit = ref.submit;
return [_c('MButton',{staticClass:"mr-2",attrs:{"outline":"","loading":_vm.processing},on:{"click":submit}},[_vm._v(" "+_vm._s(_vm.$t('create'))+" "+_vm._s(_vm.$tc('request'))+" ")]),_c('MButton',{attrs:{"variant":"default"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])]}}],null,false,2577230404)},[_c('TicketForm',{attrs:{"value":_vm.requestFormData,"module-name":_vm.$constants.REQUEST,"processing":_vm.processing,"with-submit":false},on:{"templateSelected":_vm.handleTemplateSelected,"change":_vm.handleChangeCreatingResource}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }