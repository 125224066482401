<template>
  <div class="container">
    <div id="output">
      <MInput
        id="output-input"
        style="font-size: 2rem"
        auto-focus
        :value="output"
        @update="handleChange"
      />
    </div>
    <div v-for="(number, index) in list" :key="index" class="chunk-row">
      <div
        v-for="item in number"
        :key="item.value"
        class="digit"
        @click="() => handleDigitClick(item.value)"
      >
        {{ item.text }}
        <div v-if="item.sub" class="sub">{{ item.sub }}</div>
      </div>
    </div>
    <div class="chunk-row">
      <div class="digit" @click="() => handleDigitClick('+')">
        <span>{{ '+' }}</span>
      </div>
      <div id="call" class="digit" @click="handleCall">
        <MIcon name="phone-alt"></MIcon>
      </div>
      <div class="digit" @click="handleBackspace">
        <MIcon name="backspace" />
      </div>
    </div>
  </div>
</template>

<script>
import Chunk from 'lodash/chunk'
export default {
  name: 'DialPad',
  data() {
    this.numbers = [
      { text: '1', value: '1' },
      { text: '2', value: '2', sub: 'ABC' },
      { text: '3', value: '3', sub: 'DEF' },
      { text: '4', value: '4', sub: 'GHT' },
      { text: '5', value: '5', sub: 'JKL' },
      { text: '6', value: '6', sub: 'MNO' },
      { text: '7', value: '7', sub: 'PQRS' },
      { text: '8', value: '8', sub: 'TUV' },
      { text: '9', value: '9', sub: 'WXYZ' },
      { text: '*', value: '*' },
      { text: '0', value: '0' },
      { text: '#', value: '#' },
    ]
    return {
      list: Chunk(this.numbers, 3),
      output: '',
    }
  },
  methods: {
    handleDigitClick(digit) {
      let input = this.$el.querySelector(`input#output-input`)
      const currentSelection = input.selectionStart
      this.output = `${input.value.substr(
        0,
        currentSelection
      )}${digit}${input.value.substr(currentSelection)}`
      this.$nextTick(() => {
        input.focus()
        input.selectionStart = currentSelection + 1
        input.selectionEnd = currentSelection + 1
        input = null
      })
    },
    handleBackspace() {
      let input = this.$el.querySelector(`input#output-input`)
      const currentSelection = input.selectionStart
      this.output = `${input.value.substr(
        0,
        currentSelection - 1
      )}${input.value.substr(currentSelection)}`
      this.$nextTick(() => {
        input.focus()
        input.selectionStart = currentSelection
        input.selectionEnd = currentSelection - 1
        input = null
      })
    },
    handleCall() {
      this.$emit('dial-number', this.output)
    },
    handleChange(change) {
      if (/^[0-9*#+]+$/.test(change) || !change) {
        this.output = change
      }
    },
  },
}
</script>

<style lang="less" scoped>
.chunk-row {
  @apply flex justify-between text-center;

  width: 280px;
  margin: 0 auto;
  clear: both;
}

.digit,
.dig {
  @apply px-1 py-2 cursor-pointer;

  float: left;
  width: 85px;
  font-size: 2rem;
  user-select: none;
}

.sub {
  font-size: var(--text-sm);
  color: @neutral-regular;
}

.container {
  @apply text-center bg-white;

  width: 320px;
  height: auto;
  min-height: 420px;
  margin: 0 auto;
  // box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

#output {
  @apply flex justify-center h-auto text-center;

  font-size: 2rem;
  word-break: break-word;

  input {
    @apply text-primary;

    text-align: center;
    border: none;

    :focus {
      border: none;
    }

    ::selection {
      color: #099dd9 !important;
      background: #fff !important;
    }

    &.focus-visible {
      border: none !important;
      outline: none;
    }
  }
}

#call {
  @apply m-2 text-white cursor-pointer rounded text-center justify-center items-center;

  font-size: 22px;
  background-color: #66bb6a;
}

.digit:active,
.dig:active {
  background-color: @neutral-lightest;
}

#call:hover {
  background-color: #81c784;
}
</style>
