<template>
  <MRow :gutter="0" class="">
    <MCol :size="12">
      <h6> {{ $tc(assetUpdateData.entityModel) }}</h6>
    </MCol>
    <MCol :size="12">
      <FlotoCrudContainer
        ref="list"
        as-table
        :columns="columns"
        :scrollable="false"
        :fetch-fn="getApprovalAssetUpdateData"
        :per-page="1000"
        class="h-100 flex flex-1"
      >
        <template v-slot:form>
          <span />
        </template>
        <template v-slot:currentValue="{ item }">
          <td class="text-ellipsis">
            <MTooltip v-if="item.currentValue !== '---'">
              <template v-slot:trigger>
                {{ item.currentValue }}
              </template>
              {{ item.currentValue }}
            </MTooltip>
            <span v-else>---</span>
          </td>
        </template>
      </FlotoCrudContainer>
    </MCol>
  </MRow>
  <!-- <MRow :gutter="0" class="flex flex-1 min-w-0">
    <MCol :size="12">
      <MRow :gutter="0" class="flex-1">
        <MCol v-for="field in fields" :key="field.key" :size="6">
          <MRow :gutter="0">
            <MCol class="mx-2 min-w-0">
              <FlotoFormItemReadOnly :label="field.name">
                <div class="text-ellipsis">
                  {{ assetUpdateData.updateDataJson[field.key] || '---' }}
                </div>
              </FlotoFormItemReadOnly>
            </MCol>
          </MRow>
        </MCol>
      </MRow>
    </MCol>
  </MRow> -->
</template>

<script>
import { generateId } from '@utils/id'
import { getMakercheckerUpdateDataApi } from '../approval-api'

export default {
  name: 'ApprovalAssetUpdateData',
  props: {
    stageId: { type: Number, required: true },
  },

  data() {
    this.columns = [
      {
        name: `${this.$tc('field')}`,
        key: 'key',
      },
      {
        name: `${this.$tc('old_value')}`,
        key: 'oldValue',
      },
      {
        name: `${this.$tc('new_value')}`,
        key: 'newValue',
      },
      {
        name: `${this.$tc('comment', 2)}`,
        key: 'currentValue',
      },
    ]
    return {
      assetUpdateData: {},
    }
  },
  methods: {
    getApprovalAssetUpdateData() {
      return getMakercheckerUpdateDataApi(this.stageId).then((data) => {
        this.assetUpdateData = {
          ...data,
        }
        return {
          items: (data.makerCheckerUpdateData || []).map((i) => ({
            ...i,
            id: generateId(),
          })),
          total: (data.makerCheckerUpdateData || []).length,
        }
      })
    },
  },
}
</script>
