<template>
  <div class="flex">
    <slot name="bot-avatar"></slot>
    <div
      class="content"
      :class="{ 'my-message': isMyMessage }"
      v-html="message.message"
    >
    </div>
    <slot name="sender-avatar"></slot>
  </div>
</template>

<script>
import Bus from '@utils/emitter'
import MessageMixin from '../../mixins/message-mixin'
export default {
  name: 'UtilityTransferChatMessage',
  mixins: [MessageMixin],
  mounted() {
    Bus.$emit('app:transfer-chat')
  },
}
</script>
