var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoDrawer',{attrs:{"open":_vm.showDrawer},on:{"hide":_vm.hideDrawer},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]},proxy:true},{key:"trigger",fn:function(slotData){return [_vm._t("trigger",function(){return [_c('a',{staticClass:"text-neutral mx-2",attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.showDrawer = true}}},[_c('MIcon',{staticClass:"mx-1",attrs:{"name":"lightbulb-on"}}),_vm._v(" "+_vm._s(_vm.$t('insert'))+" "+_vm._s(_vm.$t('knowledge'))+" ")],1)]},{"showDrawer":function () { return (_vm.showDrawer = true); }},slotData)]}},{key:"actions",fn:function(ref){
var hide = ref.hide;
return [(!_vm.disabled)?_c('div',[_c('MButton',{staticClass:"mr-2",attrs:{"disabled":_vm.selectedItems.length === 0,"outline":"","loading":_vm.processing},on:{"click":_vm.handleAddKb}},[_vm._v(" "+_vm._s(_vm.$tc('insert'))+" "+_vm._s(_vm.$tc('knowledge'))+" ")]),_c('MButton',{staticClass:"mr-2",attrs:{"disabled":_vm.selectedItems.length === 0,"outline":"","loading":_vm.processing},on:{"click":_vm.handleAddLink}},[_vm._v(" "+_vm._s(_vm.$tc('insert'))+" "+_vm._s(_vm.$tc('link'))+" ")])],1):_vm._e(),_c('MButton',{attrs:{"variant":"default"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.disabled ? _vm.$tc('done') : _vm.$t('cancel'))+" ")])]}}],null,true)},[(!_vm.disabled && !_vm.hideSuggestedKnowledge)?_c('SuggestedKnowledge',{attrs:{"resource":_vm.resource},on:{"insert":_vm.handleInsertKnowledge}}):_vm._e(),_c('KBSelectionList',{key:_vm.knowledgeType,ref:"kbListRef",attrs:{"selectable":!_vm.disabled,"searchable":"","max-allowed-selection":1,"fetch-fn":!_vm.hideSuggestedKnowledge && _vm.knowledgeType === 'suggestedKb'
        ? _vm.fetchKnowledgeFn
        : undefined,"allow-keywords":!_vm.hideSuggestedKnowledge && _vm.knowledgeType === 'suggestedKb'
        ? false
        : true},on:{"selection-change":_vm.setSelectedItems},scopedSlots:_vm._u([{key:"after-title",fn:function(){return [(!_vm.hideSuggestedKnowledge)?_c('FlotoDropdownPicker',{attrs:{"as-input":"","options":_vm.options,"allow-clear":false},on:{"change":_vm.handleTypeChange},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
        var currentItem = ref.currentItem;
        var toggle = ref.toggle;
return [_c('div',{staticClass:"inline-block mx-2 text-neutral cursor-pointer md-text-base",on:{"click":toggle}},[_vm._v(" "+_vm._s((currentItem || {}).text)+" "),_c('MIcon',{staticClass:"mx-1",attrs:{"name":"chevron-down"}})],1)]}}],null,false,2446074742),model:{value:(_vm.knowledgeType),callback:function ($$v) {_vm.knowledgeType=$$v},expression:"knowledgeType"}}):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }