<template>
  <MPopover
    ref="mPopoverRef"
    trigger="click"
    :placement="placement"
    :disabled="disabled"
    @hide="hide"
  >
    <template v-slot:trigger="{ show }">
      <slot name="trigger" :show="show">
        <FlotoDot :bg="color.hex" class="cursor-pointer" :size="18" />
      </slot>
    </template>
    <MRow :gutter="0">
      <MCol class="color-picker-container">
        <MRow>
          <MCol>
            <h6 class="text-primary"><slot name="heading"/></h6>
          </MCol>
        </MRow>
        <Sketch v-model="color"></Sketch>
        <MRow class="mt-4 justify-end" :gutter="0">
          <MButton variant="primary" rounded class="mx-2" @click="onSubmit">
            {{ $t('done') }}
          </MButton>
          <MButton variant="default" rounded @click="hide">
            {{ $t('cancel') }}
          </MButton>
        </MRow>
      </MCol>
    </MRow>
  </MPopover>
</template>

<script>
import { Sketch } from 'vue-color'
export default {
  name: 'FlotoColorPicker',
  components: { Sketch },
  model: {
    event: 'change',
  },
  props: {
    value: { type: String, required: true },
    placement: {
      type: String,
      default() {
        return 'bottomLeft'
      },
    },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      color: { hex: this.value },
    }
  },
  watch: {
    value(newValue) {
      this.color = { hex: newValue }
    },
  },
  methods: {
    hide() {
      this.$refs.mPopoverRef && this.$refs.mPopoverRef.hide()
      this.color = { hex: this.value }
    },
    onSubmit() {
      this.$emit('change', this.color.hex)
      this.hide()
    },
  },
}
</script>

<style lang="less" scope>
.color-picker-container {
  .vc-sketch {
    padding: 0;
    background-color: var(--form-overlay-bg);
    box-shadow: none;
    .vc-sketch-field {
      // stylelint-disable-next-line
      & .vc-input__input {
        color: var(--page-text-color);
        background: var(--page-background-color);
        border: 1px solid var(--border-color);
        box-shadow: none;
      }
    }
  }
}
</style>
