<template>
  <a @click="trigger">
    <slot name="trigger" />
    <FlotoDrawerForm
      :open="isDrawerOpen"
      @submit="handleFormSubmit"
      @cancel="cancelAction"
    >
      <template v-slot:header>
        {{ $tc('add') }} {{ $tc('callback_reminder') }}
      </template>
      <ReminderForm :form-data="formData" from-call />
      <template v-slot:actions="{ hide, submit }">
        <MButton
          variant="primary"
          :loading="processing"
          class="ml-2"
          @click="submit"
        >
          {{ $tc('add') }}
        </MButton>
        <MButton variant="default" class="ml-2" @click="hide">
          {{ $tc('cancel') }}
        </MButton>
      </template>
    </FlotoDrawerForm>
  </a>
</template>

<script>
import { createCallbackReminderApi } from './api'
import ReminderForm from '@modules/contactOps-manager/components/call-reminder/reminder-form'

export default {
  name: 'AddCallbackReminderDrawer',
  components: { ReminderForm },
  props: {
    defaultChannel: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isDrawerOpen: false,
      processing: false,
      formData: {
        requesterName: this.defaultChannel.requesterName,
        requesterNumber: this.defaultChannel.requesterNumber,
      },
    }
  },
  methods: {
    trigger() {
      setTimeout(() => {
        this.isDrawerOpen = true
        this.$emit('trigger')
      })
    },
    cancelAction() {
      this.isDrawerOpen = false
      this.formData = {
        requesterName: this.defaultChannel.requesterName,
        requesterNumber: this.defaultChannel.requesterNumber,
      }
    },
    handleFormSubmit() {
      this.processing = true
      createCallbackReminderApi(this.formData)
        .then(() => {
          this.isDrawerOpen = false
          this.formData = {
            requesterName: this.defaultChannel.requesterName,
            requesterNumber: this.defaultChannel.requesterNumber,
          }
        })
        .finally((this.processing = false))
    },
  },
}
</script>
