<template>
  <MRow :gutter="0">
    <MCol class="flex">
      <FlotoRequesterPicker
        v-if="applyAddedByFilter"
        :value="value.createdById"
        keep-unassigned
        :placeholder="`${$tc('search')} ${$tc('user')}`"
        @change="handleChange({ createdById: $event || undefined })"
      >
        <template v-slot:trigger="triggerSlotData">
          <FlotoUserAvatar
            v-bide="triggerSlotData"
            :size="30"
            :avatar="(triggerSlotData.currentItem || {}).avatar"
            :user-id="(triggerSlotData.currentItem || {}).id"
          />
        </template>
      </FlotoRequesterPicker>
      <MInput
        id="search-box"
        v-model="searchTerm"
        class="search-box mx-1"
        :placeholder="$t('search')"
        @update="handleChange({ keyword: searchTerm })"
      >
        <template v-slot:prefix>
          <MIcon name="search" />
        </template>
        <template v-if="searchTerm" v-slot:suffix>
          <MIcon
            name="times-circle"
            class="text-neutral-light cursor-pointer"
            @click="resetSearch"
          />
        </template>
      </MInput>
      <FlotoDropdownPicker
        v-if="filterOptions.length > 2"
        id="conversation-types"
        class="mx-2 flex items-center"
        :options="filterOptions"
        :value="value.conversationType"
        @change="handleChange({ conversationType: $event || undefined })"
      >
        <template v-slot:trigger="{ toggle }">
          <MButton variant="neutral-lighter" :shadow="false" @click="toggle">
            <MIcon
              v-if="filterType"
              :name="filterType.icon"
              :class="filterType.textClass"
            />
            {{
              filterType ? filterType.filterName || filterType.name : $t('all')
            }}
            <MIcon v-if="!filterType" name="filter" size="lg" />
          </MButton>
        </template>
        <template v-slot:menu-item="{ item, selectItem }">
          <a :id="item.key" href="javascript:;" @click.stop="selectItem(item)">
            <div class="flex items-between">
              <MIcon
                :class="item.textClass"
                class="w-5"
                :name="item.icon"
                size="lg"
              />
              <span class="px-2">
                {{ item.text }}
              </span>
            </div>
          </a>
        </template>
      </FlotoDropdownPicker>
      <MButton
        variant="transparent"
        :shadow="false"
        class="p-0"
        @click="applySort('createdTime')"
      >
        <MIcon
          :name="`sort-amount-${sortBy === 'createdTime' ? 'down' : 'up'}`"
          size="lg"
        />
      </MButton>
    </MCol>
  </MRow>
</template>

<script>
export default {
  name: 'ConversationSearchBox',
  model: {
    event: 'change',
  },
  props: {
    availableTypes: { type: Array, required: true },
    value: { type: Object, required: true },
    sortBy: { type: String, required: true },
    applySort: { type: Function, required: true },
    applyAddedByFilter: { type: Boolean, default: false },
  },
  data() {
    return {
      searchTerm: '',
    }
  },
  computed: {
    filterType() {
      if (this.value.conversationType) {
        return this.availableTypes.find(
          (t) => t.type === this.value.conversationType
        )
      }
      return undefined
    },
    filterOptions() {
      return [
        {
          text: this.$t('all'),
          id: 0,
          key: 0,
          icon: 'filter',
          textClass: 'text-neutral',
        },
      ].concat(
        this.availableTypes.map((t) => ({
          text: t.filterName || t.name,
          id: t.type,
          key: t.type,
          icon: t.icon,
          textClass: t.textClass,
        }))
      )
    },
  },
  methods: {
    handleChange(change) {
      this.$emit('change', {
        ...this.value,
        ...change,
      })
    },
    resetSearch() {
      this.searchTerm = ''
      this.handleChange({ keyword: undefined })
    },
  },
}
</script>
