<template>
  <MPopover
    ref="mPopoverRef"
    :key="rendorCount"
    trigger="click"
    placement="bottomRight"
    :overlay-style="{ width: '300px' }"
    @hide="hide"
  >
    <template v-slot:trigger>
      <MButton
        variant="transparent"
        class="text-neutral"
        :shadow="false"
        shape="circle"
      >
        <MTooltip>
          <template v-slot:trigger>
            <a>
              <MIcon name="phone-volume" size="lg" class="mr-4 text-primary" />
            </a>
          </template>
          {{ $tc('outgoing_call') }}
        </MTooltip>
      </MButton>
    </template>
    <h6>{{ $tc('outgoing_call') }} </h6>
    <FlotoForm @submit="handleFormSubmit">
      <FlotoFormItem
        :label="`${$tc('requester')} ${$tc('number')}`"
        :placeholder="`${$tc('requester')} ${$tc('number')}`"
        rules="required"
      >
        <MRadioGroup
          v-model="formData.requesterNumber"
          class="flex flex-col"
          size="small"
          :options="requesterNumberOptions"
        />
      </FlotoFormItem>
      <template v-slot:submit>
        <div class="text-right">
          <MButton type="submit" :loading="processing">
            <MIcon name="phone-alt" /> {{ $tc('call') }}
          </MButton>
        </div>
      </template>
    </FlotoForm>
  </MPopover>
</template>

<script>
import UniqBy from 'lodash/uniqBy'
import { getGlobalUserApi } from '@modules/users/users-api'
import { TechnicianComputed } from '@state/modules/technician'
import { dialOutgoingCallApi } from '@modules/contactOps-manager/contactOps-manager-api'
export default {
  name: 'CallBtn',
  props: {
    resourceId: { type: Number, required: true },
    resource: { type: Object, required: true },
  },
  data() {
    return {
      processing: false,
      rendorCount: 1,
      formData: {
        requesterNumber: null,
      },
      fullUser: {},
      loading: false,
    }
  },
  computed: {
    ...TechnicianComputed,
    requesterNumberOptions() {
      let list = UniqBy([
        this.resource.callFrom,
        this.fullUser.contactNo,
        this.fullUser.altContactNo1,
        this.fullUser.altContactNo2,
      ])
      return list
        .filter((e) => e)
        .map((i) => ({
          text: i,
          value: i,
        }))
    },
  },
  watch: {
    'resource.callFrom': {
      immediate: true,
      handler(newValue) {
        this.formData = {
          ...this.formData,
          requesterNumber: newValue,
        }
      },
    },
  },
  created() {
    if (this.resource.requesterId) {
      const user = this.technician(this.resource.requesterId)
      if (user && user.id) {
        this.fullUser = user
        this.formData.requesterNumber = user.contactNo
        this.loading = false
      } else {
        getGlobalUserApi(this.resource.requesterId, { archived: true }).then(
          (user) => {
            this.fullUser = user
            this.formData.requesterNumber = user.contactNo
            this.loading = false
          }
        )
      }
    }
  },
  methods: {
    handleFormSubmit() {
      const requestedData = {
        requesterId: this.resource.requesterId,
        requestId: this.resourceId,
        requesterNumber: this.formData.requesterNumber,
        dialType: 'dialpad',
      }
      this.processing = true
      dialOutgoingCallApi(requestedData)
        .then(() => {
          this.hide()
          this.processing = false
        })
        .catch(() => {
          this.processing = false
        })
    },
    hide() {
      this.$refs.mPopoverRef && this.$refs.mPopoverRef.hide()
      this.formData = {
        requesterNumber: this.resource.callFrom || this.fullUser.contactNo,
      }
      this.processing = false
      this.rendorCount = this.rendorCount + 1
    },
  },
}
</script>
