<script>
import { getTelephonyApi } from '@modules/contactops/contactops-api'
import Bus from '@utils/emitter'
import { PreferenceMethods } from '@state/modules/preference'
import { authComputed } from '@state/modules/auth'
import * as STATUSES from './helpers/channel-statuses'
import { getCoOpsChannelStatusApi } from '@modules/contactOps-manager/contactOps-manager-api'

export default {
  name: 'ContactOpsProvider',
  provide() {
    const contactOpsConfigContext = {}
    Object.defineProperty(contactOpsConfigContext, 'config', {
      enumerable: true,
      get: () => {
        return this.config
      },
    })
    Object.defineProperty(contactOpsConfigContext, 'canCall', {
      enumerable: true,
      get: () => {
        return this.canCall
      },
    })
    return { contactOpsConfigContext }
  },
  props: {
    portalMode: { type: Boolean, default: false },
  },
  data() {
    return {
      config: {},
      canCall: false,
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {
    loggedIn(newValue) {
      if (newValue) {
        this.init()
      }
    },
  },
  created() {
    if (this.loggedIn) {
      this.init()
    }
    const handelContactOpsChannelStausUpdate = (payload) => {
      if ((payload || {}).status) {
        this.canCall =
          [STATUSES.AVAILABLE, STATUSES.NOT_AVAILABLE].indexOf(
            payload.status
          ) >= 0
      }
    }
    Bus.$on(
      'contactOps_channel_status_update',
      handelContactOpsChannelStausUpdate
    )
    this.$once('hook:beforeDestroy', () => {
      Bus.$off(
        'contactOps_channel_status_update',
        handelContactOpsChannelStausUpdate
      )
    })
  },
  methods: {
    ...PreferenceMethods,
    init() {
      // @TODO enable in next release
      // eslint-next-line-disable
      const enable = false
      if (!enable) {
        return
      }
      if (this.portalMode) {
        return
      }
      this.getContactOpsConfig()
      const handler = () => {
        this.getContactOpsConfig()
        this.fetchPreferences()
      }
      Bus.$on('contactOps_config_updated', handler)
      this.$once('hook:beforeDestroy', () => {
        Bus.$off('contactOps_config_updated', handler)
      })
    },
    getContactOpsConfig() {
      getTelephonyApi().then((data) => {
        this.config = data
        if (
          data.enabled === true &&
          data.userIds.length &&
          data.userIds.indexOf(this.user.id) >= 0
        ) {
          this.getStatus()
        }
        this.$emit('contactOps-config', this.config)
      })
    },
    getStatus() {
      getCoOpsChannelStatusApi().then((data) => {
        this.canCall =
          [STATUSES.AVAILABLE, STATUSES.NOT_AVAILABLE].indexOf(data.status) >= 0
      })
    },
  },
  render() {
    return this.$scopedSlots.default({})
  },
}
</script>
