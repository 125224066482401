<template>
  <div>
    <MDropdown ref="dropdownMenu">
      <template v-slot:trigger>
        <MButton
          variant="transparent"
          class="text-neutral"
          :shadow="false"
          shape="circle"
        >
          <MIcon
            class="text-white hover:text-white mr-2"
            name="ellipsis-v"
            size="lg"
          />
        </MButton>
      </template>
      <template v-slot:menu>
        <MMenu class="action-menu">
          <MMenuItem>
            <AddCallbackReminderDrawer
              :default-channel="defaultChannel"
              @trigger="hide"
            >
              <template v-slot:trigger>
                <MIcon name="phone-alt" class="action-menu-icon" />
                <span class="ml-1 action-menu-text">
                  {{ `${$tc('add')} ${$tc('callback')} ${$tc('reminder')}` }}
                </span>
              </template>
            </AddCallbackReminderDrawer>
          </MMenuItem>
          <MMenuItem @click="blockNumber">
            <MIcon name="ban" class="action-menu-icon" />
            <span class="ml-1 action-menu-text">
              {{ $tc('block_number') }}
            </span>
          </MMenuItem>
        </MMenu>
      </template>
    </MDropdown>
    <ReasonTaker
      ref="reasonDrawer"
      type="block"
      :create-fn="handleReasonAdded"
    />
  </div>
</template>

<script>
import ReasonTaker from '@modules/contactOps-manager/components/reason-taker/reason-taker'
import AddCallbackReminderDrawer from './add-callback-reminder-drawer'
import { blockNumberApi } from './api'
export default {
  name: 'ActionMenu',
  components: { AddCallbackReminderDrawer, ReasonTaker },
  props: {
    defaultChannel: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    hide() {
      this.$refs.dropdownMenu.hide()
    },
    handleReasonAdded(data) {
      return blockNumberApi({
        blockReason: data.blockReason,
        requesterNumber: this.defaultChannel.requesterNumber,
      }).then(() => {
        this.$refs.reasonDrawer.closeForm()
      })
    },
    blockNumber() {
      this.hide()
      return this.$refs.reasonDrawer.openForm()
    },
  },
}
</script>
