<template>
  <div
    v-if="(suggestedValues || {}).suggestedKbId"
    class="suggested-item-container"
  >
    <div class="flex flex-1">
      <MTooltip placement="topLeft">
        <template v-slot:trigger>
          <div class="text-ellipsis">
            <div class="font-bold">
              {{ $tc('suggested') }} {{ $tc('knowledge') }}
            </div>
            {{ knowledge.name }}: {{ knowledge.subject }}
          </div>
        </template>
        {{ knowledge.name }}: {{ knowledge.subject }}
      </MTooltip>
    </div>
    <div class="flex items-center">
      <MTooltip placement="topLeft">
        <template v-slot:trigger>
          <MIcon
            name="check"
            size="2x"
            class="cursor-pointer"
            @click="handleSuggestedItemSelect"
          />
        </template>
        {{ $t('insert') }} {{ $t('knowledge') }}
      </MTooltip>
    </div>
  </div>
</template>

<script>
import { getKnowledgeApi } from '@modules/knowledge/knowledge-api'
export default {
  name: 'SuggestedKnowledge',
  inject: ['suggestedFieldValuesContext'],
  props: {
    resource: { type: Object, required: true },
  },
  data() {
    return {
      knowledge: {},
    }
  },
  computed: {
    suggestedValues() {
      // return { suggestedKbId: 1 }
      let value
      const fieldValue =
        (
          (this.suggestedFieldValuesContext || {}).suggestedFieldValues || []
        ).find((i) => i.refId === this.resource.id) || {}
      if ((fieldValue || {}).suggestedKbId) {
        value = fieldValue
      }
      return value
    },
  },
  watch: {
    'suggestedValues.suggestedKbId': {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          getKnowledgeApi(newValue).then((data) => {
            this.knowledge = data
          })
        }
      },
    },
  },
  methods: {
    handleSuggestedItemSelect() {
      this.$emit('insert', this.knowledge)
    },
  },
}
</script>

<style lang="less" scoped>
.suggested-item-container {
  @apply rounded mx-4 mb-3 p-2 flex;

  color: var(--secondary-green);
  background: var(--success-outline-button-hover-bg);
}
</style>
