<template>
  <div
    :style="{
      left: `${right || 70}px`,
      bottom: `${bottom || 20}px`,
      width: isOpen ? '85%' : '300px',
    }"
    class="ongoing-call-box shadow-lg bg-white"
    :class="{
      hidden: !isOpen,
      maximized: isOpen,
      minimized: !isOpen,
    }"
  >
    <div
      class="ongoing-call-header"
      :class="{
        'box-containerborder border-solid border-r-0 border-t-8 border-l-0 border-b-0 border-secondary-redDark':
          [STATUSES.CALL_RNA, STATUSES.CALL_UNANSWER].indexOf(
            channelData.callCompleteStatus
          ) >= 0,
      }"
    >
      <div class="ongoing-call-header-container flex flex-col">
        <div class="ongoing-call-name flex flex-col">
          <div class="ongoing-call-name-span flex items-center">
            <FlotoUserDrawer
              :key="channelData.requesterId"
              :disabled="channelData.unknown || !channelData.requesterId"
              :size="isOpen ? 25 : 40"
              :user-id="channelData.requesterId"
              class="text-white hover:text-white"
            >
              <template v-slot>
                <div class="flex items-center">
                  <FlotoUserAvatar
                    :size="isOpen ? 30 : 40"
                    :avatar="(user || {}).avatar"
                    :is-calling="
                      !isOpen && channelData.callStatus === STATUSES.BUSY
                    "
                    :on-call="
                      !isOpen && channelData.callStatus === STATUSES.ON_CALL
                    "
                    :class="{ 'm-6': !isOpen }"
                  />
                  <div
                    class="text-ellipsis md-text-lg flex ml-2"
                    :class="{ 'flex-col': !isOpen }"
                    style="float: right"
                  >
                    <div v-if="!isOpen" class="md-text-sm">
                      {{
                        channelData.secondCallDetails &&
                        channelData.secondCallDetails.conferenceCall
                          ? `${$tc('conference')} ${$tc('call')}...`
                          : channelData.callStatus === STATUSES.ON_CALL
                          ? 'On Call...'
                          : ''
                      }}
                      {{
                        channelData.callCompleteStatus ===
                        STATUSES.CALL_UNANSWER
                          ? 'Missed Call...'
                          : channelData.callCompleteStatus === STATUSES.CALL_RNA
                          ? 'Call Not Answered...'
                          : ''
                      }}
                      {{
                        channelData.callStatus === STATUSES.BUSY
                          ? `${$tc(channelData.callType)} ${$tc('call')}...`
                          : ''
                      }}
                    </div>
                    <div>
                      <div class="font-semibold mr-2">
                        {{ (user || {}).name || 'Unknown' }}
                      </div>
                      <div class="md-text-sm flex items-center">
                        <MTooltip class="mr-2">
                          <template v-slot:trigger>
                            <div style="position: relative">
                              <MIcon name="phone-alt" class="text-white" />
                              <MIcon
                                size="lg"
                                class="text-white"
                                :name="
                                  (channelData.callType || '').toLowerCase() ===
                                  'incoming'
                                    ? 'long-arrow-left'
                                    : 'long-arrow-right'
                                "
                                style="
                                  position: absolute;
                                  top: 2px;
                                  right: -6px;
                                  font-size: 10px;
                                "
                              />
                            </div>
                          </template>
                          {{ $tc((channelData.callType || '').toLowerCase()) }}
                          {{ $tc('call') }}
                        </MTooltip>
                        {{ channelData.requesterNumber }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </FlotoUserDrawer>
            <div
              v-if="
                isOpen &&
                channelData.secondCallDetails &&
                channelData.secondCallDetails.conferenceCall
              "
              class="box border border-solid border-r-0 border-t-0 border-b-0 border-white ml-3 cursor-default"
            >
              <div class="ml-2">
                {{ $tc('conference') }} {{ $tc('call') }}
              </div>
              <div class="ml-2 md-text-sm">
                {{ channelData.secondCallDetails.number }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ongoing-call-actions">
        <div
          v-if="
            canMinimize &&
            [STATUSES.ON_CALL, STATUSES.WRAP_UP].indexOf(
              channelData.callStatus
            ) >= 0 &&
            [STATUSES.CALL_RNA, STATUSES.CALL_UNANSWER].indexOf(
              channelData.callCompleteStatus
            ) < 0
          "
          @click.stop.prevent="toggle"
        >
          <MTooltip>
            <template v-slot:trigger>
              <MIcon
                size="lg"
                :class="isOpen ? 'mb-2' : ''"
                :name="`window-${isOpen ? 'minimize' : 'maximize'}`"
              />
            </template>
            {{ isOpen ? 'Minimize' : 'Maximize' }}
          </MTooltip>
        </div>
        <!-- <div
          v-if="channelData.callStatus === STATUSES.WRAP_UP"
          @click="closeCall"
        >
          <MTooltip>
            <template v-slot:trigger>
              <MIcon :class="isOpen ? 'ml-2' : ''" size="lg" name="times" />
            </template>
            {{ $tc('close') }} {{ $tc('call') }}
          </MTooltip>
        </div> -->
        <MTooltip v-if="isOpen">
          <template v-slot:trigger>
            <ActionMenu :default-channel="channelData" />
          </template>
        </MTooltip>
      </div>
    </div>
    <div class="ongoing-call-area" :class="{ hidden: !isOpen }">
      <FlotoFixedView>
        <OngoingCallArea
          :is-open="isOpen"
          :default-channel="channelData"
          :user="user"
        />
      </FlotoFixedView>
    </div>
    <div
      v-if="shouldShowActions"
      class="flex justify-center bg-white"
      :class="{
        'py-2': isOpen,
        'border-top':
          startTime || channelData.callStatus === STATUSES.WRAP_UP
            ? true
            : false,
      }"
    >
      <div
        v-if="startTime || channelData.callStatus === STATUSES.WRAP_UP"
        class="flex py-1 px-3 rounded bg-primary"
        :style="{
          width:
            channelData.callStatus === STATUSES.WRAP_UP && isOpen
              ? '250px'
              : isOpen &&
                channelData.secondCallDetails &&
                channelData.secondCallDetails.conferenceCall
              ? '250px'
              : '300px',
        }"
        :class="{
          'shadow-lg': isOpen,
          'justify-between':
            [STATUSES.CALL_RNA, STATUSES.CALL_UNANSWER].indexOf(
              channelData.callCompleteStatus
            ) === -1,
          'justify-center':
            [STATUSES.CALL_RNA, STATUSES.CALL_UNANSWER].indexOf(
              channelData.callCompleteStatus
            ) >= 0,
        }"
      >
        <div
          v-if="showTimer"
          class="box flex border border-solid border-l-0 border-t-0 border-b-0 border-white"
        >
          <MTooltip>
            <template v-slot:trigger>
              <div
                class="md-text-lg flex items-center text-white justify-center pr-3"
              >
                <CallTimer
                  ref="callTimerRef"
                  :start-time="startTime"
                  :type="
                    channelData.callStatus === STATUSES.WRAP_UP
                      ? 'time-up'
                      : 'increment'
                  "
                />
              </div>
            </template>
            {{ $tc('call') }} {{ $tc('time') }}
          </MTooltip>
        </div>
        <!-- @TODO: If condition to be changed -->
        <div
          v-if="
            channelData.secondCallDetails
              ? !channelData.secondCallDetails.conferenceCall
              : channelData.callStatus === STATUSES.ON_CALL
          "
          class="box"
        >
          <TransferCallDrawer>
            <template v-slot:trigger>
              <MButton variant="transparent" :shadow="false" shape="circle">
                <MTooltip placement="top">
                  <template v-slot:trigger>
                    <div>
                      <MIcon name="phone-alt" size="lg" class="text-white" />
                      <MIcon
                        size="lg"
                        class="text-white"
                        name="long-arrow-right"
                        style="
                          position: absolute;
                          top: 2px;
                          right: 0;
                          font-size: 10px;
                        "
                      />
                    </div>
                  </template>
                  {{ `${$tc('transfer')}/${$tc('conference')} ${$tc('call')}` }}
                </MTooltip>
              </MButton>
            </template>
          </TransferCallDrawer>
        </div>
        <div
          v-if="
            channelData.secondCallDetails
              ? !channelData.secondCallDetails.conferenceCall
              : channelData.callStatus === STATUSES.ON_CALL
          "
          class="box"
          :class="{ 'active-box': isHold }"
        >
          <MTooltip>
            <template v-slot:trigger="{ toggle: showTooltip }">
              <MButton
                variant="transparent"
                :shadow="false"
                shape="circle"
                @click.prevent="
                  handleAction(
                    isHold ? { key: STATUSES.UNHOLD } : { key: STATUSES.HOLD }
                  )
                "
                @mouseenter="showTooltip"
                @mouseleave="showTooltip"
              >
                <MIcon
                  name="pause"
                  size="lg"
                  class="text-white"
                  :class="{ 'text-primary': isHold }"
                />
              </MButton>
            </template>
            {{ isHold ? $tc('unhold') : $tc('hold') }}
          </MTooltip>
        </div>
        <div
          v-if="channelData.callStatus === STATUSES.ON_CALL"
          class="box"
          :class="{ 'active-box': isMute }"
        >
          <MTooltip>
            <template v-slot:trigger="{ toggle: showTooltip }">
              <MButton
                variant="transparent"
                :shadow="false"
                shape="circle"
                @click.prevent="
                  handleAction(
                    isMute ? { key: STATUSES.UNMUTE } : { key: STATUSES.MUTE }
                  )
                "
                @mouseenter="showTooltip"
                @mouseleave="showTooltip"
              >
                <MIcon
                  v-if="isMute"
                  size="lg"
                  name="microphone-slash"
                  class="text-white"
                  :class="{ 'text-primary': isMute }"
                />
                <MIcon v-else size="lg" name="microphone" class="text-white" />
              </MButton>
            </template>
            {{ isMute ? $tc('unmute') : $tc('mute') }}
          </MTooltip>
        </div>
        <div v-if="channelData.callStatus === STATUSES.ON_CALL" class="box">
          <MTooltip>
            <template v-slot:trigger="{ toggle: showTooltip }">
              <MButton
                variant="transparent"
                :shadow="false"
                shape="circle"
                @click.prevent="handleAction({ key: STATUSES.END })"
                @mouseenter="showTooltip"
                @mouseleave="showTooltip"
              >
                <MIcon
                  class="text-white"
                  size="lg"
                  name="phone-alt"
                  style="transform: rotate(133deg)"
                />
              </MButton>
            </template>
            {{ $tc('end') }} {{ $tc('call') }}
          </MTooltip>
        </div>
        <div v-if="channelData.callStatus === STATUSES.WRAP_UP" class="box">
          <MButton variant="transparent" :shadow="false" @click="closeCall">
            <span class="text-white md-text-lg">
              <MIcon
                class="text-white mr-2"
                size="lg"
                name="phone-alt"
                style="transform: rotate(133deg)"
              />
              {{ $tc('close') }} {{ $tc('call') }}</span
            >
          </MButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment'
import Bus from '@utils/emitter'
import { getGlobalUserApi } from '@modules/users/users-api'
import { transformContactOpsChannel } from './data'
import { updateCallActionApi } from './api'
import * as STATUSES from './helpers/channel-statuses'
import ActionMenu from './action-menu'
import OngoingCallArea from './ongoing-call/ongoing-call-area'
import CallTimer from './components/call-timer'
import TransferCallDrawer from './transfer-call/transfer-call-drawer'

export default {
  name: 'OngoingCallBox',
  components: {
    ActionMenu,
    TransferCallDrawer,
    OngoingCallArea,
    CallTimer,
  },
  props: {
    defaultChannel: {
      type: Object,
      default() {
        return {}
      },
    },
    // eslint-disable-next-line
    canMinimize: { type: Boolean, default: true },
    // eslint-disable-next-line
    shouldShowActions: { type: Boolean, default: true },
    right: { type: [Number], default: 0 },
    bottom: { type: [Number], default: 0 },
  },
  data() {
    this.STATUSES = STATUSES
    return {
      isOpen: false,
      talkTime: '',
      channelData: {
        callStatus: STATUSES.BUSY,
        ...this.defaultChannel,
      },
      user: {},
      wrapUpStarted: false,
    }
  },
  computed: {
    showTimer() {
      if (
        (this.channelData.callStatus === STATUSES.ON_CALL ||
          this.channelData.callStatus === STATUSES.WRAP_UP) &&
        this.startTime
      ) {
        if (
          this.channelData.callStatus === STATUSES.WRAP_UP &&
          [STATUSES.CALL_RNA, STATUSES.CALL_UNANSWER].indexOf(
            this.channelData.callCompleteStatus
          ) >= 0
        ) {
          return false
        }
        return true
      }
      return false
    },
    isMute() {
      return this.channelData.mute || false
    },
    isHold() {
      return this.channelData.hold || false
    },
    startTime() {
      if (this.channelData.callStatus === STATUSES.WRAP_UP) {
        return (
          this.channelData.currStatusStartTime - this.channelData.callStartTime
        )
      }
      if (this.channelData.callStartTime) {
        return Moment().valueOf() - this.channelData.callStartTime
      }
      return 0
    },
  },
  watch: {
    'channelData.requesterId': {
      immediate: true,
      handler(newValue, preValue) {
        if (newValue && newValue !== preValue) {
          getGlobalUserApi(newValue, { archived: true }).then((user) => {
            this.user = user
          })
        }
      },
    },
    wrapUpStarted: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.$refs.callTimerRef.end()
          this.isOpen = false
          this.closeCall()
        }
      },
    },
  },
  created() {
    const handleContactOpsChannelUpdated = (payload) => {
      const channel = transformContactOpsChannel(payload)
      // when incoming call ringing open dialer
      if (channel.type === 'call' && channel.callStatus === STATUSES.ON_CALL) {
        if (!channel.mute && !channel.hold) {
          this.isOpen = true
        }
        this.channelData = channel
      }
      if (channel.type === 'call' && channel.callStatus === STATUSES.WRAP_UP) {
        this.channelData = channel
        this.wrapUpStarted = true
      }
      // when incoming call close or wrap up call dialer
      if (
        channel.type === 'call' &&
        channel.callStatus === STATUSES.AVAILABLE
      ) {
        this.isOpen = false
        this.channelData = channel
      }
    }
    Bus.$on('contactops_channel_updated', handleContactOpsChannelUpdated)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('contactops_channel_updated', handleContactOpsChannelUpdated)
    })
    const handleAppContactOpsChannelUpdated = (change) => {
      this.channelData = {
        ...this.channelData,
        ...change,
      }
    }
    Bus.$on('app:contactops_channel_updated', handleAppContactOpsChannelUpdated)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off(
        'app:contactops_channel_updated',
        handleAppContactOpsChannelUpdated
      )
    })
    const handleUserUpdate = (user) => {
      this.user = {
        ...this.user,
        ...user,
      }
    }
    Bus.$on('app:user_update', handleUserUpdate)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('app:user_update', handleUserUpdate)
    })
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
    closeCall() {
      this.$emit('close-call')
    },
    handleAction(action) {
      let data = {}
      if (action.key === 'transfer_call') {
        return
      }
      if (action.key === STATUSES.HOLD) {
        data = { holdCall: true }
      }
      if (action.key === STATUSES.UNHOLD) {
        data = { unHoldCall: true }
      }
      if (action.key === STATUSES.MUTE) {
        data = { muteCall: true }
      }
      if (action.key === STATUSES.UNMUTE) {
        data = { unMuteCall: true }
      }
      if (action.key === STATUSES.END) {
        data = { endCall: true }
      }
      updateCallActionApi(action.key, data)
    },
  },
}
</script>

<style lang="less" scoped>
.border-top {
  @apply border border-solid border-neutral-lighter border-b-0 border-l-0 border-r-0;
}

.active-box {
  @apply bg-white text-primary rounded;
}
</style>
