<template>
  <div class="flex">
    <slot name="bot-avatar"></slot>
    <div class="content" :class="{ 'my-message': isMyMessage }">
      <div v-html="message.message"> </div>
      <FlotoDatePicker
        v-model="formData.chatBrainValue"
        :show-time="showTime"
        @change="handleChange"
      />
    </div>
    <slot name="sender-avatar"></slot>
  </div>
</template>

<script>
import MessageMixin from '../../mixins/message-mixin'
export default {
  name: 'UserDateMessage',
  mixins: [MessageMixin],
  data() {
    return {
      formData: {},
    }
  },
  computed: {
    showTime() {
      return this.message.inputType === 'date_time'
    },
  },
  methods: {
    handleChange(value) {
      const message = this.$options.filters.datetime(
        value,
        undefined,
        this.showTime
      )
      this.$emit('send-message', {
        message,
        chatBrainValue: value,
        chatBrainId: this.message.chatBrainId,
      })
    },
  },
}
</script>
