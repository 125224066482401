import api from '@api'
import {
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
  transformBinaryQualForServer,
} from '@data/qualification'
import { transformUser } from '@data/user'
import { transformMessageForServer, transformMessage } from '@data/ai-bot'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@/src/utils/get-module-translator'
import { isLoggedIn } from '@utils/auth'

const __rootTc = getRootPluaralTranslator()
const __rootT = getRootTranslator()

export const transformRoom = (room) => ({
  id: room.id,
  owner: room.userId,
  status: room.status,
  isCollaboration: room.collaboration,
  user: transformUser(room.user),
  technicians: room.technicianId,
  members: [...(room.technicianId || []), room.userId],
  contextList: room.contextList || [],
  createdAt: room.createdTime,
  updatedAt: room.updatedTime,
})

export const transformUnAnswerBotResponse = (unanswerbot) => ({
  id: unanswerbot.id,
  message: unanswerbot.message,
  chatInfoIds: unanswerbot.chatInfoIds,
})
export function getRoomBetweenTechnicianApi(owner, userId) {
  return api
    .get(`/chat/info/collaboration/between/${owner}/and/${userId}`)
    .then((data) => transformRoom(data))
}

export function getChatRoomByIdApi(roomId) {
  return api.get(`/chat/info/${roomId}`).then((data) => transformRoom(data))
}

export function getChatHistoryApi(roomId, offset, size) {
  return api
    .post(
      `${isLoggedIn() ? '/' : 'public/'}chat/${roomId}/details/search/byqual`,
      {},
      {
        notify: false,
        params: {
          offset,
          size,
        },
      }
    )
    .then((data) => {
      return {
        messages: (data.objectList || []).map(transformMessage),
        total: data.totalCount,
      }
    })
}

export function createMessageApi(roomId, message, fileAttachments) {
  return api
    .post(
      `${isLoggedIn() ? '/' : 'public/'}chat/${roomId}/details`,
      {
        fileAttachments,
        message,
      },
      {
        notify: false,
      }
    )
    .then(transformMessage)
  // .then((data) => {
  //   return {
  //     id: data.id,
  //     message: data.message,
  //     fileAttachments: data.fileAttachments,
  //     owner: data.createdById,
  //     date: data.createdTime,
  //   }
  // })
}

export function getRequesterChatChannelApi(requesterId) {
  return api
    .get(`/cportal/chat/info/byRequester`, { notify: false })
    .then((data) => transformRoom(data))
}

export function getGuestUserChatChannelApi(guestUserId) {
  return api
    .get(`/public/chat/info/${guestUserId} `, { notify: false })
    .then((data) => transformRoom(data))
}

export function getRequesterChatSessionApi() {
  return api
    .get(`cportal/chat/info/getMySession`, {
      notify: false,
    })
    .then((data) => transformRoom(data))
}

export function createRequesterChatSessionApi() {
  return api
    .get(`${isLoggedIn() ? 'cportal' : 'public'}/chat/info/createMySession`, {
      notify: false,
    })
    .then((data) => {
      return {
        channel: transformRoom(data.chatInfoRest),
        history: data.responseList,
        technicianTalking: data.technicianTalking,
      }
    })
}
export function clearOldAndCreateNewChatSessionApi() {
  return api
    .get(
      `${isLoggedIn() ? 'cportal' : 'public'}/chat/info/createMyNewSession`,
      { notify: false }
    )
    .then((data) => {
      return {
        channel: transformRoom(data.chatInfoRest),
        history: data.responseList,
        technicianTalking: data.technicianTalking,
      }
    })
}

export function guestUserChatWithTechnicianCreateNewChatSessionApi(channelId) {
  return api
    .get(`/public/chat/info/getSession/${channelId}`, { notify: false })
    .then((data) => transformRoom(data))
}
export function sendMessageApi(data) {
  return api
    .post(
      `${!isLoggedIn() ? 'public' : ''}/chatbot/user${
        !isLoggedIn() ? '/guest' : ''
      }/response`,
      transformMessageForServer(data)
    )
    .then((data) => {
      return (data.objectList || []).map(transformMessage)
    })
}
export function chatPreviewSendMessageApi(
  data,
  chatflowId,
  additionParams = {}
) {
  return api
    .post(
      `/chatbot/${chatflowId}/preview/user/response`,
      transformMessageForServer(data),
      {
        params: {
          ...additionParams,
        },
      }
    )
    .then((data) => {
      return (data.objectList || []).map(transformMessage)
    })
}

export function getAIBotChatHistoryApi(roomId, offset, size) {
  return api
    .post(
      `${
        !isLoggedIn() ? 'public' : ''
      }/chatbot/${roomId}/details/search/byqual`,
      {},
      {
        notify: false,
        params: {
          offset,
          size,
          sort_by: 'createdTime',
        },
      }
    )
    .then((data) => {
      return {
        messages: (data.objectList || []).map(transformMessage),
        total: data.totalCount,
      }
    })
}

export function createRequesterChatChannelApi(requesterId) {
  return api
    .post(
      `/chat/info`,
      { userId: requesterId, status: 'pending' },
      { notify: false }
    )
    .then((data) => transformRoom(data))
}

export function getChatChannelsApi(filter, limit, offset) {
  let quals = []
  if ('ids' in filter) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        filter.ids,
        'long',
        'db'
      )
    )
  }
  if ('userId' in filter) {
    quals.push(
      buildRelationalQualificationStructure(
        'userId',
        'in',
        filter.userId,
        'long',
        'db'
      )
    )
  }
  if ('userIdNotIn' in filter) {
    quals.push(
      buildRelationalQualificationStructure(
        'userId',
        'not_in',
        filter.userIdNotIn,
        'long',
        'db'
      )
    )
  }
  if ('status' in filter) {
    quals.push(
      buildRelationalQualificationStructure(
        'status',
        'in',
        filter.status,
        'enum',
        'db'
      )
    )
    quals.push(
      buildRelationalQualificationStructure(
        'collaboration',
        'equal',
        false,
        'boolean',
        'db'
      )
    )
  }
  if ('startDate' in filter && 'endDate' in filter) {
    quals.push(
      buildRelationalQualificationStructure(
        'createdTime',
        'between',
        [filter.startDate, filter.endDate],
        'long',
        'db'
      )
    )
  }
  return api
    .post(
      `/chat/info/search/byqual`,
      {
        qualDetails: buildFlatQualificationStructure([...quals]),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformRoom),
        total: data.totalCount,
      }
    })
}

export function getChatCollaborationsApi(limit, offset) {
  return api
    .post(
      `/chat/info/search/byqual`,
      {
        qualDetails: buildFlatQualificationStructure([
          buildRelationalQualificationStructure(
            'collaboration',
            'equal',
            true,
            'boolean',
            'db'
          ),
          transformBinaryQualForServer(
            buildRelationalQualificationStructure(
              'technicianId',
              'any_member_or_all_members_exist',
              'current_user',
              'variable',
              'db'
            ),
            buildRelationalQualificationStructure(
              'createdById',
              'equal',
              'current_user',
              'variable',
              'db'
            ),
            'or'
          ),
        ]),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformRoom),
        total: data.totalCount,
      }
    })
}

export function getUnAnswerBotResponseApi(limit, offset) {
  return api
    .post(
      `/unanswerbotresponse/search/byqual`,
      {},
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformUnAnswerBotResponse),
        total: data.totalCount,
      }
    })
}
export function getOngoingChatApi(technicianId) {
  return api
    .post(`/chat/info/search/byqual`, {
      qualDetails: buildFlatQualificationStructure([
        buildRelationalQualificationStructure(
          'status',
          'equal',
          'on_going',
          'enum',
          'db'
        ),
        buildRelationalQualificationStructure(
          'technicianId',
          'any_member_or_all_members_exist',
          [technicianId],
          'long',
          'db'
        ),
        buildRelationalQualificationStructure(
          'collaboration',
          'equal',
          false,
          'boolean',
          'db'
        ),
      ]),
    })
    .then((data) => (data.objectList || []).map(transformRoom))
}

export function claimChatApi(roomId, technicianId) {
  return api.get(`/chat/${roomId}/info/claimTo/${technicianId}`)
}

export function tranferChatApi(roomId, technicianId) {
  return api.get(`/chat/${roomId}/info/transferTo/${technicianId}`)
}

export function transferChatApi(roomId, technicianId) {
  return api.get(`/chat/${roomId}/info/transferTo/${technicianId}`)
}

export function updateChatRoomApi(roomId, data) {
  return api.patch(`/chat/info/${roomId}`, data, {
    message: __rootT('updated_successfully', {
      resource: __rootTc('chat', 1),
    }),
  })
}

export function getChatSupportedUsersApi() {
  return api
    .get('/chat/config/supportedUser')
    .then((data) => (data.objectList || []).map(transformUser))
}

export function getPendingCountApi() {
  return api
    .get(`/chat/info/pendingCount`, { notify: false })
    .then((data) => data.total)
}
