<template>
  <FlotoContentLoader :loading="false">
    <div class="flex flex-col flex-1 mt-4">
      <div class="basic-details">
        <MRow class="px-4">
          <MCol :size="10">
            <h5>{{ `${$tc('contact')} ${$tc('details')}` }}</h5>
          </MCol>
          <MCol :size="2" class="text-right">
            <AddUpdateRequester
              v-if="!mainUser.id"
              :default-channel="defaultChannel"
              @done="handleDone"
            />
            <AddRequesterDrawer v-else :user="mainUser" @done="handleDone">
              <template v-slot:trigger>
                <MIcon name="pencil" size="lg" variant="primary" />
              </template>
            </AddRequesterDrawer>
          </MCol>
        </MRow>
        <MRow class="px-4 mt-2">
          <MCol :size="12">
            <div class="text-neutral mb-1">
              {{ $tc('phone_numbers') }}
            </div>
            <div
              v-if="
                mainUser.contactNo ||
                  mainUser.altContactNo1 ||
                  mainUser.altContactNo2
              "
              class="flex flex-wrap"
            >
              {{
                [
                  mainUser.contactNo,
                  mainUser.altContactNo1,
                  mainUser.altContactNo2,
                ]
                  .filter((item) => item && item)
                  .join(', ')
              }}
            </div>
            <div v-else>
              {{ '---' }}
            </div>
          </MCol>
          <MCol class="mt-4">
            <div class="text-neutral">
              {{ `${$tc('last')} ${$tc('called')}` }}
            </div>
            <div v-if="defaultChannel.lastCallTime">
              {{ defaultChannel.lastCallTime | datetime }} ({{
                defaultChannel.lastCallTime | timeago
              }})
            </div>
            <div v-else> {{ '---' }} </div>
          </MCol>
        </MRow>
        <MDivider />
      </div>
      <div class="recent-list flex flex-1 h-0">
        <div
          class="flex flex-col flex-1 border border-b-1 border-t-0 border-l-0 border-r-0 border-solid border-neutral-lighter overflow-x-hidden h-auto"
        >
          <h5 class="px-4">{{ $tc('coops_recent_calls') }}</h5>
          <div
            v-if="recentCalls.length"
            class="flex flex-col flex-1 overflow-y-scroll overflow-x-hidden h-0 px-4"
          >
            <div
              v-for="(item, index) in recentCalls"
              :key="index"
              class="recent-item flex border border-b-1 border-t-0 border-l-0 border-r-0 border-solid border-neutral-lightest py-2 w-full"
            >
              <div class="left">
                <MTooltip class=" mr-2">
                  <template v-slot:trigger>
                    <div style="position: relative">
                      <MIcon name="phone-alt" size="lg" class="text-neutral" />
                      <MIcon
                        size="lg"
                        class="text-neutral"
                        :name="
                          (item.callType || '').toLowerCase() === 'incoming'
                            ? 'long-arrow-left'
                            : 'long-arrow-right'
                        "
                        style=" position: absolute;top: 1px;right: -4px;font-size: 10px;"
                      />
                    </div>
                  </template>
                  {{ $tc((item.callType || '').toLowerCase()) }}
                  {{ $tc('call') }}
                </MTooltip>
              </div>
              <div class="right flex flex-col flex-1">
                <div class="flex justify-between">
                  <span>
                    {{ item.requesterNumber }}
                  </span>
                </div>
                <div class="text-neutral flex">
                  <small class="mr-2">
                    <MIcon name="user" />
                    {{ item.assignee }}
                  </small>
                  <small class="mr-2">
                    <MIcon name="clock" />
                    {{ item.callTime | datetime }}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <FlotoNoData size="small" />
          </div>
        </div>
      </div>
      <div class="count-box">
        <MRow :gutter="0" class="dashboard-view-container mt-4">
          <MCol :size="12" class="mb-6">
            <MRow :gutter="0">
              <MCol
                class="mr-4 ml-4 border border-solid border-neutral-lighter rounded"
                :size="5"
              >
                <h1 class="mb-0 ml-2">
                  {{ waitingCallCount || 0 }}
                </h1>
                <h5 class="mb-0 ml-2 text-ellipsis font-normal">{{
                  'Wait Call Queue'
                }}</h5>
              </MCol>
              <MCol
                class="border border-solid border-neutral-lighter rounded"
                :size="5"
              >
                <h1 class="mb-0 ml-2">
                  {{ onHoldCount || 0 }}
                </h1>
                <h5 class="mb-0 ml-2 text-ellipsis font-normal">{{
                  'Hold Calls'
                }}</h5>
              </MCol>
            </MRow>
          </MCol>
        </MRow>
      </div>
    </div>
  </FlotoContentLoader>
</template>

<script>
import Bus from '@utils/emitter'
import { transformContactOpsChannel } from '../data'
import {
  getRecentCallsApi,
  getWaitingCallCountApi,
  getHoldCallCountApi,
} from '../api'
import AddUpdateRequester from './add-update-requester'
import AddRequesterDrawer from './add-requester-drawer'
export default {
  name: 'RequesterDetails',
  components: { AddUpdateRequester, AddRequesterDrawer },
  props: {
    user: {
      type: Object,
      required: true,
    },
    defaultChannel: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      recentCalls: [],
      waitingCallCount: 0,
      onHoldCount: 0,
      fullUser: { ...this.user },
    }
  },
  computed: {
    mainUser() {
      return this.fullUser
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(newValue, prevValue) {
        if (newValue !== prevValue) {
          this.fullUser = {
            ...newValue,
          }
          Bus.$emit('app:contactops_channel_updated', {
            unknown: !newValue.id,
            requesterId: newValue.id,
            requesterName: newValue.name,
          })
        }
      },
    },
  },
  created() {
    this.getRecentCalls()

    getWaitingCallCountApi().then((data) => {
      this.waitingCallCount = data
    })

    getHoldCallCountApi().then((data) => {
      this.onHoldCount = data
    })
    const handleContactOpsChannelUpdated = (payload) => {
      const channel = transformContactOpsChannel(payload)
      if (channel.type === 'waitingCallCount') {
        this.waitingCallCount = channel.waitingCallCount
      }
      if (channel.type === 'onHoldCount') {
        this.onHoldCount = channel.onHoldCount
      }
    }

    Bus.$on('contactops_channel_updated', handleContactOpsChannelUpdated)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('contactops_channel_updated', handleContactOpsChannelUpdated)
    })
  },
  methods: {
    getRecentCalls(id) {
      let callApi = true
      if (this.defaultChannel.unknown) {
        callApi = false
      }
      if (id) {
        callApi = true
      }
      if (callApi) {
        getRecentCallsApi(id || this.defaultChannel.requesterId).then(
          (data) => {
            this.recentCalls = data.items || []
          }
        )
      }
    },
    handleDone(user) {
      this.fullUser = { ...user }
      Bus.$emit('app:contactops_channel_updated', {
        unknown: false,
        requesterId: user.id,
        requesterName: user.name,
      })
      Bus.$emit('app:user_update', user)
      this.getRecentCalls(user.id)
    },
  },
}
</script>
