<script>
import io from 'socket.io-client'
import { authComputed } from '@state/modules/auth'
import { OrganizationComputed } from '@state/modules/organization'
const URL = window.location.origin
// const URL = 'http://localhost:8000'

export default {
  provide() {
    const socketIOClientContext = {}
    Object.defineProperty(socketIOClientContext, 'eventPrefix', {
      enumerable: true,
      get() {
        return this.socket.query.eventPrefix
      },
    })
    Object.defineProperty(socketIOClientContext, 'socket', {
      enumerable: true,
      get: () => {
        return this.socket
      },
    })
    Object.defineProperty(socketIOClientContext, 'connected', {
      enumerable: true,
      get: () => {
        return this.connected
      },
    })
    return { socketIOClientContext }
  },
  props: {
    socketPath: { type: String, required: true },
    isRequester: { type: Boolean, default: false },
  },
  data() {
    return {
      socket: undefined,
      connected: false,
    }
  },
  computed: {
    ...authComputed,
    ...OrganizationComputed,
  },
  created() {
    const query = {
      id: this.user.id,
      name: this.user.name,
      token: this.accessToken,
      useDomainEventPrefix: this.multiTenantEnvironment,
      eventPrefix: this.multiTenantEnvironment ? window.location.host : '',
    }
    this.socket = io(URL, {
      path: `${process.env.VUE_APP_API_BASE_PATH}${this.socketPath}`,
      // path: `${this.socketPath}`,
      reconnectionAttempts: 5,
      query,
    })
    this.socket.on('connect', this.onConnectHandler)
    this.socket.on('connect_error', this.onDisconnectHandler)
  },
  beforeDestroy() {
    this.socket.off('connect', this.onConnectHandler)
    this.socket.off('connect_error', this.onDisconnectHandler)
    this.socket.disconnect()
  },
  methods: {
    onConnectHandler() {
      this.connected = true
    },
    onDisconnectHandler() {
      this.connected = false
    },
  },
  render() {
    return this.$scopedSlots.default()
  },
}
</script>
