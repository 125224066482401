<template>
  <div class="w-full flex">
    <slot name="bot-avatar"></slot>
    <div class="content w-full" :class="{ 'my-message': isMyMessage }">
      <div
        v-if="message.message"
        class="flex flex-col"
        v-html="message.message"
      >
      </div>
      <div class="flex flex-col">
        <div
          v-for="(option, index) in message.options"
          :key="index"
          class="flex mx-2 my-2 rounded action-message-options"
        >
          <div class="py-3 px-2 icon-container">
            <PluginMessageModuleIcon
              v-if="
                (option.rightHeaderLink && option.rightHeaderLink.model) ||
                (option.leftHeaderLink && option.leftHeaderLink.model)
              "
              :module-name="
                (option.rightHeaderLink && option.rightHeaderLink.model) ||
                (option.leftHeaderLink && option.leftHeaderLink.model)
              "
            />
            <MIcon v-else name="tire-rugged" size="2x" class="mx-1" />
          </div>
          <div class="flex flex-col p-2 flex-1 min-w-0">
            <div
              v-if="option.rightHeaderLink || option.leftHeaderLink"
              class="flex flex-col"
              :gutter="0"
            >
              <div
                v-if="(option.leftHeaderLink || {}).text"
                class="flex items-center pb-1"
              >
                <FlotoLink
                  v-if="option.leftHeaderLink && option.leftHeaderLink.model"
                  :key="option.leftHeaderLink.text"
                  target="_blank"
                  :to="
                    getDetailLinkForModule(
                      option.leftHeaderLink.model,
                      option.leftHeaderLink.id,
                      $modules,
                      option.leftHeaderLink.model
                    ).link
                  "
                >
                  {{ (option.leftHeaderLink || {}).text }}
                </FlotoLink>
                <div v-else>
                  {{ (option.leftHeaderLink || {}).text }}
                </div>
              </div>
              <div
                v-if="(option.rightHeaderLink || {}).text"
                class="flex items-center pb-1"
              >
                <FlotoLink
                  v-if="option.rightHeaderLink && option.rightHeaderLink.model"
                  :key="option.rightHeaderLink.text"
                  target="_blank"
                  :to="
                    getDetailLinkForModule(
                      option.rightHeaderLink.model,
                      option.rightHeaderLink.id,
                      $modules,
                      option.rightHeaderLink.model
                    ).link
                  "
                >
                  {{ (option.rightHeaderLink || {}).text }}
                </FlotoLink>
                <div v-else>
                  {{ (option.rightHeaderLink || {}).text }}
                </div>
              </div>
            </div>
            <div
              v-for="o in option.response"
              :key="o.key"
              class="flex flex-col"
              :gutter="0"
            >
              <div class="flex items-center pb-1 text-ellipsis">
                {{ o.key }}:
                <span
                  class="page-secondary-text-color px-1 text-ellipsis"
                  v-html="o.text"
                >
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot name="sender-avatar"></slot>
  </div>
</template>

<script>
import { getDetailLinkForModule } from '@utils/get-module-detail-link'
import PluginMessageModuleIcon from './plugin-message-module-icon.vue'
import MessageMixin from '../../mixins/message-mixin'
export default {
  name: 'UtilityPluginMessage',
  components: { PluginMessageModuleIcon },
  mixins: [MessageMixin],
  data() {
    this.getDetailLinkForModule = getDetailLinkForModule
    return {}
  },
}
</script>

<style lang="less" scoped>
.action-message-options {
  color: var(--page-text-color);
  background-color: var(--page-background-color);
  border: 2px dashed var(--border-color);
  border-radius: 8px;

  & .icon-container {
    color: var(--ai-bot-primary);
  }
}
</style>
