var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoCrudContainer',{attrs:{"as-table":"","fetch-fn":_vm.getActiveAgents,"create-fn":_vm.addProductType,"columns":_vm.tableColumns,"scrollable":false},scopedSlots:_vm._u([{key:"add-controls",fn:function(){return [_c('span')]},proxy:true},{key:"form",fn:function(){return [_c('span')]},proxy:true},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item.name || '---')+" ")])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_c('AgentStatusPicker',{attrs:{"disabled":"","value":item.status}})],1)]}},{key:"durationInStatus",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item.durationInStatus || '---')+" ")])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('FlotoDeleteBtn',{attrs:{"message":_vm.$t('confirm_transfercall')},on:{"confirm":function () { return _vm.handleTransferCall(item); }}},[_c('MIcon',{staticClass:"ml-2",class:!_vm.disabled ? 'cursor-pointer text-primary' : '',attrs:{"name":"phone-alt"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }