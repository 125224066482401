<template>
  <MComment v-if="!isEditing" class="work-conversation">
    <template v-slot:avatar>
      <FlotoUserAvatar
        size="large"
        :disabled="isPortalLogin"
        :user-id="ownerTechnician.id || owner"
      />
    </template>
    <template v-slot:content>
      <MRow :gutter="0">
        <MCol
          class="box-container"
          :class="isDraft ? 'border-secondary-red' : conversation.borderClass"
        >
          <MTooltip v-if="isPrivate && usePrivateIndicator">
            <template v-slot:trigger>
              <MIcon
                name="lock-alt"
                class="mr-2 text-neutral absolute"
                :style="
                  isRtl
                    ? { top: '15px', right: '0px' }
                    : { top: '15px', left: '4px' }
                "
              />
            </template>
            {{ $t('not_visible_to_requester') }}
          </MTooltip>
          <template v-if="conversation.type === 'feedback'">
            <Feedback v-bind="{ feedback: $props }" />
          </template>
          <template v-else-if="conversation.type === 'call_log'">
            <CallLog
              :conversation="conversation"
              v-bind="{ callLog: $props }"
            />
          </template>
          <template v-else>
            <MCol class="editor-row">
              <MRow :gutter="0" class="flex-no-wrap items-start">
                <MCol class="mr-2">
                  <div v-if="isDraft" class="draft-text">
                    {{ `[${$tc('draft')}]` }}
                  </div>
                  <template
                    v-if="
                      [
                        'forward_notification',
                        'technician_to_requester',
                        'requestor_to_technician',
                      ].indexOf(conversation.type) >= 0
                    "
                  >
                    <div>
                      <h5> {{ subject }}</h5>
                      <FlotoFormItemReadOnly
                        v-if="(emailIds || []).length > 0"
                        :label="$tc('email', 2)"
                      >
                        <FlotoTagsPicker
                          :value="emailIds"
                          disabled
                          type="email"
                        />
                      </FlotoFormItemReadOnly>
                      <FlotoFormItemReadOnly
                        v-if="(ccEmailIds || []).length > 0"
                        :label="$tc('cc_email', 2)"
                      >
                        <FlotoTagsPicker
                          :value="ccEmailIds"
                          disabled
                          type="email"
                        />
                      </FlotoFormItemReadOnly>
                      <MDivider />
                    </div>
                  </template>
                  <div class="flex flex-col">
                    <FlotoReadMore :height="richBoxRows * 20">
                      <FlotoRichEditor
                        :value="latestText"
                        :disabled="true"
                        :enable-mention="true"
                        class="flex-1 border-none mb-1"
                      />
                    </FlotoReadMore>
                  </div>
                  <div v-if="trailText" class="flex flex-col">
                    <a @click="toggleTrail">
                      <MTooltip>
                        <template v-slot:trigger>
                          <MIcon name="ellipsis-h" size="3x" />
                        </template>
                        {{
                          $t(
                            trailExpanded
                              ? 'hide_expanded_content'
                              : 'show_trimmed_content'
                          )
                        }}
                      </MTooltip>
                    </a>
                    <div v-if="trailExpanded" class="trail-text">
                      <FlotoRichEditor
                        :value="trailText"
                        :disabled="true"
                        :enable-mention="true"
                        class="flex-1 border-none mb-1"
                      />
                    </div>
                  </div>
                </MCol>
                <MCol
                  auto-size
                  :class="conversation.textClass"
                  class="flex items-center"
                >
                  <a
                    v-if="!disabled && canEdit"
                    href="javascript:;"
                    class="text-neutral"
                    @click="handleEdit"
                  >
                    <MTooltip>
                      <template v-slot:trigger>
                        <MIcon name="pencil" class="mx-1 text-primary" />
                      </template>
                      {{ $t('edit') }}
                    </MTooltip>
                  </a>
                  <FlotoDeleteBtn
                    v-if="(!disabled && canDelete) || canDeleteReview"
                    :message="$t('confirm_remove_item', { item: toolTipText })"
                    class="mx-1"
                    @confirm="$emit('delete')"
                  />
                  <MTooltip v-if="!disabled" class="info-icon">
                    <template v-slot:trigger>
                      <MIcon :name="conversation.icon" size="lg" class="mx-1" />
                    </template>
                    {{ toolTipText }}
                  </MTooltip>
                  <MTooltip
                    v-if="
                      [
                        'technician_to_requester',
                        'requestor_to_technician',
                        'forward_notification',
                        'other_user_to_requster',
                      ].indexOf(type) >= 0 &&
                      !isPortalLogin &&
                      !isDraft
                    "
                  >
                    <template v-slot:trigger>
                      <MIcon
                        name="share"
                        class="mx-1 cursor-pointer text-primary"
                        size="lg"
                        @click="
                          $emit('forward', {
                            text,
                            attachments,
                            createdAt,
                            owner,
                          })
                        "
                      />
                    </template>
                    {{ $t('forward') }}
                  </MTooltip>
                  <MTooltip
                    v-if="
                      [
                        'technician_to_requester',
                        'requestor_to_technician',
                        'forward_notification',
                        'other_user_to_requster',
                      ].indexOf(type) >= 0 &&
                      !isPortalLogin &&
                      !isDraft
                    "
                  >
                    <template v-slot:trigger>
                      <MIcon
                        name="reply-to-requester"
                        class="mx-1 cursor-pointer text-primary"
                        size="lg"
                        @click="
                          $emit('reply', {
                            text,
                            attachments,
                            createdAt,
                            owner,
                            emailIds,
                            ccEmailIds,
                          })
                        "
                      />
                    </template>
                    {{ $t('reply') }}
                  </MTooltip>
                </MCol>
              </MRow>
            </MCol>
          </template>

          <MCol class="mb-2">
            <MRow :gutter="0">
              <MCol :size="12">
                <FlotoAttachment v-model="attachments" disabled />
              </MCol>
              <MCol :size="12" class="mt-2">
                <MRow>
                  <MCol auto-size>
                    <div class="text-neutral">
                      <small>
                        {{ $t('added_by') }}
                        <FlotoUserDrawer
                          :user-id="owner"
                          v-bind="{
                            ...(type === 'requestor_to_technician'
                              ? { userId: owner }
                              : {
                                  user: ownerTechnician,
                                }),
                          }"
                          :disabled="isPortalLogin"
                        />
                      </small>
                    </div>
                  </MCol>
                  <MCol
                    auto-size
                    :style="
                      isRtl ? { marginRight: 'auto' } : { marginLeft: 'auto' }
                    "
                  >
                    <div class="text-neutral">
                      <small>
                        {{ createdAt | datetime }} ({{ createdAt | timeago }})
                      </small>
                    </div>
                  </MCol>
                </MRow>
              </MCol>
            </MRow>
          </MCol>
        </MCol>
      </MRow>
    </template>
  </MComment>
  <AddBox
    v-else
    :id="id"
    :processing="processing"
    :conversation-type="conversation"
    :resource="resource"
    :allow-save-in-draft="conversation.type !== 'note' && allowSaveInDraft"
    v-bind="formData"
    :is-private="isPrivate && usePrivateIndicator"
    :allow-templates="allowTemplates"
    :allow-subject="allowSubject"
    :allow-email="allowEmail"
    :allow-cc-email="allowCcEmail"
    :allow-outgoing-email="allowOutgoingEmail"
    :default-email-send-config-id="defaultEmailSendConfigId"
    :module-name="moduleName"
    :allow-knowledges="allowKnowledges"
    :form-data="{ subject, emailIds, ccEmailIds }"
    :is-draft="isDraft"
    @submit="updateConversation"
    @remove="resetConversation"
  />
</template>

<script>
import { authComputed } from '@state/modules/auth'
import { PreferenceComputed } from '@state/modules/preference'
import { TechnicianComputed } from '@state/modules/technician'
import { ConversationType } from './conversation-types'
import AddBox from './conversation-add-box'
import Feedback from './feedback'
import CallLog from './call-log'

export default {
  name: 'Conversation',
  components: { AddBox, Feedback, CallLog },
  props: {
    id: { type: Number, default: undefined },
    update: { type: Function, default: undefined },
    type: { type: String, required: true },
    text: { type: [String], default: undefined },
    attachments: { type: Array, required: true },
    rating: { type: Number, default: 0 },
    fieldValueDetails: {
      type: Object,
      default() {
        return {}
      },
    },
    subject: { type: [String], default: undefined },
    emailIds: {
      type: Array,
      default() {
        return []
      },
    },
    ccEmailIds: {
      type: Array,
      default() {
        return []
      },
    },
    createdAt: { type: Number, required: true },
    owner: { type: Number, default: undefined },
    canEdit: { type: Boolean, default: false },
    canDelete: { type: Boolean, default: false },
    isPrivate: { type: Boolean, default: false },
    inlineEdit: { type: Boolean, default: false },
    usePrivateIndicator: { type: Boolean, required: true },
    disabled: { type: Boolean, default: false },
    richBoxRows: { type: Number, default: 8 },
    resource: {
      type: Object,
      default() {
        return {}
      },
    },
    allowSaveInDraft: { type: Boolean, default: false },
    isDraft: { type: Boolean, default: false },
    allowKnowledges: { type: Boolean, default: false },
    allowTemplates: { type: Boolean, default: false },
    moduleName: { type: String, default: undefined },
    defaultEmailSendConfigId: { type: Number, default: 0 },
  },
  data() {
    return {
      processing: false,
      isEditing: false,
      formData: {},
      trailExpanded: false,
    }
  },
  computed: {
    ...authComputed,
    ...TechnicianComputed,
    ...PreferenceComputed,
    conversation() {
      return ConversationType()[this.type] || {}
    },
    toolTipText() {
      if (this.isPortalLogin && this.type === 'technician_to_requester') {
        return this.$t('reply_from_technician')
      }
      if (!this.isPortalLogin && this.type === 'requestor_to_technician') {
        return this.$t('reply_from_requester')
      }
      return this.conversation.name
    },
    ownerTechnician() {
      return this.technician(this.owner)
    },
    allowSubject() {
      if (
        ['forward_notification', 'other_user_to_requster'].indexOf(
          this.conversation.type
        ) >= 0
      ) {
        return true
      }
      return false
    },
    allowEmail() {
      if (
        [
          'forward_notification',
          'other_user_to_requster',
          'technician_to_requester',
        ].indexOf(this.conversation.type) >= 0
      ) {
        return true
      }
      return false
    },
    allowCcEmail() {
      if (
        [
          'forward_notification',
          'other_user_to_requster',
          'technician_to_requester',
        ].indexOf(this.conversation.type) >= 0
      ) {
        return true
      }
      return false
    },
    allowOutgoingEmail() {
      if (
        [
          'forward_notification',
          'other_user_to_requster',
          'technician_to_requester',
        ].indexOf(this.conversation.type) >= 0
      ) {
        return true
      }
      return false
    },
    canDeleteReview() {
      if (this.conversation.type === 'review') {
        return (
          (!this.disabled && this.canDelete) ||
          this.myAllowedModules.indexOf('knowledge.can_manage_review') >= 0
        )
      }
      return !this.disabled && this.canDelete
    },
    latestText() {
      const regEx = /<hr(?:[^>]*)\/?>/
      const arr = this.text.split(regEx)
      const filteredArr = arr.filter((e) => e)
      if (filteredArr.length > 1) {
        return arr[0]
      }
      return this.text
    },
    trailText() {
      const regEx = /<hr(?:[^>]*)\/?>/
      const arr = this.text.split(regEx)
      const filteredArr = arr.filter((e) => e)
      if (filteredArr.length > 1) {
        arr.shift()
        return arr.join('')
      }
      return ''
    },
  },
  methods: {
    toggleTrail() {
      this.trailExpanded = !this.trailExpanded
    },
    handleEdit() {
      if (this.inlineEdit) {
        this.formData = {
          value: this.text,
          fileAttachments: this.attachments.map((a) => a),
          ...(this.allowSubject ? { subject: this.subject } : {}),
          ...(this.allowEmail ? { emailIds: this.emailIds } : {}),
          ...(this.allowCcEmail ? { ccEmailIds: this.ccEmailIds } : {}),
          ...(this.allowOutgoingEmail
            ? { emailSendConfigId: this.emailSendConfigId }
            : {}),
        }
        this.isEditing = true
      } else {
        this.$emit('edit')
      }
    },
    updateConversation(conversation) {
      if (!this.update) {
        throw new Error('update function not found for conversation')
      }
      this.processing = true
      this.update(conversation)
        .then((response) => {
          this.isEditing = false
          return response
        })
        .finally(() => {
          this.processing = false
        })
    },
    resetConversation() {
      this.formData = {}
      this.isEditing = false
    },
  },
}
</script>

<style lang="less" scoped>
.work-conversation {
  .@{ant-prefix}-comment-content {
    padding: 0;
  }
}

.box-container {
  @apply border-solid border rounded border-l-4 px-6;

  border-top-color: var(--border-color);
  border-right-color: var(--border-color);
  border-bottom-color: var(--border-color);

  .editor-row {
    @apply p-2;
  }
}

.draft-text {
  color: var(--secondary-red);
}

i.info-icon {
  position: absolute;
  top: -26px;
  right: -19px;
  padding: 4px;
  font-size: 9px;
  background: var(--page-background-color);
  border: 1px solid var(--border-color);
  border-radius: 50%;
}

.trail-text {
  padding-left: 2px;
  border-left: 1px solid var(--border-color);
}
</style>
