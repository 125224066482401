<template>
  <MTooltip :disabled="!tooltipText">
    <template v-slot:trigger>
      <div
        class="counter-step"
        :style="{
          textAlign: 'center',
        }"
        :class="{
          'bg-secondary-green': status === 'success',
          'bg-secondary-red': status === 'error',
          'bg-neutral-light': status === 'pending',
          'bg-primary': status === 'progress',
          active,
        }"
      >
        <div :class="{ active }">
          <slot />
        </div>
      </div>
    </template>
    {{ tooltipText }}
  </MTooltip>
</template>

<script>
export default {
  name: 'CounterStep',
  props: {
    status: { type: String, default: 'pending' },
    tooltipText: { type: String, default: undefined },
    active: { type: Boolean, default: false },
  },
}
</script>

<style lang="less" scoped>
.counter-step {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  min-height: 50px;
  // padding-top: 10px;
  // padding-right: 20px;
  // padding-bottom: 10px;
  // padding-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  color: @white;
  border-radius: 30px;

  &.active {
    margin-right: 5px;
    margin-left: 5px;
  }

  &.active::after {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    z-index: -1;
    content: ' ';
    border-radius: 50%;
  }
  &.bg-secondary-green.active::after {
    background: fade(@secondary-green, 35%);
  }
  &.bg-secondary-red.active::after {
    background: fade(@secondary-red, 35%);
  }
  &.bg-neutral-light.active::after {
    background: fade(@neutral-light, 35%);
  }
  &.bg-primary.active::after {
    background: fade(@primary-color, 35%);
  }
}
</style>
