<template>
  <FlotoFixedView>
    <div class="w-full flex flex-col flex-1 ">
      <MRow :size="12" class="min-h-0 flex-no-wrap flex-1" :gutter="0">
        <MCol
          :size="3"
          class="flex flex-col overflow-hidden pr-1 border border-b-0 border-t-0 border-l-0 border-r-1 border-solid border-neutral-lighter"
        >
          <RequesterDetails :user="user" :default-channel="defaultChannel" />
        </MCol>
        <MCol :size="9">
          <RequestList :is-open="isOpen" :default-channel="defaultChannel" />
        </MCol>
      </MRow>
    </div>
  </FlotoFixedView>
</template>

<script>
import RequesterDetails from './requester-details'
import RequestList from './request-list'

export default {
  name: 'OngoingCallArea',
  components: { RequesterDetails, RequestList },
  props: {
    user: {
      type: Object,
      default() {
        return {}
      },
    },
    defaultChannel: {
      type: Object,
      default() {
        return {}
      },
    },
    isOpen: { type: Boolean, default: false },
  },
  data() {
    this.moduleName = this.$constants.REQUEST
    return {}
  },
}
</script>
