<template>
  <a @click="trigger">
    <slot name="trigger" />
    <FlotoDrawerForm
      :open="isDrawerOpen"
      @submit="handleFormSubmit"
      @cancel="cancelAction"
    >
      <template v-slot:header>
        {{ formData.id ? $tc('update') : $tc('add') }}
        {{ $tc('requester') }}
      </template>
      <FlotoFixedView :gutter="0">
        <FlotoScrollView>
          <MRow :gutter="0" class="mb-4 px-4">
            <MCol :size="12">
              <MRow class="flex-no-wrap">
                <MCol class="pl-4">
                  <FlotoFormItem
                    v-model="formData.name"
                    :wrapper-col="{ xs: 5 }"
                    :label="$tc('full_name')"
                    :placeholder="$tc('name')"
                    rules="required|short_text"
                  />
                  <FlotoFormItem
                    v-model="formData.email"
                    rules="email"
                    :placeholder="$tc('email')"
                    :label="$tc('email')"
                    :disabled="Boolean(user.email)"
                  />
                  <PasswordInput
                    v-if="!user.id && usePassword"
                    v-model="formData.password"
                    :label="$t('password')"
                    vid="password"
                    :rules="{ required: !user.id }"
                    :placeholder="$t('password')"
                  />
                  <PasswordInput
                    v-if="!user.id && usePassword"
                    v-model="formData.confirmPassword"
                    :label="$t('confirm_password')"
                    :rules="{ confirmed: 'password', required: !user.id }"
                    :placeholder="$t('confirm_password')"
                  />
                  <FlotoFormItem
                    v-model="formData.contactNo"
                    :disabled="!Boolean(formData.id)"
                    rules="required|contact_number"
                    :label="$tc('contact_no')"
                    class="w-full"
                    :placeholder="$tc('contact_no')"
                  />
                  <FlotoFormItem
                    v-model="formData.altContactNo1"
                    rules="contact_number"
                    :label="$tc('alt_contact_no1')"
                    class="w-full"
                    :placeholder="$tc('alt_contact_no1')"
                  />
                  <FlotoFormItem
                    v-model="formData.altContactNo2"
                    rules="contact_number"
                    :label="$tc('alt_contact_no2')"
                    class="w-full"
                    :placeholder="$tc('alt_contact_no2')"
                  />
                  <FlotoFormItem :label="$tc('department')">
                    <FlotoDepartmentPicker
                      v-model="formData.department"
                      class="w-full"
                      as-input
                    />
                  </FlotoFormItem>
                  <FlotoFormItem :label="$tc('location')">
                    <FlotoLocationPicker
                      v-model="formData.location"
                      class="w-full"
                      :placeholder="$tc('location')"
                    />
                  </FlotoFormItem>
                  <FlotoFormItem :label="`${$tc('vip')} ${$tc('requester')}`">
                    <MRadioGroup
                      v-model="formData.vip"
                      as-button
                      :options="vipOptons"
                      :default-value="false"
                    />
                  </FlotoFormItem>
                  <div class="-mx-2">
                    <FormConsumer
                      v-if="customFields.length"
                      v-model="formData.fieldValueDetails"
                      :form-fields="customFields"
                      :with-submit="false"
                    />
                  </div>
                </MCol>
              </MRow>
            </MCol>
          </MRow>
        </FlotoScrollView>
      </FlotoFixedView>
      <template v-slot:actions="{ hide, submit }">
        <MButton
          variant="primary"
          :loading="processing"
          class="ml-2"
          @click="submit"
        >
          {{ formData.id ? $tc('update') : $tc('add') }}
        </MButton>
        <MButton variant="default" class="ml-2" @click="hide">
          {{ $tc('cancel') }}
        </MButton>
      </template>
    </FlotoDrawerForm>
  </a>
</template>

<script>
import { FormComputed } from '@state/modules/form'
import { PreferenceComputed } from '@state/modules/preference'
import FormConsumer from '@components/form-consumer.vue'
import { createUserApi, updateUserApi } from '../api'
import PasswordInput from '@components/password-input'

export default {
  name: 'AddRequesterDrawer',
  components: { FormConsumer, PasswordInput },
  props: {
    user: { type: Object, required: true },
  },
  data() {
    this.vipOptons = [
      { text: this.$t('yes'), value: true },
      { text: this.$t('no'), value: false },
    ]
    return {
      isDrawerOpen: false,
      processing: false,
      formData: {
        ...this.user,
      },
    }
  },
  computed: {
    ...FormComputed,
    ...PreferenceComputed,
    customFields() {
      return (this.userForm || {}).fields || []
    },
    usePassword() {
      return this.tenantPrefrences.showPassword
    },
  },
  watch: {
    user(newValue) {
      if (newValue) {
        this.formData = { ...this.formData, ...newValue }
      }
    },
  },
  methods: {
    trigger() {
      setTimeout(() => {
        this.isDrawerOpen = true
        this.$emit('trigger')
      })
    },
    cancelAction() {
      this.isDrawerOpen = false
      this.formData = {
        ...this.user,
      }
    },
    handleFormSubmit() {
      this.processing = true
      if (this.formData.id) {
        return updateUserApi(this.formData)
          .then((data) => {
            this.$emit('done', data)
            this.cancelAction()
            this.$emit('close-drawer')
          })
          .finally(() => {
            this.processing = false
          })
      } else {
        return createUserApi(this.formData)
          .then((data) => {
            this.$emit('done', data)
            this.cancelAction()
            this.$emit('close-drawer')
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
  },
}
</script>
