<template>
  <portal to="contactOps-container">
    <ContactOpsProvider v-if="initiated">
      <OngoingCallBox
        v-if="initiated"
        :default-channel="channelData"
        @close-call="handleCloseCall"
      />
    </ContactOpsProvider>
    <CallbackReminderPopup
      v-if="reminder"
      :call-back-remider-data="callBackRemiderData"
      @close-reminder="reminder = false"
    />
    <ReasonTaker
      ref="reasonDrawer"
      type="call"
      :create-fn="handleReasonAdded"
    />
  </portal>
</template>
<script>
import Bus from '@utils/emitter'
import { channelStatusOptions } from '@components/contactOps/helpers/channel-statuses'
import ContactOpsProvider from '@components/contactOps/contactOps-provider'
import ReasonTaker from '@modules/contactOps-manager/components/reason-taker/reason-taker'
import { transformContactOpsChannel } from './data'
import * as STATUSES from './helpers/channel-statuses'
import OngoingCallBox from './ongoing-call-box'
import CallbackReminderPopup from './callback-reminder-popup'
import { updateCallActionApi } from './api'

export default {
  name: 'OngoingCallContainer',
  components: {
    ReasonTaker,
    OngoingCallBox,
    CallbackReminderPopup,
    ContactOpsProvider,
  },
  data() {
    return {
      initiated: false,
      reminder: false,
      channelData: {},
      callBackRemiderData: {},
      wrapUpCallData: {},
      requesterId: null,
    }
  },
  watch: {
    'channelData.callStatus': {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          Bus.$emit(
            'contactOps_channel_status_update',
            channelStatusOptions().find((s) => s.key === newValue)
          )
        }
      },
    },
  },
  created() {
    const handleContactOpsChannelUpdated = (payload) => {
      const channel = transformContactOpsChannel(payload)
      // when type is callbavk reminder open popup for it
      if (channel.type === 'callBackReminder') {
        this.callBackRemiderData = channel
        this.reminder = true
        return
      }
      this.channelData = channel
      if (channel.type === 'call') {
        this.requesterId = channel.requesterId
      }
      // when incoming call ringing open dialer
      if (
        channel.type === 'call' &&
        (channel.callStatus === STATUSES.BUSY ||
          channel.callStatus === STATUSES.ON_CALL ||
          channel.callStatus === STATUSES.WRAP_UP)
      ) {
        this.initiated = true
      }
      // when incoming call close or wrap up call dialer
      if (
        channel.type === 'call' &&
        [STATUSES.AVAILABLE, STATUSES.WRAP_UP, STATUSES.NOT_AVAILABLE].indexOf(
          channel.callStatus
        ) >= 0
      ) {
        this.channelData = channel
        if (
          [STATUSES.AVAILABLE, STATUSES.NOT_AVAILABLE].indexOf(
            channel.callStatus
          ) >= 0
        ) {
          this.initiated = false
          this.$refs.reasonDrawer.closeForm()
        }
      }
    }
    Bus.$on('contactops_channel_updated', handleContactOpsChannelUpdated)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('contactops_channel_updated', handleContactOpsChannelUpdated)
    })
    const handleAppContactOpsChannelUpdated = (change) => {
      this.channelData = {
        ...this.channelData,
        ...change,
      }
    }
    Bus.$on('app:contactops_channel_updated', handleAppContactOpsChannelUpdated)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off(
        'app:contactops_channel_updated',
        handleAppContactOpsChannelUpdated
      )
    })
    const handleAppWrapUpCallData = (change) => {
      this.wrapUpCallData = {
        ...this.wrapUpCallData,
        ...change,
      }
    }
    Bus.$on('app:update_wrap_up_call_data', handleAppWrapUpCallData)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('app:update_wrap_up_call_data', handleAppWrapUpCallData)
    })
  },
  methods: {
    handleReasonAdded(data) {
      return updateCallActionApi(STATUSES.CLOSE, {
        disposalReason: data.disposalReason,
        remark: data.remark,
        requesterId: this.requesterId,
        requestId: this.wrapUpCallData.requestId,
      }).then(() => {
        this.initiated = false
        this.$refs.reasonDrawer.closeForm()
      })
    },
    handleCloseCall() {
      this.$refs.reasonDrawer.openForm()
    },
  },
}
</script>
