import { colors } from '@utils/color'

export const BUSY = 'busy' // Ringing
export const ON_CALL = 'on_call' // Talking
export const WRAP_UP = 'wrap_up'
export const NOT_AVAILABLE = 'not_available'
export const AVAILABLE = 'available'
export const ON_BREAK = 'on_break'
export const OFFLINE = 'offline'
export const MUTE = 'mute'
export const UNMUTE = 'unmute'
export const HOLD = 'hold'
export const UNHOLD = 'unhold'
export const END = 'end'
export const CLOSE = 'close'
export const CALL_RNA = 'call_rna'
export const CALL_UNANSWER = 'call_unanswer'

export const channelStatusOptions = () => {
  return [
    {
      text: 'Not Available',
      key: NOT_AVAILABLE,
      status: NOT_AVAILABLE,
      color: colors['warning-light'],
    },
    {
      text: 'Available',
      key: AVAILABLE,
      status: AVAILABLE,
      color: colors.success,
    },
    {
      text: 'On Break',
      key: ON_BREAK,
      status: ON_BREAK,
      color: colors['warning-light'],
    },
    {
      text: 'Offline',
      key: OFFLINE,
      status: OFFLINE,
      color: colors['neutral-light'],
    },
    {
      text: 'Busy',
      key: BUSY,
      status: BUSY,
      color: colors.error,
    },
    {
      text: 'On Call',
      key: ON_CALL,
      status: ON_CALL,
      color: colors.error,
    },
    {
      text: 'Wrap-up',
      key: WRAP_UP,
      status: WRAP_UP,
      color: colors.error,
    },
  ]
}
