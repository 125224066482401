<template>
  <a @click="triggerAction">
    <slot name="trigger">
      <MTooltip>
        <template v-slot:trigger>
          <MButton shape="circle" class="mr-2" variant="neutral-lighter">
            <MIcon name="history" />
          </MButton>
        </template>
        {{ $tc('asset_update') }}
      </MTooltip>
    </slot>
    <FlotoDrawer :open="showDrawer" @hide="showDrawer = false">
      <template v-slot:title>
        {{ $tc('asset_update') }}
      </template>
      <ApprovalAssetUpdateData :stage-id="stageId" />
    </FlotoDrawer>
  </a>
</template>

<script>
import ApprovalAssetUpdateData from './approval-asset-update-data.vue'

export default {
  name: 'Audit',
  components: { ApprovalAssetUpdateData },
  props: {
    stageId: { type: Number, required: true },
  },
  data() {
    return {
      showDrawer: false,
    }
  },
  methods: {
    triggerAction() {
      this.$emit('trigger')
      setTimeout(() => {
        this.showDrawer = true
      }, 400)
    },
  },
}
</script>
