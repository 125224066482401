export const isCaptchaEnabled = (config, action) => {
  if (!config.enableCaptcha) {
    return false
  }
  switch (action) {
    case 'loginFormCaptcha':
      return config.captchaEnableModules.indexOf('sign_in_form') >= 0
    case 'forgotPasswordCaptcha':
      return config.captchaEnableModules.indexOf('forgot_password_form') >= 0
    case 'requestFormCaptcha':
      return (
        config.captchaEnableModules.indexOf(
          'guest_user_request_creation_form'
        ) >= 0
      )
    case 'selfSignupFormCaptcha':
      return config.captchaEnableModules.indexOf('requester_sign_up_form') >= 0
    default:
      return false
  }
}
