<template>
  <MComment>
    <template v-slot:avatar>
      <FlotoUserAvatar size="large" :user-id="user.id" />
    </template>
    <template v-slot:content>
      <MRow :gutter="0">
        <MCol class="box-container" :class="conversationType.borderClass">
          <div class="flex">
            <MCol class="editor-row">
              <FlotoForm
                layout="vertical"
                class="flex flex-col items-end w-full"
                @submit="() => handleSubmit()"
              >
                <MRow
                  v-if="allowSubject || allowEmail || allowCcEmail"
                  class="w-full forward-details"
                  :gutter="0"
                >
                  <MCol class="p-4">
                    <FlotoFormItem
                      v-if="allowEmail"
                      id="emails-picker"
                      class="w-full"
                      :label="$tc('email', 2)"
                      :rules="validationRules"
                    >
                      <FlotoTagsPicker v-model="emailIds" type="email" />
                    </FlotoFormItem>
                    <FlotoFormItem
                      v-if="allowCcEmail"
                      id="emails-picker"
                      class="w-full"
                      :label="$tc('cc_email', 2)"
                    >
                      <FlotoTagsPicker v-model="ccEmailIds" type="email" />
                    </FlotoFormItem>
                    <FlotoFormItem
                      v-if="allowSubject"
                      id="subject-input"
                      v-model="subject"
                      class="w-full"
                      :label="$t('subject')"
                      :placeholder="$t('subject')"
                      rules="required"
                    />
                    <FlotoFormItem
                      v-if="
                        allowUserToSelectFromEmail &&
                        allowOutgoingEmail &&
                        moduleName === $constants.REQUEST
                      "
                      id="outgoing-mail-picker"
                      :label="$tc('outgoing_email')"
                      :placeholder="$tc('outgoing_email')"
                      rules="nonzero"
                      :style="{ width: '30%' }"
                    >
                      <OutgoingMailPicker
                        v-model="emailSendConfigId"
                        as-input
                        only-enabled-options
                      />
                    </FlotoFormItem>
                  </MCol>
                </MRow>
                <FlotoFormItem
                  id="text-input"
                  rules="required"
                  class="w-full"
                  :validation-label="$t('text')"
                >
                  <FlotoRichEditor
                    :key="renderCount"
                    ref="editorRef"
                    v-model="text"
                    auto-focus
                    :name="$t('text')"
                    :rows="richBoxRows"
                    :affix-toolbar="false"
                    :toolbar="conversationType.editorToolbar || false"
                    :enable-mention="conversationType.enableMention"
                    :placeholder="$t('start_typing')"
                    :max-height="350"
                    class="flex-1 mb-1"
                  >
                    <template v-slot:editor-bar-actions>
                      <MTooltip>
                        <template v-slot:trigger>
                          <a
                            href="javascript:;"
                            class="text-neutral mt-2 mx-1 close-icon"
                            @click="$emit('remove')"
                          >
                            <MIcon name="times" />
                          </a>
                        </template>
                        {{ $t('cancel') }}
                      </MTooltip>
                      <MTooltip v-if="isPrivate">
                        <template v-slot:trigger>
                          <a class="cursor-default mt-2 private-lock">
                            <MIcon name="lock-alt" class="mr-2 text-neutral" />
                          </a>
                        </template>
                        {{ $t('not_visible_to_requester') }}
                      </MTooltip>
                    </template>
                  </FlotoRichEditor>
                </FlotoFormItem>
                <template v-slot:submit="{ validate }">
                  <MTooltip v-if="allowSaveInDraft">
                    <template v-slot:trigger>
                      <MButton
                        id="save-as-draft-btn"
                        :loading="processing"
                        class="mx-2 mb-4"
                        :style="
                          isRtl
                            ? { left: '60px', zIndex: 2 }
                            : { right: '60px', zIndex: 2 }
                        "
                        shape="circle"
                        type="button"
                        @click="
                          validate().then(
                            (response) => response && handelSaveInDraft()
                          )
                        "
                      >
                        <MIcon
                          v-if="!processing"
                          name="save"
                          size="lg"
                          class="mx-1"
                        />
                        <span v-else />
                      </MButton>
                    </template>
                    {{ $tc('save_as_draft') }}
                  </MTooltip>
                  <MTooltip
                    v-if="
                      !knowledgeIsInserted &&
                      conversationType.type === 'solution'
                    "
                  >
                    <template v-slot:trigger>
                      <MButton
                        id="add-to-knowledge-btn"
                        :loading="processing"
                        class="mx-2 mb-4"
                        :style="
                          isRtl
                            ? { left: '60px', zIndex: 2 }
                            : { right: '60px', zIndex: 2 }
                        "
                        shape="circle"
                        type="button"
                        @click="
                          validate().then(
                            (response) =>
                              response && handelSaveAndAddToKnowledge()
                          )
                        "
                      >
                        <MIcon
                          v-if="!processing"
                          name="lightbulb-on"
                          size="lg"
                          class="mx-1"
                        />
                        <span v-else />
                      </MButton>
                    </template>
                    {{ $tc('save_and_add_to_knowledge') }}
                  </MTooltip>
                  <MTooltip>
                    <template v-slot:trigger>
                      <MButton
                        id="save-btn"
                        :loading="processing"
                        class="mx-2 mb-4"
                        shape="circle"
                        type="submit"
                        :style="{
                          zIndex: 2,
                        }"
                      >
                        <MIcon
                          v-if="!processing"
                          name="paper-plane"
                          size="lg"
                          class="mx-1"
                        />
                        <span v-else />
                      </MButton>
                    </template>
                    {{
                      [
                        'technician_to_requester',
                        'forward_notification',
                        'requestor_to_technician',
                      ].indexOf(conversationType.type) >= 0
                        ? $tc('send')
                        : $tc('save')
                    }}
                  </MTooltip>
                </template>
              </FlotoForm>
            </MCol>
          </div>
          <MCol class="mb-3 px-2">
            <FlotoAttachment v-model="attachments" only-list />
          </MCol>
          <MCol class="mb-3 px-2 flex">
            <FlotoAttachment
              v-model="attachments"
              :show-upload-list="false"
              as-link
              @file-list-updated="fileList = $event"
            />
            <a href="javascript:;" class="text-neutral mx-2 hidden">
              <MIcon name="comment-check" class="mx-1" />
              {{ $t('canned') }} {{ $t('response') }}
            </a>
            <InsertResponseTemplate
              v-if="allowTemplates"
              :resource="resource"
              @selected="handleInsertTemplate"
            />
            <InsertKnowledge
              v-if="allowKnowledges"
              :resource="resource"
              @selected="handleInsertKnowledge"
            />
            <MCheckbox
              v-if="
                conversationType.type === 'note' &&
                showNoteAsFirstResponse &&
                !isPortalLogin &&
                moduleName === $constants.REQUEST
              "
              id="consider-note-as-first-response-checkbox"
              v-model="noteAsFirstResponse"
              class="mx-2"
            >
              <span class="text-neutral">{{
                $tc('consider_note_as_first_response')
              }}</span>
            </MCheckbox>
          </MCol>
        </MCol>
      </MRow>
    </template>
  </MComment>
</template>

<script>
import Pick from 'lodash/pick'
import Bus from '@utils/emitter'
import { authComputed } from '@state/modules/auth'
import { PreferenceComputed } from '@state/modules/preference'
import InsertResponseTemplate from './insert-response-template'
import InsertKnowledge from './insert-knowledge'
import OutgoingMailPicker from '@components/data-picker/outgoing-mail-picker'

export default {
  name: 'ConversationAddBox',
  components: { InsertResponseTemplate, InsertKnowledge, OutgoingMailPicker },
  model: {
    event: 'change',
  },
  props: {
    isPrivate: { type: Boolean, default: false },
    conversationType: { type: Object, required: true },
    processing: { type: Boolean, default: false },
    id: { type: Number, default: undefined },
    moduleName: { type: String, default: undefined },
    value: {
      type: String,
      required: false,
      default: undefined,
    },
    fileAttachments: {
      type: Array,
      required: false,
      default: () => [],
    },
    kb: {
      type: Array,
      required: false,
      default: () => [],
    },
    allowTemplates: { type: Boolean, default: false },
    allowKnowledges: { type: Boolean, default: false },
    allowSubject: { type: Boolean, default: false },
    allowEmail: { type: Boolean, default: false },
    allowCcEmail: { type: Boolean, default: false },
    allowOutgoingEmail: { type: Boolean, default: false },
    richBoxRows: { type: Number, default: 5 },
    latestReplay: {
      type: Object,
      default() {
        return {}
      },
    },
    forwardDefalutContent: {
      type: Object,
      default() {
        return {}
      },
    },
    resource: { type: Object, required: true },
    showNoteAsFirstResponse: { type: Boolean, default: false },
    allowSaveInDraft: { type: Boolean, default: false },
    defaultEmailSendConfigId: { type: Number, default: 0 },
    formData: {
      type: Object,
      default() {
        return {}
      },
    },
    isDraft: { type: Boolean, default: false },
  },
  data() {
    return {
      renderCount: 1,
      text: this.value,
      attachments: this.fileAttachments,
      kbAttachments: this.kb,
      fileList: [],
      knowledgeIsInserted: false,
      noteAsFirstResponse: false,
      subject:
        this.formData.subject ||
        `[${this.resource.name}]: ${this.resource.subject}`,
      emailIds: this.formData.emailIds || [],
      ccEmailIds: this.formData.ccEmailIds || [],
      emailSendConfigId: this.defaultEmailSendConfigId || undefined,
    }
  },
  computed: {
    ...authComputed,
    ...Pick(PreferenceComputed, ['allowUserToSelectFromEmail']),
    validationRules() {
      let rules = {
        required: (this.conversationType || {}).name === 'Forward',
      }
      return rules
    },
  },
  created() {
    this.prePopulateContentForReplay()
    this.prePopulateContentForForward()
    this.emailSendConfigId = this.defaultEmailSendConfigId
    // if (
    //   this.conversationType.type === 'technician_to_requester' &&
    //   !this.text &&
    //   this.user.signature
    // ) {
    //   this.text = `<br />${this.user.signature}`
    // }
  },
  mounted() {
    if (
      [
        'technician_to_requester',
        'requestor_to_technician',
        'forward_notification',
      ].indexOf(this.conversationType.type) >= 0
    ) {
      this.$refs.editorRef.focus('start')
    }
    const handleSetFrowardContent = (content) => {
      this.prePopulateContentForForward(content)
      this.renderCount = this.renderCount + 1
      setTimeout(() => {
        this.$refs.editorRef.focus('start')
      }, 100)
    }
    const handleSetReplyContent = (content) => {
      this.prePopulateContentForReplay(content)
      this.renderCount = this.renderCount + 1
      setTimeout(() => {
        this.$refs.editorRef.focus('start')
      }, 100)
    }
    Bus.$on('app:conversation:forward:content', handleSetFrowardContent)
    Bus.$on('app:conversation:reply:content', handleSetReplyContent)
  },
  methods: {
    handelSaveAndAddToKnowledge() {
      this.handleSubmit(true)
    },
    prepaireContent(type, content) {
      let text = ''
      if (type === 'technician_to_requester' && this.user.signature) {
        text = text.concat(`<p></p><p></p>${this.user.signature}<p><br /></p>`)
      }
      const replyContent = content || this.latestReplay
      if (Object.keys(replyContent || {}).length && replyContent.text) {
        if (!text) {
          text = '<p><br /><br /></p>'
        }
        text = text.concat(
          `<br /><b>From:</b> ${
            replyContent.owner
          } <br /><b>Date:</b> ${this.$options.filters.datetime(
            replyContent.createdAt
          )} <br /> <hr />${replyContent.text}`
        )
        if ((replyContent.attachments || []).length) {
          this.attachments = replyContent.attachments
        }
        if (type === 'technician_to_requester') {
          this.emailIds = replyContent.emailIds
          this.ccEmailIds = replyContent.ccEmailIds
          this.emailSendConfigId = replyContent.emailSendConfigId
        }
      }
      return text
    },
    prePopulateContentForReplay(content) {
      if (
        ['technician_to_requester', 'requestor_to_technician'].indexOf(
          this.conversationType.type
        ) >= 0 &&
        !this.isDraft
      ) {
        this.text = this.prepaireContent(this.conversationType.type, content)
      }
    },
    prePopulateContentForForward(content) {
      if (this.isDraft && this.text) {
        return
      }
      const forwardDefalutContent = content || this.forwardDefalutContent
      if (['forward_notification'].indexOf(this.conversationType.type) >= 0) {
        let text = ''
        if (this.user.signature) {
          text = text.concat(
            `<p></p><p></p>${this.user.signature}<p><br /></p>`
          )
        }
        if (Object.keys(forwardDefalutContent || {}).length) {
          if (!text) {
            text = '<p></p><p></p>'
          }
          text = text.concat(
            `<br /><b>From:</b> ${
              forwardDefalutContent.owner
            } <br /><b>Date:</b> ${this.$options.filters.datetime(
              forwardDefalutContent.createdAt
            )} <br /> <hr />${forwardDefalutContent.text}`
          )
        }
        this.text = text
        if ((forwardDefalutContent.attachments || []).length) {
          this.attachments = forwardDefalutContent.attachments
        }
        if (this.conversationType.type === 'technician_to_requester') {
          this.emailIds = forwardDefalutContent.emailIds
          this.ccEmailIds = forwardDefalutContent.ccEmailIds
          this.emailSendConfigId = forwardDefalutContent.emailSendConfigId
        }
      }
    },
    handelSaveInDraft() {
      this.handleSubmit(false, true)
    },
    handleSubmit(saveAndAddToKnowledge = false, isDraft = false) {
      if (this.processing) {
        return
      }
      const eventName =
        ['solution', 'conclusion', 'diagnosis'].indexOf(
          this.conversationType.type
        ) >= 0
          ? 'update'
          : 'submit'
      this.$emit(
        eventName,
        {
          id: this.id,
          text: this.text,
          fileAttachments: this.attachments,
          kbAttachments: this.kbAttachments,
          name: this.conversationType.type,
          noteAsFirstResponse: this.noteAsFirstResponse,
          subject: this.subject,
          emailIds: this.emailIds,
          ccEmailIds: this.ccEmailIds,
          emailSendConfigId: this.emailSendConfigId,
          conversationState: isDraft ? 'draft' : 'published',
        },
        saveAndAddToKnowledge
      )
    },
    reset() {
      this.text = this.value
      this.attachments = this.fileAttachments
      this.kbAttachments = this.kb
    },
    handleInsertTemplate(template) {
      this.text = template.content
      this.attachments = template.fileAttachments
      this.renderCount = this.renderCount + 1
    },
    insertKnowledgeContent(data) {
      this.$refs.editorRef.insertHTML(data)
    },
    handleInsertKnowledge(kb, link) {
      if (link) {
        const url = window.location.href
        let domain = new URL(url)
        const link = `<a href="${domain.origin}/support-portal/knowledge/${kb.id}" target="_blank" >
                  ${domain.origin}/support-portal/knowledge/${kb.id}
                </a><br />`
        this.insertKnowledgeContent(link)
      } else {
        this.insertKnowledgeContent(kb.content)
        this.attachments = kb.fileAttachments
      }
      this.renderCount = this.renderCount + 1
      this.knowledgeIsInserted = true
    },
  },
}
</script>

<style lang="less">
body.rtl {
  .box-container {
    @apply border-r-4;

    border-top-color: var(--border-color);
    border-bottom-color: var(--border-color);
    border-left-color: var(--border-color);
  }
}
</style>
<style lang="less" scoped>
.box-container {
  @apply border-solid border rounded px-6;

  .editor-row {
    border-bottom-color: var(--border-color);

    @apply mb-2 p-2 border-solid border border-l-0 border-r-0 border-t-0;
  }
}

body:not(.rtl) .box-container {
  @apply border-l-4;

  border-top-color: var(--border-color);
  border-right-color: var(--border-color);
  border-bottom-color: var(--border-color);
}
</style>
<style lang="less">
.forward-details {
  border-bottom: 1px solid var(--border-color);

  .ant-btn.ant-btn-primary.ant-btn-circle {
    position: unset !important;
    right: unset !important;
    bottom: unset !important;
    width: unset !important;
    min-width: unset !important;
    height: unset !important;
  }
}
</style>
