import Merge from 'lodash/merge'
import Omit from 'lodash/omit'
import Moment from 'moment'
import DefaultConfig from './default-config'

const ExcludedConfigs = ['series', 'serverSideZoom', 'pointTransformer']

export default function buildChartConfig(config = {}) {
  const colors = (config.series || [])
    .map((series) => series.color)
    .filter(Boolean)
  return Merge(
    {},
    DefaultConfig,
    {
      time: {
        timezone: config.timezone || Moment.tz.guess(),
      },
      title: {
        text: config.title,
      },
      subtitle: {
        text: config.subtitle,
      },
      xAxis: {},

      ...(colors.length ? { colors } : {}),

      ...(config.pointTransformer
        ? {
            series: (config.series || []).map((series) => ({
              name: series.name,
              ...Omit(series, ['data']),
              data: (series.data || []).map((d) => config.pointTransformer(d)),
            })),
          }
        : { series: config.series }),
    },
    Omit(config, ExcludedConfigs)
  )
}
