<template>
  <!-- @TODO: Add Call From field instead of CC Emails & disable Requester Field -->
  <a @click="trigger">
    <slot name="trigger" />
    <FlotoDrawerForm
      :open="isDrawerOpen"
      width="65%"
      @cancel="cancelAction"
      @submit="handleCreateRequest"
    >
      <template v-slot:header>
        {{ $t('create') }} {{ $tc('incident') }}
      </template>
      <TicketForm
        :value="requestFormData"
        :module-name="$constants.REQUEST"
        :processing="processing"
        :with-submit="false"
        :use-cc-email="false"
        @templateSelected="handleTemplateSelected"
        @change="handleChangeCreatingResource"
      />
      <template v-slot:actions="{ hide, submit }">
        <MButton
          variant="primary"
          class="mr-2"
          :loading="processing"
          @click="submit"
        >
          {{ $t('create') }}
        </MButton>
        <MButton variant="default" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawerForm>
  </a>
</template>

<script>
import Bus from '@utils/emitter'
import TicketForm from '@modules/ticket/components/ticket-form'
import { createRequestApi } from '../api'

export default {
  name: 'CreateIncidentDrawer',
  components: { TicketForm },
  props: {
    defaultChannel: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isDrawerOpen: false,
      processing: false,
      requestFormData: {
        requesterId: this.defaultChannel.requesterId,
        requester: this.defaultChannel.requesterName,
      },
    }
  },
  methods: {
    trigger() {
      setTimeout(() => {
        this.isDrawerOpen = true
      })
    },
    cancelAction() {
      this.isDrawerOpen = false
      this.requestFormData = {
        requesterId: this.defaultChannel.requesterId,
        requester: this.defaultChannel.requesterName,
      }
    },
    handleTemplateSelected(template) {
      if (!Object.keys(template).length) {
        this.requestFormData = {
          requesterId: this.defaultChannel.requesterId,
          requester: this.defaultChannel.requesterName,
        }
      }
    },
    handleChangeCreatingResource(data) {
      this.requestFormData = {
        ...this.requestFormData,
        ...data,
      }
    },
    handleCreateRequest() {
      this.processing = true
      createRequestApi(this.$constants.REQUEST, {
        ...this.requestFormData,
        callFrom: this.defaultChannel.requesterNumber,
        callType: this.defaultChannel.callType,
        callTime: this.defaultChannel.callStartTime,
      })
        .then((data) => {
          Bus.$emit('app:update_wrap_up_call_data', {
            requestId: data.id,
          })
          this.isDrawerOpen = false
          this.$emit('refresh')
        })
        .finally(() => (this.processing = false))
    },
  },
}
</script>
